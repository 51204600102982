import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Location } from '@angular/common';
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AdvancesResponse } from "src/app/models/properties/advances.response";
import { RelationDocumentsResponse } from "src/app/models/properties/relation.documents.response.model";
import { PropertiesService } from "src/app/services/admin-services/properties/properties.service";
import { WorksResponse } from "src/app/models/works/woks.response.model";
import { cleanNullAttributes, findByMatchingProperty, loadSideBarConfiguration } from "src/app/utils/utils";
import { InternalPropertiesService } from "../../../../../services/admin-services/properties/internal-properties.service";
import { ToastrService } from "ngx-toastr";
import { PropertieResponse } from "src/app/models/properties/properties.response.model";
import { LocalizationsService } from "src/app/services/localizations/localizations.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { AccountRelationalontactModel } from "src/app/models/accounts/relational-contact.model";
import { InternalContactModel } from "src/app/models/internal-contacts/internal-contact.model";
import { InternalContactsService } from "src/app/services/admin-services/internal-contacts/internal-contacts.service";
import { CanComponentDeactivate } from "src/app/config/prevent-change-route/prevent-change-route.guard";
import { ConfirmDialogNewVersionComponent } from "src/app/components/modals/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { InternalWorksService } from "../../../../../services/admin-services/works/internal-works.service";

@Component({
  selector: "app-internal-details-properties",
  templateUrl: "./internal-details-properties.component.html",
})
export class InternalDetailsPropertiesComponent
  implements OnInit, CanComponentDeactivate
{
  propertiesFilter: string;
  indexProperties: number;
  properties: string[] = [];
  relationDocuments: RelationDocumentsResponse[] = [];
  relationInternalDocuments: RelationDocumentsResponse[] = [];
  advances: AdvancesResponse[] = [];
  worksResponse: WorksResponse[] = [];
  documentFileTrustUrl = null;
  documentFileResourceUrl = null;
  isEditable = false;
  propertiesForm: UntypedFormGroup;
  notificationForm: UntypedFormGroup;
  documentationForm: UntypedFormGroup;
  documentType: string;
  documentCategory: string;
  document: string;
  country: string;
  countriesSelect;
  citiesSelect;
  cities = [];
  states = [];
  statesSelect;
  documentsType = [];
  documentsCategory = [];
  propertiesTypes = [];
  documents: any[] = [];
  documentsFiltered: any[] = [];
  documentNames: any[] = [];
  documentsToUpload: any[] = [];
  contactRecords: InternalContactModel[];
  idClient: number;
  internalProjects = [];
  projectNumber: string;
  isButtonDisabled = false;
  routerUrl: string;
  internalProperties: any;
  userType: string = sessionStorage.getItem("tipo");

  constructor(
    private readonly _Activatedroute: ActivatedRoute,
    private readonly _propertiesService: PropertiesService,
    private readonly localizationService: LocalizationsService,
    private readonly internalPropertiesService: InternalPropertiesService,
    private readonly _InternalWorksServices: InternalWorksService,
    private readonly spinner: NgxSpinnerService,
    private readonly sanitizer: DomSanitizer,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly toastr: ToastrService,
    private readonly authService: AuthService,
    private readonly internalContactService: InternalContactsService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly location: Location
  ) {}

  ngOnInit(): void {
    this.getParameteresURL();
    this.buildDocumentationForm();
    this.buildNotificationForm();
    this.getDocumentsType();
    this.getDocumentsCategory();
    this.getPropertiesType();
    this.getCountries();
    this.getCities();
    this.getStates();
    loadSideBarConfiguration("btnMenuInternalPropertiesDetails");
    this.router.events.subscribe((event) => {
      this.routerUrl = event["url"];
      if (this.routerUrl === "/") {
        this.routerUrl = "/home/projects";
      }
    });
  }

  tabChanged(event) {
    if (event.index > 0) {
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }

  validateForm(): Promise<boolean> {
    if (this.propertiesForm.dirty) {
      sessionStorage.setItem("propertyDirty", "true");
      const dialogRef = this.dialog.open(ConfirmDialogNewVersionComponent, {
        autoFocus: false,
        disableClose: true,
        data: {
          title: "Are you sure to quit without saving?",
          textSaveButton: "Save changes",
          textCloseButton: "Exit without saving",
        },
      });
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          sessionStorage.setItem("propertyDirty", "false");
          this.saveFormProperties();
          this.propertiesForm.reset();
          this.router.navigate([this.routerUrl]);
        } else {
          sessionStorage.setItem("propertyDirty", "false");
          this.propertiesForm.reset();
          this.router.navigate([this.routerUrl]);
        }
      });
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  }

  openConfirmUpdatePropertyDialog(
    textSaveButton: string,
    textCloseButton: string,
    title: string,
    action: string
  ): void {
    const dialogRef = this.dialog.open(ConfirmDialogNewVersionComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        title,
        textSaveButton,
        textCloseButton,
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        if (action === "close" || action === "cancel") {
          this.saveFormProperties();
          this.propertiesForm.reset();
          this.location.back();
        } else {
          this.saveFormProperties();
        }
      } else {
        if (action === "cancel") {
          this.propertiesForm.reset();
          this.location.back();
        }
      }
    });
  }

  getPropertiesType() {
    this.spinner.show();
    this.internalPropertiesService
      .getPropertiesType()
      .subscribe((response: any[]) => {
        this.propertiesTypes = response;
        this.spinner.hide();
      });
  }

  setStates(idCountry: string) {
    this.states = this.statesSelect.filter(
      (state) => state.country_Name === idCountry
    );
  }

  setCities(idState: string) {
    this.cities = this.citiesSelect.filter(
      (state) => state.department_Name === idState
    );
  }

  getStates() {
    this.spinner.show();
    this.localizationService.getStates().subscribe((response: any[]) => {
      this.statesSelect = response;
      this.setStates(this.properties[this.indexProperties]["country"]);
      this.spinner.hide();
    });
  }

  getCountries() {
    this.spinner.show();
    this.localizationService.getCountries().subscribe((countries: any[]) => {
      this.countriesSelect = countries;
      this.spinner.hide();
    });
  }

  getCities() {
    this.spinner.show();
    this.localizationService.getCities().subscribe((cities: any[]) => {
      this.citiesSelect = cities;
      this.setCities(this.properties[this.indexProperties]["department"]);
      this.spinner.hide();
    });
  }

  buildDocumentationForm() {
    this.documentationForm = this.formBuilder.group({
      property_type: [null],
      document_Category: [null],
      document_Name: [null],
    });
  }

  buildNotificationForm() {
    this.notificationForm = this.formBuilder.group({
      Send_To: [
        this.properties[this.indexProperties]["id_Property_Contact"],
        Validators.required,
      ],
      Property_Name: [this.properties[this.indexProperties]["name"]],
      Property: [this.properties[this.indexProperties]["property_Number"]],
      Project_Name: [this.properties[this.indexProperties]["projectName"]],
      Details: ["", Validators.required],
      Created: [new Date()],
    });
  }

  buildPropertiesForm() {
    this.propertiesForm = this.formBuilder.group({
      ID: [this.properties[this.indexProperties]["id"], Validators.required],
      ProjectName: [
        this.properties[this.indexProperties]["projectName"],
        Validators.required,
      ],
      Project_Number: [this.properties[this.indexProperties]["project_Number"]],
      Property_Number: [
        this.properties[this.indexProperties]["property_Number"],
      ],
      Property_Name: [this.properties[this.indexProperties]["property_Name"]],
      Department: [this.properties[this.indexProperties]["department"]],
      Property_Contact: [
        this.properties[this.indexProperties]["property_Contact"],
      ],
      Service: [this.properties[this.indexProperties]["service"]],
      Deliverable: [this.properties[this.indexProperties]["deliverable"]],
      Created: [new Date(this.properties[this.indexProperties]["created"])],
      Latitude: [this.properties[this.indexProperties]["latitude"]],
      Longitude: [this.properties[this.indexProperties]["longitude"]],
      Property_Major_Type: [
        this.properties[this.indexProperties]["property_Major_Type"],
      ],
      Property_Subtype: [
        this.properties[this.indexProperties]["property_Subtype"],
      ],
      Property_Account: [
        this.properties[this.indexProperties]["property_Account"],
      ],
      Property_Contact_Email: [
        this.properties[this.indexProperties]["property_Contact_Email"],
      ],
      Zip_Code: [this.properties[this.indexProperties]["zip_Code"]],
      Property_Type: [this.properties[this.indexProperties]["property_Type"]],
      Identifier: [this.properties[this.indexProperties]["identifier"]],
      Street_Address: [this.properties[this.indexProperties]["street_Address"]],
      Country: [this.properties[this.indexProperties]["country"]],
      City: [this.properties[this.indexProperties]["city"]],
      Parcel_Number: [this.properties[this.indexProperties]["parcel_Number"]],
      Property_Descriptions: [
        this.properties[this.indexProperties]["property_Descriptions"],
      ],
      Id_property_Contact: [
        this.properties[this.indexProperties]["id_Property_Contact"],
      ],
      Id_Inspection_Contact: [
        this.properties[this.indexProperties]["id_Inspection_Contact"],
      ],
    });
  }

  getDocumentsType() {
    this.internalPropertiesService
      .getDocumentsType()
      .subscribe((types: any) => {
        this.documentsType = types
          .map((type) => type["property_type"])
          .filter((value, index, self) => self.indexOf(value) === index);
        this.documents = types;
      });
  }

  getDocumentsCategory() {
    this.internalPropertiesService
      .getDocumentsCategory()
      .subscribe((categories: any) => {
        this.documentsCategory = categories;
      });
  }

  getParameteresURL() {
    this._Activatedroute.paramMap.subscribe((params) => {
      this.indexProperties = Number(params.get("id"));
      this.getProperties();
      this.getRelationDocuments();
    });
    this.worksResponse = JSON.parse(
      sessionStorage.getItem("internalJobs")
    );
    this.internalProperties = this.properties[this.indexProperties];
    this.buildPropertiesForm();
  }

  getRelationDocuments() {
    this.spinner.show();
    this._propertiesService
      .getRelationDocuments(
        this.properties[this.indexProperties]["project_Number"],
        this.properties[this.indexProperties]["property_Number"]
      )
      .subscribe(
        (response: RelationDocumentsResponse[]) => {
          this.relationInternalDocuments = response;
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(error.error.message, "Error");
        }
      );
  }

  getProperties() {
    this.properties = JSON.parse(sessionStorage.getItem("internalProperties"));
    this.internalProjects = JSON.parse(
      sessionStorage.getItem("internalProjects")
    );
    this.projectNumber =
      this.properties[this.indexProperties]["project_Number"];
    this.idClient = this.internalProjects?.find(
      (project) => project.project_Number === this.projectNumber
    ).id_Client;
    this.getCompanyxContacts(this.idClient);
  }

  viewDocument(document: any) {
    this._propertiesService.viewDocument(document.document_url).subscribe(
      (response) => {
        const byteArray = new Uint8Array(
          atob(response)
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        let extension = document.document_url.substr(
          document.document_url.lastIndexOf(".") + 1
        );
        let mimeType = this.getMimeType(extension);
        const blob = new Blob([byteArray], { type: mimeType });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
        this.documentFileTrustUrl = this.sanitizer.bypassSecurityTrustUrl(url);
        this.documentFileResourceUrl =
          this.sanitizer.bypassSecurityTrustResourceUrl(
            this.documentFileTrustUrl
          );
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  getMimeType(fileExtension: string) {
    switch (fileExtension) {
      case "doc":
        return "application/msword";
      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case "xls":
        return "application/vnd.ms-excel";
      case "xlsx":
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      case "ppt":
        return "application/vnd.ms-powerpoint";
      case "pptx":
        return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      case "jpg":
        return "image/jpeg";
      case "png":
        return "image/png";
      default:
        return "application/pdf";
    }
  }

  logout() {
    this.authService.logout();
  }

  get fullName() {
    return sessionStorage.getItem("nombreCompleto");
  }

  saveFormProperties() {
    const relatedJob = this.worksResponse
      ?.find( job => job['property_Number'] === this.propertiesForm.value.Property_Number);

    const data = {
      id: relatedJob.id,
      job_Number: relatedJob.job_Number,
      project_Number: relatedJob.project_Number,
      property_Number: relatedJob.property_Number,
      job_type: relatedJob.job_type,
      country: relatedJob.country,
      business_Unit: relatedJob.business_Unit,
      sector: relatedJob.sector,
      job_Manager: relatedJob.job_Manager,
      job_Status: relatedJob.job_Status,
      job_Comments: relatedJob.job_Comments,
      external_Fees: relatedJob.external_Fees,
      internal_fees: relatedJob.internal_fees,
      due_Date: relatedJob.due_Date,
      created: relatedJob.created,
      address: this.propertiesForm.value.Street_Address,
      currency: relatedJob.currency,
      equivalent_USD: relatedJob.equivalent_USD,
      account: relatedJob.account,
      account_Officer: relatedJob.account_Officer,
      phone_Account_Officer: relatedJob.phone_Account_Officer,
      email_Account_Officer: relatedJob.email_Account_Officer,
      deliverable: relatedJob.deliverable,
      property_Name: this.propertiesForm.value.Property_Name,
      latitude: this.propertiesForm.value.Latitude,
      longitude: this.propertiesForm.value.Longitude,
      city: relatedJob.city,
      zip_Code: relatedJob.zip_Code,
      name_Job_Manager: relatedJob.name_Job_Manager,
      department: relatedJob.department,
      market_Approach_Land: relatedJob.market_Approach_Land,
      residual_Land_Approach_Land: relatedJob.residual_Land_Approach_Land,
      cost_Approach: relatedJob.cost_Approach,
      market_Approach: relatedJob.market_Approach,
      income_Approach_Direct_Capitalization: relatedJob.income_Approach_Direct_Capitalization,
      income_Approach_Discounted_Cash_Flow: relatedJob.income_Approach_Discounted_Cash_Flow,
      interest_Valued: relatedJob.interest_Valued,
      type_of_Value: relatedJob.type_of_Value,
      valuation_Scenarios: relatedJob.valuation_Scenarios,
      delivery_Date: relatedJob.delivery_Date,
      days_to_Deliver: relatedJob.days_to_Deliver,
      iD_Collaborating_Territory: relatedJob.iD_Collaborating_Territory,
      iD_Collaborator: relatedJob.iD_Collaborator,
      percentage_of_Collaboration: relatedJob.percentage_of_Collaboration,
      iD_Job_Status: relatedJob.iD_Job_Status,
      iD_Job_Manager: relatedJob.iD_Job_Manager,
      id_Property_Contact: relatedJob.id_Property_Contact,
      id_Inspection_Contact: relatedJob.id_Inspection_Contact,
      iD_Account_Officer: relatedJob.iD_Account_Officer,
      iD_Account: relatedJob.iD_Account,
      inspection_Required: relatedJob.inspection_Required,
      id_Property_Type: relatedJob.id_Property_Type
    }

    this.spinner.show();
    this._propertiesService
      .updatePropertie(this.propertiesForm.value)
      .subscribe((response: any) => {
        if (response) {
          this.toastr.success(
            "The property has been successfully updated",
            "Update Property",
            {
              progressBar: true,
              progressAnimation: "increasing",
            }
          );
          this.listProperties();
          this.saveFormJob(data);
          this.isEditable = false;
          this.spinner.hide();
        } else {
          this.toastr.error(
            "Se han presentado problemas al actualizar la propiedad",
            "Update Property",
            {
              progressBar: true,
              progressAnimation: "increasing",
            }
          );
          this.spinner.hide();
        }
      });
  }

  listProperties() {
    this._propertiesService
      .getAllProperties()
      .subscribe((resp: PropertieResponse[]) => {
        sessionStorage.setItem("properties", JSON.stringify(resp));
        sessionStorage.setItem("internalProperties", JSON.stringify(resp));
        this.properties = JSON.parse(sessionStorage.getItem("internalProperties"));
        this.internalProjects = JSON.parse(
          sessionStorage.getItem("internalProjects")
        );
      });
  }

  sendNotificationDetail() {
    this.spinner.show();
    const email = this.contactRecords.find(
      (contact) =>
        this.notificationForm.value.Send_To ===
        contact.idContactInformationRecord
    )?.email;
    this.notificationForm.get("Send_To").setValue(email);
    this.internalPropertiesService
      .sendNotificationDetail(this.notificationForm.value)
      .subscribe(
        () => {
          this.toastr.success("Sent successfully", "Notification Detail", {
            progressBar: true,
            progressAnimation: "increasing",
          });
          this.notificationForm.patchValue({
            Send_To: null,
            Details: null,
          });
          this.spinner.hide();
        },
        () => {
          this.toastr.error(
            "Se han presentado problemas realizar el envio de la notificación",
            "Notification Detail",
            {
              progressBar: true,
              progressAnimation: "increasing",
            }
          );
          this.spinner.hide();
        }
      );
  }

  clearFiltersDocumentations() {
    this.documentationForm.patchValue({
      property_type: undefined,
      document_Category: undefined,
      document_Name: undefined,
    });
    this.documentsFiltered = [];
    this.relationDocuments = [];
  }

  filterDocuments() {
    const filters = cleanNullAttributes(this.documentationForm.value);
    const resultFilter = findByMatchingProperty(this.documents, filters);
    this.documentsFiltered = resultFilter;
    this.relationDocuments = this.documentsFiltered;
    this.documentNames = this.relationDocuments
      .map((type) => type["document_Name"])
      .filter((value, index, self) => self.indexOf(value) === index);
  }

  documentsUpload() {
    const documentsAlreadyLoad = [];
    this.documentsFiltered.forEach((document) => {
      let documentExists = this.documentsToUpload.find(
        (documentUpload) =>
          documentUpload.document_Name === document.document_Name
      );
      if (!documentExists) {
        this.documentsToUpload.push(document);
      } else {
        documentsAlreadyLoad.push(document);
      }
    });

    if (documentsAlreadyLoad.length > 0) {
      this.toastr.warning(
        "There is/are document(s) already exists in the list",
        "Notification Detail",
        {
          progressBar: true,
          progressAnimation: "increasing",
        }
      );
    }
  }

  removeDocument(document) {
    this.documentsToUpload.splice(this.documentsToUpload.indexOf(document), 1);
  }

  saveDocuments() {
    this.spinner.show();
    const data = [];
    const duplicateDocuments = [];

    this.documentsToUpload.forEach((document) => {
      let documentExists = this.relationInternalDocuments.find(
        (documentUpload) =>
          documentUpload.document_Name === document.document_Name
      );

      data.push({
        Document_Name: document.document_Name,
        Property_Type: document.property_type,
        Document_Category: document.document_Category,
        Project_Number: this.properties[this.indexProperties]["project_Number"],
        Property: this.properties[this.indexProperties]["property_Number"],
        Document_Upload: false,
        Document_url: "",
        Created: new Date(),
        Id_RelacionDocumentoSH: 0,
      });

      if (documentExists) {
        duplicateDocuments.push(document);
      }
    });

    if (duplicateDocuments.length > 0) {
      this.toastr.warning(
        "Alguno de los documentos que intenta cargar ya se encuentra cargado",
        "Notification Detail",
        {
          progressBar: true,
          progressAnimation: "increasing",
        }
      );
      this.spinner.hide();
    } else {
      this.internalPropertiesService.saveDocuments(data).subscribe(
        () => {
          this.toastr.success(
            "Documents have been stored successfully",
            "Upload Documents",
            {
              progressBar: true,
              progressAnimation: "increasing",
            }
          );
          this.documentsToUpload = [];
          this.getRelationDocuments();
          this.spinner.hide();
        },
        () => {
          this.toastr.error(
            "Se han presentado problemas al almacenar los documentos",
            "Upload Documents",
            {
              progressBar: true,
              progressAnimation: "increasing",
            }
          );
          this.spinner.hide();
        }
      );
    }
  }

  private saveFormJob(job: any) {
    this.spinner.show();
    this._InternalWorksServices.updateJobs(job).subscribe(
      (): void => { this.spinner.hide() },
      (): void => { this.spinner.hide() }
    );
  }

  private getCompanyxContacts(idClient: number) {
    this.spinner.show();
    this.internalContactService.getCompanyxContacts(idClient).subscribe(
      (contacts: AccountRelationalontactModel[]) => {
        this.spinner.hide();
        const contactIds = contacts.map(
          (contact) => contact.idContactInformationRecord
        );
        this.getRecordsIds(contactIds);
      },
      () => {
        this.spinner.hide();
        this.toastr.error(
          `Error! Does not exist an ID Client in ${this.projectNumber}`,
          "Error"
        );
      }
    );
  }

  private getRecordsIds(contacts: number[]) {
    this.spinner.show();
    this.internalContactService.getRecordsIds(contacts).subscribe(
      (contactRecords: InternalContactModel[]) => {
        this.spinner.hide();
        this.contactRecords = contactRecords;
      },
      () => {
        this.spinner.hide();
        this.toastr.error("Error! Please try again", "Error");
      }
    );
  }
}
