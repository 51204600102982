import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { InternalWorksService } from "../../../../../../services/admin-services/works/internal-works.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ConfirmationInspectionDialogComponent } from "./confirmation-inspection-dialog/confirmation-inspection-dialog.component";

@Component({
  selector: 'app-inspection-dialog',
  templateUrl: './inspection-dialog.component.html'
})
export class InspectionDialogComponent implements OnInit {

  inspectionForm: UntypedFormGroup;
  hasBeenSaved = false;

  constructor(
    private readonly dialogRef: MatDialogRef<InspectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly internalWorksService: InternalWorksService,
    private readonly dialog: MatDialog,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.buildInspectionForm();
  }

  confirmationManageInspection() {
    if (this.inspectionForm.dirty) {
      const dialogRef = this.dialog.open(ConfirmationInspectionDialogComponent, {
        hasBackdrop: true,
        autoFocus: false,
        disableClose: false,
        data: {
          inspectionForm: this.inspectionForm
        },
      });
      dialogRef.afterClosed().subscribe((action: string) => {
        if ( action === 'discardChanges') {
          this.dialogRef.close();
        } else if ( action === 'saveChanges') {
          this.manageInspection('saveAndClose');
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  manageInspection(action: string): void {
    if (this.data.action === 'edit') {
      this.updateInspection(action);
    } else {
      this.createInspection(action);
    }
  }

  private createInspection(action: string): void {
    if ( this.hasBeenSaved ) {
      this.keepOrCloseDialog(action);
    } else {
      this.spinner.show();
      this.inspectionForm.get('idInspection').setValue(0);
      this.internalWorksService.createInspection(this.inspectionForm.value).subscribe(
        () => {
          this.toastr.success('Inspection created successfully.');
          this.spinner.hide();
          this.keepOrCloseDialog(action);
        },
        () => {
          this.toastr.error('Error, please try again later.');
          this.spinner.hide();
        }
      );
    }
  }

  private updateInspection(action: string): void {
    this.spinner.show();
    this.internalWorksService.updateInspection(this.inspectionForm.value).subscribe(
      () => {
        this.spinner.hide();
        this.toastr.success('Inspection updated successfully', 'Success');
        this.keepOrCloseDialog(action);
      },
      () => {
        this.spinner.hide();
        this.toastr.error('An error occurred while updating the inspection', 'Error');
      }
    );
  }

  private keepOrCloseDialog(action: string): void {
    if ( action === 'saveAndClose') {
      this.dialogRef.close();
    } else {
      this.hasBeenSaved = true;
    }
  }

  private buildInspectionForm(): void {
    this.inspectionForm = this.formBuilder.group({
      idInspection: [this.data?.inspection?.idInspection],
      inspectionNumber: [this.data?.inspection?.inspectionNumber ? this.data?.inspection?.inspectionNumber : this.data?.inspectionConsecutive],
      inspectionDate: [this.data?.inspection?.inspectionDate, Validators.required],
      inspectedBy: [this.data?.inspection?.inspectedBy, Validators.required],
      idJob: [this.data?.jobId],
      statusInspection: [this.data?.inspection?.statusInspection, Validators.required],
      createdOn: [this.data?.inspection ? this.data?.inspection?.createdOn : new Date()],
      createdBy: [this.data?.inspection ? this.data?.inspection?.createdBy : sessionStorage.getItem('userID')],
      modifiedOn: [this.data?.inspection ? this.data?.inspection?.modifiedOn : new Date()],
      modifiedBy: [sessionStorage.getItem('userID')],
      status: [this.data?.inspection ? this.data?.inspection?.status : 'Active']
    });
  }

}
