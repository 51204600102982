import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html'
})
export class RecoveryPasswordComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
