export function fileExtensions(extensionName: string) {
  switch (extensionName.toLocaleLowerCase()) {
    case 'xlsx':
      return true;
    case 'xls':
      return true;
    case 'docx':
      return true;
    case 'doc':
      return true;
    case 'pptx':
      return true;
    case 'ppt':
      return true;
    case 'pdf':
      return true;
    case 'jpg':
      return true;
    case 'png':
      return true;
    case 'mpp':
      return true;
    case 'txt':
      return true;
    case 'bmp':
      return true;
    case 'dwg':
      return true;
    case 'dgn':
      return true;
    case 'dwl2':
      return true;
    case 'tif':
      return true;
    case 'mp3':
      return true;
    case 'wav':
      return true;
    case 'avi':
      return true;
    case 'mpeg':
      return true;
    default:
      return false;
  }
}

export function getExtensionIcon(extensionName: string) {
  switch (extensionName.toLocaleLowerCase()) {
    case 'xlsx':
      return 'fas fa-file-excel excel-color mr-1';
    case 'docx':
      return 'fas fa-file-word word-color mr-1';
    case 'pptx':
      return 'fas fa-file-powerpoint power-point-color mr-1';
    case 'pdf':
      return 'fas fa-file-pdf pdf-color mr-1';
    case 'jpg':
      return 'fas fa-image image-color mr-1';
    case 'png':
      return 'fas fa-image image-color mr-1';
    case 'mpp':
      return 'fas fa-file-excel excel-color mr-1';
    case 'txt':
      return 'fas fa-envelope-open-text image-color mr-1';
    case 'bmp':
      return 'fas fa-file-code image-color mr-1';
    case 'dwg':
      return 'fas fa-file-code image-color mr-1';
    case 'dgn':
      return 'fas fa-file-code image-color mr-1';
    case 'dwl2':
      return 'fas fa-file-code image-color mr-1';
    case 'tif':
      return 'fas fa-image image-color mr-1';
    case 'mp3':
      return 'fas fa-file-audio word-color mr-1';
    case 'wav':
      return 'fas fa-file-video word-color mr-1';
    case 'avi':
      return 'fas fa-file-video word-color mr-1';
    case 'mpeg':
      return 'fas fa-file-video word-color mr-1';
    default:
      return 'fa fa-folder color-icon-folder mr-1';
  }
}
