import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { combineLatest } from "rxjs";
import { ConfirmDialogComponent } from "src/app/components/confirm-dialog/confirm-dialog.component";
import { AccountModel } from "src/app/models/accounts/account.model";
import { AccountCompanyProjectModel } from "src/app/models/accounts/company-project.model";
import { AccountProjectModel } from "src/app/models/accounts/project.model";
import { CityResponse } from "src/app/models/localizations/city.response.model";
import { CountryResponse } from "src/app/models/localizations/country.reponse.model";
import { StateResponse } from "src/app/models/localizations/state.response.model";
import { AccountsService } from "src/app/services/admin-services/accounts/accounts.service";
import { LocalizationsService } from "src/app/services/localizations/localizations.service";
import { ActivatedRoute, Router } from "@angular/router";
import { InternalContactsService } from "../../../../services/admin-services/internal-contacts/internal-contacts.service";
import { ManageInternalContactDialogComponent } from "../../internal-contacts/manage-internal-contact-dialog/manage-internal-contact-dialog.component";
import { AccountRelationalontactModel } from "src/app/models/accounts/relational-contact.model";
import { InternalContactModel } from "src/app/models/internal-contacts/internal-contact.model";

@Component({
  selector: "app-manage-account-dialog",
  templateUrl: "./manage-account-dialog.component.html",
})
export class ManageAccountDialogComponent implements OnInit {
  accountForm: UntypedFormGroup;
  filteredStates: StateResponse[];
  filteredCities: CityResponse[];
  relatedProjects: AccountProjectModel[];
  countriesResponse: CountryResponse[];
  statesResponse: StateResponse[];
  citiesResponse: CityResponse[];
  accounts: AccountModel[];
  account: AccountModel;
  idAccount: number;
  contactRecords: InternalContactModel[];
  loadForm = false;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: ToastrService,
    private readonly dialog: MatDialog,
    private readonly accountService: AccountsService,
    private readonly internalContactService: InternalContactsService,
    private readonly localizationService: LocalizationsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.idAccount = +this.route.snapshot.paramMap.get("idAccount");
    this.getLocalizations();
    this.getAccounts();
    if (this.idAccount) {
      this.getCompanyXproject();
      this.getCompanyxContacts();
    }
  }

  openManageContactDialog(action?: string) {
    this.dialog.open(ManageInternalContactDialogComponent, {
      width: "950pt",
      height: "700pt",
      maxWidth: "1000pt",
      autoFocus: false,
      disableClose: false,
      data: {
        action,
        account: this.account,
      },
    });
  }

  setCities(idState: number) {
    this.filteredCities = this.citiesResponse.filter(
      (city) => city.department_ID === idState
    );
  }

  setStates(idCountry: number) {
    this.filteredStates = this.statesResponse.filter(
      (state) => state.country_ID === idCountry
    );
  }

  openConfirmAccountDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        title: "Save a New Account",
        description: "¿Are you sure you want to save a new account?",
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.managementAccount();
      }
    });
  }

  getCountryName(countryId: number) {
    return this.countriesResponse?.find((country) => country.id === countryId)
      ?.country_Name;
  }

  getStateName(stateId: number) {
    return this.statesResponse?.find((state) => state.id === stateId)
      ?.department_Name;
  }

  getCityName(cityId: number) {
    return this.citiesResponse?.find((city) => city.id === cityId)?.city_Name;
  }

  private getAccounts() {
    this.spinner.show();
    this.loadForm = true;
    this.accountService.getAccounts().subscribe(
      (accounts: AccountModel[]) => {
        this.spinner.hide();
        this.accounts = accounts;
        if (this.idAccount) {
          this.account = accounts.find(
            (account) => account.idCompany === this.idAccount
          );
        }
        this.createAccountForm();
      },
      () => {
        this.spinner.hide();
        this.toastr.error("Error! Please try again", "Error");
      }
    );
  }

  private getLocalizations() {
    combineLatest([
      this.localizationService.currentCountries,
      this.localizationService.currentStates,
      this.localizationService.currentCities,
    ]).subscribe(([countries, states, cities]) => {
      if (countries !== null && states !== null && cities !== null) {
        this.countriesResponse = countries;
        this.statesResponse = states;
        this.citiesResponse = cities;
      }
    });
  }

  private getCompanyxContacts() {
    this.spinner.show();
    this.internalContactService.getCompanyxContacts(this.idAccount).subscribe(
      (contacts: AccountRelationalontactModel[]) => {
        this.spinner.hide();
        const contactIds = contacts.map(
          (contact) => contact.idContactInformationRecord
        );
        this.getRecordsIds(contactIds);
      },
      () => {
        this.spinner.hide();
        this.toastr.error("Error! Please try again", "Error");
      }
    );
  }

  private getRecordsIds(contacts: number[]) {
    this.spinner.show();
    this.internalContactService.getRecordsIds(contacts).subscribe(
      (contactRecords: InternalContactModel[]) => {
        this.spinner.hide();
        this.contactRecords = contactRecords;
      },
      () => {
        this.spinner.hide();
        this.toastr.error("Error! Please try again", "Error");
      }
    );
  }

  private getCompanyXproject() {
    this.spinner.show();
    this.accountService
      .getCompanyXproject(this.idAccount)
      .subscribe((companyProjects: AccountCompanyProjectModel[]) => {
        const projects = companyProjects.map(
          (project) => project.projectNumber
        );
        this.getProjectsCompany(projects);
      });
  }

  private getProjectsCompany(companyProjects: string[]) {
    this.accountService
      .getProjectsCompany(companyProjects)
      .subscribe((projects: AccountProjectModel[]) => {
        this.relatedProjects = projects;
        this.spinner.hide();
      });
  }

  private managementAccount(): void {
    this.spinner.show();
    if (!this.idAccount) {
      this.accountService.createAccount(this.accountForm.value).subscribe(
        () => {
          this.spinner.hide();
          this.toastr.success("Account created successfully");
          this.router.navigate(["/home/accounts"]);
        },
        () => {
          this.spinner.hide();
          this.toastr.error("Error creating user");
        }
      );
    } else {
      this.accountService.updateAccount(this.accountForm.value).subscribe(
        () => {
          this.spinner.hide();
          this.toastr.success("Account updated successfully");
          this.router.navigate(["/home/accounts"]);
        },
        () => {
          this.spinner.hide();
          this.toastr.error("Error updating user");
        }
      );
    }
  }

  private createAccountForm() {
    this.accountForm = this.formBuilder.group({
      IdCompany: [this.account ? this.account.idCompany : 0],
      IdStatus: [this.account ? this.account.idStatus : true],
      CompanyName: [
        this.account ? this.account.companyName : "",
        Validators.required,
      ],
      CompanyAddress: [
        this.account ? this.account.companyAddress : "",
        Validators.required,
      ],
      PhoneCompany: [
        this.account ? this.account.phoneCompany : "",
        Validators.required,
      ],
      CompanyType: [this.account ? this.account.companyType : ""],
      IdCompanyMain: [this.account ? this.account.idCompanyMain : 0],
      GuidCompany: [this.account ? this.account.guidCompany : ""],
      TableName: [this.account ? this.account.tableName : "Company"],
      DateUpdate: [this.account ? this.account.dateUpdate : new Date()],
      UpdateUser: [
        this.account
          ? this.account.updateUser
          : sessionStorage.getItem("email"),
      ],
      UpdateProgram: [
        this.account ? this.account.updateProgram : "Production Web App",
      ],
      Website: [this.account ? this.account.website : "", Validators.required],
      IdCountry: [
        this.account ? this.account.idCountry : "",
        Validators.required,
      ],
      IdState: [this.account ? this.account.idState : "", Validators.required],
      IdCity: [this.account ? this.account.idCity : "", Validators.required],
      Status: [this.account ? this.account.status : true],
    });
    if (this.idAccount) {
      this.setStates(this.account.idCountry);
      this.setCities(this.account.idState);
    }
    this.loadForm = false;
  }
}
