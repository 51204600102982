import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class InternalTasksService {
  private headers = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    }),
  };

  constructor(public readonly http: HttpClient) {}

  getTasksByUser() {
    return this.http.get(
      `${environment.tasksByUser}?usuario=${sessionStorage.getItem("email")}`,
      this.headers
    );
  }

  getTasksByProject(projectNumber: string) {
    return this.http.get(
      `${environment.internalServices.tasksProjects}?proyectNumber=${projectNumber}`,
      this.headers
    );
  }

  getTaskStatus() {
    return this.http.get(`${environment.masters.taskStatus}`, this.headers);
  }

  getTaskType() {
    return this.http.get(`${environment.masters.taskType}`, this.headers);
  }

  getTaskCategory() {
    return this.http.get(`${environment.masters.taskCategory}`, this.headers);
  }

  getTaskCodes() {
    return this.http.get(`${environment.masters.taskCode}`, this.headers);
  }

  getTaskSubTypes() {
    return this.http.get(`${environment.masters.taskSubType}`, this.headers);
  }

  updateTask(task: any) {
    return this.http.put(`${environment.updateTask}`, task, this.headers);
  }

  createTask(task: any) {
    return this.http.post(`${environment.createTask}`, task, this.headers);
  }
}
