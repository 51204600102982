import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ProjectResponse } from "src/app/models/projects/project.response.model";
import { ProjectsService } from "src/app/services/admin-services/projects/projects.service";

@Component({
  selector: "app-internal-table-projects",
  templateUrl: "./internal-table-projects.component.html",
})
export class InternalTableProjectsComponent implements OnInit {
  filterProjectsTable: string;
  projectStatusSelect: any[] = [];

  @Output() updateInternalFilterProjects = new EventEmitter<any>();
  @Input() projectsResponse: ProjectResponse[];
  @Input() set filter(filter: string) {
    this.filterByWord(filter);
  }

  constructor(private readonly projectService: ProjectsService,) {}

  ngOnInit(): void {
    this.getStatusJobs();
  }

  identifyProjects(index, project) {
    return project.id;
  }

  getStatusJobs() {
    this.projectService
      .getStatusProjects()
      .subscribe((projectStatus: any[]) => (this.projectStatusSelect = projectStatus));
  }

  getStatusProjectName(idStatusProject: number) {
    const projectStatus = this.projectStatusSelect.find(
      (x) => x.id === idStatusProject
    )?.name_Status;
    return projectStatus;
  }

  filterByWord(filter: string) {
    this.filterProjectsTable = filter;
    this.projectsResponse = JSON.parse(
      sessionStorage.getItem("internalProjects")
    );
    var internalProjectsFiltered = this.filterByValue(
      this.projectsResponse,
      filter
    );
    this.updateInternalFilterProjects.emit(internalProjectsFiltered);
  }

  filterByValue(array, string) {
    return array?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k])?.toLowerCase().includes(string?.toLowerCase())
      )
    );
  }

  getProjectAssets(projectNumber: string): number {
    const internalProperties = JSON.parse(sessionStorage.getItem("internalProperties"));
    return internalProperties.filter(property => property.project_Number === projectNumber).length;
  }

}
