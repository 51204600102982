import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MasterService {
  private headers = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    }),
  };

  constructor(public readonly http: HttpClient) {}

  getBusinessUnits() {
    return this.http.get(environment.masters.businessUnit, this.headers);
  }

  getContactClasses() {
    return this.http.get(environment.masters.contactClasses, this.headers);
  }
}
