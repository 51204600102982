import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html'
})
export class CodeComponent implements OnInit {

  private email: string;

  constructor(
    private readonly _AuthService: AuthService,
    private readonly _toastr: ToastrService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _router: Router
  ) {  }

  ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe(params => {
      this.email = params['email'];
    });
  }

  sendEmailResetPassword() {
    this._spinner.show();
    this._AuthService.sendEmailResetPassword({ Email: this.email }).subscribe({
      next: () => {
        this._spinner.hide();
        this._toastr.success('Se ha enviado un correo con el código de recuperación de contraseña');
        this._router.navigate(['/auth/code-confirmation'], { queryParams: { email: this.email } });
      },
      error: () => {
        this._spinner.hide();
        this._toastr.error('Ha ocurrido un error al enviar el correo, por favor intente nuevamente.');
      }
    });
  }

}
