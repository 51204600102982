import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-advances',
  templateUrl: './advances.component.html'
})
export class AdvancesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.loadSideBarConfiguration();
  }

  loadSideBarConfiguration() {
    let sidebar = document.querySelector(".sidebar");
    let closeBtn = document.querySelector("#btnMenuInternalProjects");

    closeBtn.addEventListener("click", () => {
      sidebar.classList.toggle("open");
      menuBtnChange();
    });

    function menuBtnChange() {
      if (sidebar.classList.contains("open")) {
        closeBtn.classList.replace("bx-menu", "bx-menu-alt-right");
        document.querySelector(".margin-screen").classList.add("md:ml-14");
        document.querySelector(".margin-screen").classList.remove("md:ml-3");
      } else {
        closeBtn.classList.replace("bx-menu-alt-right", "bx-menu");
        document.querySelector(".margin-screen").classList.add("md:ml-3");
        document.querySelector(".margin-screen").classList.remove("md:ml-14");
      }
    }
  }

}
