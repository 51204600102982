import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { InternalContactModel } from "src/app/models/internal-contacts/internal-contact.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class InternalContactsService {
  protected internalContacts = new BehaviorSubject<InternalContactModel[]>(
    null
  );
  currentInternalContacts = this.internalContacts.asObservable();

  private headers = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    }),
  };

  constructor(public readonly http: HttpClient) {}

  getInternalContacts() {
    return this.http.get(environment.internalContacts.get, this.headers);
  }

  setInternalContacts(internalContacts: InternalContactModel[]) {
    this.internalContacts.next(internalContacts);
  }

  createInternalContact(data: any) {
    return this.http.post(
      environment.internalContacts.create,
      data,
      this.headers
    );
  }

  updateInternalContact(data: any) {
    return this.http.put(
      environment.internalContacts.update,
      data,
      this.headers
    );
  }

  getRecordsXProperties(idContactRecord: number) {
    return this.http.get(environment.internalContacts.recordsXProperties, {
      params: {
        idContactRecord: idContactRecord.toString(),
      },
      headers: this.headers.headers,
    });
  }

  getRecordsXJobs(idContactRecord: number) {
    return this.http.get(environment.internalContacts.recordsXJobs, {
      params: {
        idContactRecord: idContactRecord.toString(),
      },
      headers: this.headers.headers,
    });
  }

  getRecordsXProjects(idContactRecord: number) {
    return this.http.get(environment.internalContacts.recordsXProjects, {
      params: {
        idContactRecord: idContactRecord.toString(),
      },
      headers: this.headers.headers,
    });
  }

  getContactProperties(properties: string[]) {
    return this.http.post(
      environment.internalContacts.propertiesContact,
      properties,
      this.headers
    );
  }

  getContactJobs(jobs: number[]) {
    return this.http.post(
      environment.internalContacts.jobsContact,
      jobs,
      this.headers
    );
  }

  getContactProjects(projects: string[]) {
    return this.http.post(
      environment.accounts.projectsCompany,
      projects,
      this.headers
    );
  }

  getCompanyxContacts(idCompany: number) {
    return this.http.get(environment.internalContacts.contactCompanies, {
      params: {
        idCompany: idCompany?.toString(),
      },
      headers: this.headers.headers,
    });
  }

  getRecordsIds(idContactRercords: number[]) {
    return this.http.post(
      environment.internalContacts.recordsIds,
      idContactRercords,
      this.headers
    );
  }
}
