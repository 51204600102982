import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth/auth.service';
import { cleanNullAttributes, findByMatchingProperties, loadSideBarConfiguration } from "../../../utils/utils";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { InternalWorksResponse } from "../../../models/works/internal.works.response.model";
import { DatepickerOptions } from "ng2-datepicker";
import { getYear } from "date-fns";
import locale from "date-fns/locale/en-US";
import * as moment from "moment/moment";
import { InternalWorksService } from "../../../services/admin-services/works/internal-works.service";
import { ToastrService } from "ngx-toastr";
import { ProjectResponse } from "../../../models/projects/project.response.model";
import { ProjectsService } from "../../../services/admin-services/projects/projects.service";
import { WorksService } from "../../../services/admin-services/works/works.service";

@Component({
  selector: 'app-works-table',
  templateUrl: './works-table.component.html'
})
export class WorksTableComponent implements OnInit {
  @ViewChild("startDate") resetStartDate: ElementRef;
  @ViewChild("endDate") resetEndDate: ElementRef;

  internalJobsResponse: InternalWorksResponse[] = [];
  filterInternalJobs: InternalWorksResponse[] = [];
  filtersForm: UntypedFormGroup;
  jobs: string;
  jobStatusSelect: any[] = [];
  country: string;
  countrySelect = [];
  city: string;
  citySelect = [];

  startDateValue = null;
  endDateValue = null;

  optionsStartDate: DatepickerOptions = {
    minYear: getYear(new Date()) - 30, // minimum available and selectable year
    maxYear: getYear(new Date()) + 30, // maximum available and selectable year
    placeholder: "Start Date", // placeholder in case date model is null | undefined, example: 'Please pick a date'
    format: "LLLL do yyyy", // date format to display in input
    formatTitle: "LLLL yyyy",
    formatDays: "EEEEE",
    firstCalendarDay: 1, // 0 - Sunday, 1 - Monday
    locale, // date-fns locale
    position: "bottom",
    inputClass:
      "border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150", // custom input CSS class to be applied
    calendarClass: "datepicker-default", // custom datepicker calendar CSS class to be applied
    scrollBarColor: "#dfe3e9", // in case you customize you theme, here you define scroll bar color
  };

  optionsEndDate: DatepickerOptions = {
    minYear: getYear(new Date()) - 30, // minimum available and selectable year
    maxYear: getYear(new Date()) + 30, // maximum available and selectable year
    placeholder: "End Date", // placeholder in case date model is null | undefined, example: 'Please pick a date'
    format: "LLLL do yyyy", // date format to display in input
    formatTitle: "LLLL yyyy",
    formatDays: "EEEEE",
    firstCalendarDay: 1, // 0 - Sunday, 1 - Monday
    locale, // date-fns locale
    position: "bottom",
    inputClass:
      "border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150", // custom input CSS class to be applied
    calendarClass: "datepicker-default", // custom datepicker calendar CSS class to be applied
    scrollBarColor: "#dfe3e9", // in case you customize you theme, here you define scroll bar color
  };

  get startDate() {
    return moment(this.startDateValue).format("YYYY-MM-DD");
  }

  get endDate() {
    return moment(this.endDateValue).format("YYYY-MM-DD");
  }

  get fullName() {
    return sessionStorage.getItem("nombreCompleto");
  }

  constructor(
    private readonly internalWorksServices: InternalWorksService,
    private readonly jobService: WorksService,
    private readonly projectService: ProjectsService,
    private readonly toastr: ToastrService,
    private readonly spinner: NgxSpinnerService,
    private readonly authService: AuthService,
    private readonly formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.getStatusJobs();
    this.getUserProjects();
    this.buildFiltersForms();
    loadSideBarConfiguration("btnMenuJobs");
  }

  filterJobs() {
    const startDate = this.startDate;
    const endDate = this.endDate;

    this.filterInternalJobs = JSON.parse(
      sessionStorage.getItem("internalJobs")
    ).map((job) => ({
      ...job,
      created: job.created.split("T")[0],
    }));

    if (this.startDateValue && this.endDateValue) {
      this.filterInternalJobs = this.filterInternalJobs.filter(
        (job: InternalWorksResponse) =>
          job.created >= startDate && job.created <= endDate
      );
    }

    const filtersForm = {
      ...this.filtersForm.value,
    };

    const filters = cleanNullAttributes(filtersForm);
    this.internalJobsResponse = findByMatchingProperties(
      this.filterInternalJobs,
      filters
    );
  }

  clearFilters() {
    this.resetStartDate["displayValue"] = "";
    this.resetEndDate["displayValue"] = "";
    this.startDateValue = undefined;
    this.endDateValue = undefined;
    this.filtersForm.patchValue({
      iD_Job_Status: undefined,
      country: undefined,
      city: undefined,
    });
    this.internalJobsResponse = JSON.parse(
      sessionStorage.getItem("internalJobs")
    );
  }

  logout() {
    this.authService.logout();
  }

  updateInternalFilterJobs(event) {
    this.internalJobsResponse = event;
  }

  private getUserProjects() {
    this.spinner.show();
    this.projectService.getProjects().subscribe(
      (resp: ProjectResponse[]) => {
        const projects = resp.map((project) => project.project_Number);
        this.getJobsByProjects(projects);
      },
      () => {
        this.toastr.error(
          "Se presento un problema obteniendo los proyectos",
          "Proyectos",
          {
            progressBar: true,
            progressAnimation: "increasing",
          }
        );
      }
    );
  }

  private getJobsByProjects(projectNumbers: string[]) {
    this.jobService.getAllWorks(projectNumbers).subscribe(
      (resp: InternalWorksResponse[]) => {
        this.internalJobsResponse = resp
          .sort(
            (a, b) => Number(moment(a.due_Date)) - Number(moment(b.due_Date))
          )
          .reverse();
        sessionStorage.setItem("internalJobs", JSON.stringify(resp));
        this.getUniqueValuesOnchangeSelect();
        this.spinner.hide();
      },
      () => {
        this.toastr.error(
          "Se han presentando inconvenientes al obtener los datos de los jobs",
          "Obteniendo datos",
          {
            progressBar: true,
            progressAnimation: "increasing",
          }
        );
        this.spinner.hide();
      }
    );
  }

  private getUniqueValuesOnchangeSelect() {
    this.countrySelect = this.internalJobsResponse
      .map((job: InternalWorksResponse) => job.country)
      .filter(
        (value: string, index: number, self: string[]) =>
          self.indexOf(value) === index
      );

    this.citySelect = this.internalJobsResponse
      .map((job: InternalWorksResponse) => job.city)
      .filter(
        (value: string, index: number, self: string[]) =>
          self.indexOf(value) === index
      );
  }

  private getStatusJobs() {
    this.internalWorksServices
      .getStatusJobs()
      .subscribe((jobStatus: any[]) => (this.jobStatusSelect = jobStatus));
  }

  private buildFiltersForms() {
    this.filtersForm = this.formBuilder.group({
      iD_Job_Status: [[]],
      country: [null],
      city: [null],
    });
  }

}
