import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ProjectResponse } from "src/app/models/projects/project.response.model";
import { environment } from "src/environments/environment";
import { ProjectFileModel } from "../../../models/projects/project.file.model";
import { ProjectStatusModel } from "../../../models/projects/project-status.model";

@Injectable({
  providedIn: "root",
})
export class ProjectsService {
  protected projects = new BehaviorSubject<ProjectResponse[]>(null);
  currentProjects = this.projects.asObservable();

  protected filesUploaded = new BehaviorSubject<number>(0);
  currentFilesUploaded = this.filesUploaded.asObservable();

  private headers = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    }),
  };

  constructor(public readonly http: HttpClient) {}

  getProjects() {
    let data = {
      GuidCliente: sessionStorage.getItem("guidCompany"),
      Pais: sessionStorage.getItem("country"),
      Rol: sessionStorage.getItem("rol"),
      Email: sessionStorage.getItem("email"),
    };
    return this.http.post(`${environment.projects}`, data, this.headers);
  }

  updateProject(data: any) {
    return this.http.put(`${environment.updateProject}`, data, this.headers);
  }

  getStatusProjects(): Observable<ProjectStatusModel[]> {
    return this.http.get<ProjectStatusModel[]>(`${environment.masters.statusProjects}`, this.headers);
  }

  setProjects(projects: ProjectResponse[]): void {
    this.projects.next(projects);
  }

  setFilesUploaded(files: number): void {
    this.filesUploaded.next(files);
  }

  uploadDocuments(data: any): Observable<any> {
    return this.http.post<any>(`${environment.documents.upload}`, data);
  }

  getDocumentsTypeBlob(): Observable<any> {
    return this.http.get<any>(`${environment.documents.typeBlob}`);
  }

  getDocumentLocationByItem(idItem: string, status: string): Observable<any> {
    return this.http.get<any>(`${environment.documents.documentLocationByItem}`, { params: { idItem, status } });
  }

  downloadDocument(pathAndFileName: string): Observable<any> {
    return this.http.get(`${environment.documents.download}`,
      { params: { pathAndFileName }, responseType: 'text' }
    );
  }

  setStatusDocument(document: ProjectFileModel): Observable<any> {
    return this.http.put<any>(`${environment.documents.manageDocument}`, document);
  }

  updateDocument(data: any, action: string): Observable<any> {
    if ( action === 'Edit' ) {
      return this.http.put<any>(`${environment.documents.manageDocument}`, data);
    } else {
      return this.http.post<any>(`${environment.documents.rename}`, data);
    }
  }

}
