import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-internal-details-tasks',
  templateUrl: './internal-details-tasks.component.html'
})
export class InternalDetailsTasksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
