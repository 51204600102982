export function cleanNullAttributes(object: any) {
  for (var propName in object) {
    if (object[propName] === null || object[propName] === undefined || object[propName].length === 0) {
      delete object[propName];
    }
  }
  return object;
}

export function findByMatchingProperties(array, properties) {
  return array.filter(function (property) {
    return Object.keys(properties).every(function (key) {
      return properties[key].some((element) => {
        return property[key] === element;
      });
    });
  });
}

export function findByMatchingProperty(array, properties) {
  return array.filter(function (propertie) {
    return Object.keys(properties).every(function (key) {
      return propertie[key] === properties[key];
    });
  });
}

export function loadSideBarConfiguration(idMenu: string) {
  let sidebar = document.querySelector(".sidebar");
  let closeBtn = document.querySelector("#" + idMenu);

  closeBtn.addEventListener("click", () => {
    sidebar.classList.toggle("open");
    menuBtnChange();
  });

  function menuBtnChange() {
    if (sidebar.classList.contains("open")) {
      closeBtn.classList.replace("bx-menu", "bx-menu-alt-right");
      document.querySelector(".margin-screen").classList.add("md:ml-14");
      document.querySelector(".margin-screen").classList.remove("md:ml-3");
    } else {
      closeBtn.classList.replace("bx-menu-alt-right", "bx-menu");
      document.querySelector(".margin-screen").classList.add("md:ml-3");
      document.querySelector(".margin-screen").classList.remove("md:ml-14");
    }
  }
}

export function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * Number((Math.abs(num) / 1000).toFixed(1)) + "k"
    : Math.sign(num) * Math.abs(num);
}

export function searchJson(jsonObj: any, targetValue: any): any {
  let foundObjects = {};

  for (const key in jsonObj) {
    const value = jsonObj[key];
    if (value?.toString().includes(targetValue)) {
      foundObjects = jsonObj;
      break;
    }
  }

  return foundObjects;
}

export function orderArraysByName(array1: any[], array2: any[]): [any[], any[]] {
  const clonedArray1 = [...array1];
  const clonedArray2 = [...array2];

  // Sort both arrays based on the name property
  const comparator = (a: any, b: any) => a.name.localeCompare(b.name);

  clonedArray1.sort(comparator);
  clonedArray2.sort(comparator);

  // Create a mapping of names to their corresponding items in array1
  const nameToItemMap = new Map<string, any>();
  clonedArray1.forEach(item => {
    nameToItemMap.set(item.name, item);
  });

  // Order array2 based on the order of array1, with unmatched names at the end
  const orderedArray2 = clonedArray2.sort((a, b) => {
    const itemA = nameToItemMap.get(a.name);
    const itemB = nameToItemMap.get(b.name);

    if (itemA && itemB) {
      return clonedArray1.indexOf(itemA) - clonedArray1.indexOf(itemB);
    } else if (itemA) {
      return -1;
    } else if (itemB) {
      return 1;
    } else {
      // Both names are not present in array1, order them by name
      return comparator(a, b);
    }
  });

  return [clonedArray1, orderedArray2];
}
