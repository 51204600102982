import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WorksService {

  private headers = { headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
  })};

  constructor(
    public readonly http: HttpClient
  ) { }

  getAllWorks(projects: string[]) {
    return this.http.post(`${environment.allJobs}`, projects, this.headers);
  }

  getWorksByProjectNumber(projectNumber: string): Observable<any> {
    return this.http.get(`${environment.jobs}?numeroProyecto=${projectNumber}`, this.headers);
  }

}
