import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";

@Directive({
  selector: '[inputEmail]',
  providers: [{provide: NG_VALIDATORS, useExisting: InputEmailDirective, multi: true}]
})
export class InputEmailDirective implements Validator {

  isEmailValid(email: string): boolean {
    const regexEmail = '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$';
    return new RegExp(regexEmail).test(email);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const validEmail = this.isEmailValid(control.value);
      return !validEmail ? { email: { value: control.value } } : null;
    } else {
      return null;
    }
  }

}