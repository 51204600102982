import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth/auth.service";
import { loadSideBarConfiguration } from "src/app/utils/utils";

@Component({
  selector: "app-advanced-settings",
  templateUrl: "./advanced-settings.component.html",
})
export class AdvancedSettingsComponent implements OnInit {
  showAdvancedSettings = true;
  type = false;
  selectedView: string;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService
  ) {
    router.events.subscribe((val) => {
      if (val["url"] === "/home/advanced-settings") {
        this.showAdvancedSettings = true;
        if (sessionStorage.getItem("tipo") === "Interno") {
          this.type = true;
        } else {
          this.type = false;
        }
      } else {
        this.showAdvancedSettings = false;
      }
    });
  }

  ngOnInit(): void {
    loadSideBarConfiguration('btnMenuInternalAdvancedSettings');
  }

  logout() {
    this.authService.logout();
  }

  get fullName() {
    return sessionStorage.getItem("nombreCompleto");
  }
}
