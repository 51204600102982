// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  clientID: "b1af6507-e385-4cdf-8460-aa55fe28743a",
  authToken:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Seguridad",
  auth: "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Contacts/validarusuario",
  historicPassword: "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/PasswordHistory/getpasswordhistory",
  generateToken:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Contacts/generartoken",
  testCode:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Contacts/comprobarcodigo",
  changePassword:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Contacts/cambioclave",
  countries:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Maestros/obtenercountry",
  states:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Maestros/obtenerdepartment",
  cities:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Maestros/obtenercity",
  saveContact:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Contacts/GuardarContacto",
  contacts:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Contacts/ObtenerContactos",
  saveCompanyName:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/RazonSocial/GuardarRazonSocial",
  companyNames:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/RazonSocial/ObtenerRazonSocial",
  jobs: "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Jobs/ObtenerJobsProyecto",
  allJobs:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Jobs/ObtenerJobsProyectoCompletos",
  properties:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Propiedades/ObtenerPropiedades",
  allProperties:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Propiedades/ObtenerPropiedadesCompletas",
  allTasks:
    "https://loganportalcliente20210622195724.azurewebsites.net/api/Tareas/ObtenerTareasProyectoCompleto",
  tasks:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Tareas/ObtenerTareasProyecto",
  tasksByUser:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Tareas/obtenertareasusuario",
  createTask:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Tareas/guardartarea",
  updateTask:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Tareas/actualizartarea",
  projects:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Projects/ObtenerProyectosCliente",
  updateProject:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Projects/actualizarproyectos",
  updateProperty:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Propiedades/actualizarpropiedades",
  relationDocuments:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/CargaDocumento/obtenerrelaciondocumentos",
  deleteRelationDocuments:
    "https://loganportalcliente20210622195724.azurewebsites.net/api/CargaDocumento/EliminarDocumento",
  updateRelationDocumetns:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/CargaDocumento/actualizardocumentorelacionado",
  uploadDocument:
    "https://loganportalcliente20210622195724.azurewebsites.net/api/CargaDocumento/CargaDocumentoRelacionado",
  viewDocument:
    "https://loganportalcliente20210622195724.azurewebsites.net/api/CargaDocumento/VerDocumento",
  advances:
    "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Anticipos/ObtenerAnticipos",
  internalServices: {
    getJobsRol:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Jobs/obtenerjobsporrol",
    updateJobs:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Jobs/actualizarjobs",
    getExpenses:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Expenses/obtenerexpenses",
    notificationDetails:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Propiedades/notificaciondetails",
    saveDocuments:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/CargaDocumento/guardardocumentorelacionado",
    tasksProjects:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Tareas/obtenertareasproyectnumber",
    collabotarions: "https://prd-logan-administracion.azurewebsites.net/api/Collaboration/getcollaboration",
    collaborationByJob: "https://prd-logan-administracion.azurewebsites.net/api/Collaboration/getcollaborationxjob",
    createCollaboration: "https://prd-logan-administracion.azurewebsites.net/api/Collaboration/createcollaboration",
    updateCollaboration: "https://prd-logan-administracion.azurewebsites.net/api/Collaboration/updatecollaboration",
  },
  masters: {
    documentsType:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Maestros/obtenerdocumentstype",
    documentsCategory:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Maestros/obtenerdocumentscategory",
    taskStatus:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Maestros/obtenerstatustask",
    taskType:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Maestros/obtenertypetask",
    taskSubType:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Maestros/obtenertasksubtype",
    taskCode:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Maestros/obtenertaskcode",
    taskCategory:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Maestros/obtenercategorytask",
    sector:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Maestros/obtenersector",
    internalContacts:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Contacts/obtenercontactosinternos",
    statusJobs:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Maestros/obtenerstatusjobs",
    statusProjects:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Maestros/obtenerstatusprojects",
    propertiesType:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Maestros/obtenerpropertytype",
    externalContacts:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Contacts/obtenercontactosexternos",
    territory:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/Maestros/obtenerterritory",
    userInfoGraph:
      "https://prd-logan-administracion.azurewebsites.net/api/transversal/getinfouserinternal",
    businessUnit:
      "https://prd-logan-administracion.azurewebsites.net/api/businessUnit/getbusinessunit",
    sectors:
      "https://prd-logan-administracion.azurewebsites.net/api/sector/getsector",
    contactClasses:
      "https://prd-logan-administracion.azurewebsites.net/api/contactclass/getcontactclass",
    userTenant:
      "https://loganautenticacionportal.azurewebsites.net/api/maestros/obteneruserstenant",
    inspectionStatus: "https://prd-logan-administracion.azurewebsites.net/api/StatusInspections/getstatusinspections"
  },
  advancedSettings: {
    contacts: {
      get: "https://prd-logan-administracion.azurewebsites.net/api/contacts/getcontacts",
      create:
        "https://prd-logan-administracion.azurewebsites.net/api/contacts/createcontacts",
      update:
        "https://prd-logan-administracion.azurewebsites.net/api/contacts/updatecontacts",
      sendEmail:
        "https://prd-logan-administracion.azurewebsites.net/api/Transversal/enviaremail",
    },
    dropdowns: {
      city: "https://prd-logan-administracion.azurewebsites.net/api/city/getcity",
      countries:
        "https://prd-logan-administracion.azurewebsites.net/api/country/getcountry",
      subtypes:
        "https://prd-logan-administracion.azurewebsites.net/api/subtype/getsubtypeuser",
      types:
        "https://prd-logan-administracion.azurewebsites.net/api/typeuser/gettypeuser",
    },
    roles: {
      get: "https://prd-logan-administracion.azurewebsites.net/api/roles/getroles",
      getAll:
        "https://prd-logan-administracion.azurewebsites.net/api/roles/getrolesfeatures",
      create:
        "https://prd-logan-administracion.azurewebsites.net/api/roles/createrol",
      update:
        "https://prd-logan-administracion.azurewebsites.net/api/roles/updateroles",
    },
    features: {
      get: "https://prd-logan-administracion.azurewebsites.net/api/features/getfeatures",
      getAll:
        "https://prd-logan-administracion.azurewebsites.net/api/features/getallfeatures",
      create:
        "https://prd-logan-administracion.azurewebsites.net/api/features/createfeature",
      update:
        "https://prd-logan-administracion.azurewebsites.net/api/features/updatefeature",
    },
    rules: {
      get: "https://prd-logan-administracion.azurewebsites.net/api/rulejobs/getrulejobs",
      create:
        "https://prd-logan-administracion.azurewebsites.net/api/rulejobs/createrulejobs",
      update:
        "https://prd-logan-administracion.azurewebsites.net/api/rulejobs/updaterulejobs",
    },
  },
  accounts: {
    get: "https://prd-logan-administracion.azurewebsites.net/api/company/getcompany",
    create:
      "https://prd-logan-administracion.azurewebsites.net/api/company/createcompany",
    update:
      "https://prd-logan-administracion.azurewebsites.net/api/company/updatecompany",
    projectsXcompany:
      "https://prd-logan-administracion.azurewebsites.net/api/company/getcompanyxproject",
    projectsCompany:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/projects/obtenerproyectos",
  },
  internalContacts: {
    get: "https://prd-logan-administracion.azurewebsites.net/api/contactinformationrecord/getcontactinformationrecord",
    create:
      "https://prd-logan-administracion.azurewebsites.net/api/contactinformationrecord/createcontactinformationrecord",
    update:
      "https://prd-logan-administracion.azurewebsites.net/api/contactinformationrecord/updatecontactinformationrecord",
    recordsXProperties:
      "https://prd-logan-administracion.azurewebsites.net/api/contactinformationrecord/getcontactinformationrecordsxproperties",
    recordsIds:
      "https://prd-logan-administracion.azurewebsites.net/api/contactinformationrecord/getContactinformationrecordxids",
    recordsXJobs:
      "https://prd-logan-administracion.azurewebsites.net/api/contactinformationrecord/getcontactinformationrecordxjobs",
    recordsXProjects:
      "https://prd-logan-administracion.azurewebsites.net/api/contactinformationrecord/getcontactinformationrecordxproject",
    recordsJobs:
      "https://prd-logan-administracion.azurewebsites.net/api/Jobs/obtenerjobscontactsrecord",
    contactCompanies:
      "https://prd-logan-administracion.azurewebsites.net/api/company/getcompanyxcontacts",
    jobsContact:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/jobs/obtenerjobscontactsrecord",
    propertiesContact:
      "https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/propiedades/obtenerpropertiescontactsrecord",
  },
  documents: {
    upload: "https://prd-logan-administracion.azurewebsites.net/api/UpLoadDocument",
    typeBlob: "https://prd-logan-administracion.azurewebsites.net/api/DocumentBlob/getdocumenttypeblob",
    documentLocationByItem: "https://prd-logan-administracion.azurewebsites.net/api/DocumentLocation/getdocumentlocationbyitem",
    manageDocument: "https://prd-logan-administracion.azurewebsites.net/api/DocumentLocation/updatedocumentlocation",
    download: "https://prd-logan-administracion.azurewebsites.net/api/UploadDocument/getdownload",
    rename: "https://prd-logan-administracion.azurewebsites.net/api/UploadDocument/RenameAsync",
  },
  forgotPassword: {
    validateEmail: 'https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/ForgotPassword/forgotpassword',
    sendEmailResetPassword: 'https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/ForgotPassword/sendEmailForgot',
    validateCode: 'https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/ForgotPassword/comprobarcodigo',
    changePassword: 'https://prd-general-logan-autenticacion-portal.azurewebsites.net/api/ForgotPassword/cambioclave'
  },
  agmConfiguration: {
    apiKey: "AIzaSyDZCXg3yAaca37D9ZqaPLiosC76TOomL4A",
  },
  googleRecaptcha: {
    siteKey: "6LceAGopAAAAAAZ4OiZMVipr_4m68vsi31wrywvz",
  },
  inspection: {
    get: "https://prd-logan-administracion.azurewebsites.net/api/Inspections/getinspections",
    getByJob: "https://prd-logan-administracion.azurewebsites.net/api/Inspections/getinspectionsxjob",
    create: "https://prd-logan-administracion.azurewebsites.net/api/Inspections/createinspections",
    update: "https://prd-logan-administracion.azurewebsites.net/api/Inspections/updateinspections"
  }
};
