import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../../../../../services/auth/auth.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-code-confirmation',
  templateUrl: './code-confirmation.component.html'
})
export class CodeConfirmationComponent implements OnInit {

  private email: string;

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _AuthService: AuthService,
    private readonly _toastr: ToastrService,
    private readonly _spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe(params => {
      this.email = params['email'];
    });
  }

  sendEmailResetPassword() {
    this._spinner.show();
    this._AuthService.sendEmailResetPassword({ Email: this.email }).subscribe({
      next: () => {
        this._spinner.hide();
        this._toastr.success('Se ha enviado un correo con el código de recuperación de contraseña');
      },
      error: () => {
        this._spinner.hide();
        this._toastr.error('Ha ocurrido un error al enviar el correo, por favor intente nuevamente.');
      }
    });
  }

}
