import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-works-details',
  templateUrl: './works-details.component.html'
})
export class WorksDetailsComponent implements OnInit {

  openTab: number = 1;
  indexWorks: number;
  works: string[] = [];

  constructor(
    private readonly _Activatedroute: ActivatedRoute,
    private readonly spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getParameteresURL();
  }

  getParameteresURL() {
    this._Activatedroute.paramMap.subscribe(params => { 
      this.indexWorks = Number(params.get('id'));
      this.getWorks();
    });
  }

  getWorks() {
    this.works = JSON.parse(sessionStorage.getItem("jobs"));
  }

  toggleTabs($tabNumber: number){
    this.openTab = $tabNumber;
  }

}
