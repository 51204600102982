import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AdvacedSettingsFeatureModel } from "src/app/models/advanced-settings/feature.model";
import { AdvancedSettingsRoleModel } from "src/app/models/advanced-settings/role.model";
import { AdvancedSettingsService } from "src/app/services/admin-services/advanced-settings/advanced-settings.service";
import { ManageSecurityRolesDialogComponent } from "./manage-security-roles-dialog/manage-security-roles-dialog.component";

@Component({
  selector: "app-security-roles",
  templateUrl: "./security-roles.component.html",
})
export class SecurityRolesComponent implements OnInit {
  roles: AdvancedSettingsRoleModel[];
  features: AdvacedSettingsFeatureModel[];

  constructor(
    private readonly dialog: MatDialog,
    private readonly _advancedSettingsService: AdvancedSettingsService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getRoles();
    this.getFeatures();
  }

  openManageRolDialog(action?: string, rol?: AdvancedSettingsRoleModel) {
    const dialogRef = this.dialog.open(ManageSecurityRolesDialogComponent, {
      width: "850pt",
      autoFocus: false,
      disableClose: false,
      data: {
        rol,
        action,
        lastId: this.roles[this.roles.length - 1].id,
        features: this.features
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getRoles();
      }
    });
  }

  private getFeatures() {
    this.spinner.show();
    this._advancedSettingsService.getRolesFeatures().subscribe(
      (features: any[]) => {
        this.features = features;
        this.spinner.hide();
      },
      () => {
        this.toastr.error("Error! Please try again", "Error", {
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.spinner.hide();
      }
    );
  }

  private getRoles() {
    this.spinner.show();
    this._advancedSettingsService.getAllRoles().subscribe(
      (roles: AdvancedSettingsRoleModel[]) => {
        this.roles = roles;
        this.spinner.hide();
      },
      () => {
        this.toastr.error("Error! Please try again", "Error", {
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.spinner.hide();
      }
    );
  }
}
