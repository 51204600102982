import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AdvancedSettingsService } from "src/app/services/admin-services/advanced-settings/advanced-settings.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ConfirmDialogComponent } from "src/app/components/confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-manage-security-roles-dialog",
  templateUrl: "./manage-security-roles-dialog.component.html",
})
export class ManageSecurityRolesDialogComponent implements OnInit {
  rolForm: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<ManageSecurityRolesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly _advancedSettingsService: AdvancedSettingsService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: ToastrService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.createRolForm();
  }

  openConfirmRoleDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        title: "Save a New Role",
        description: "¿Are you sure you want to save a new role?",
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.managementRole();
      }
    });
  }

  managementRole(): void {
    this.rolForm.get("Id").enable();
    this.spinner.show();
    this.rolForm
      .get("Id")
      .setValue(this.data.rol ? +this.data.rol.id : +this.rolForm.value.Id);
    if (this.data.action === "create") {
      this._advancedSettingsService.createRol(this.rolForm.value).subscribe(
        () => {
          this.spinner.hide();
          this.toastr.success("Role created successfully");
          this.dialogRef.close(true);
        },
        () => {
          this.spinner.hide();
          this.toastr.error("Error creating role");
        }
      );
    } else {
      this._advancedSettingsService.updateRol(this.rolForm.value).subscribe(
        () => {
          this.spinner.hide();
          this.toastr.success("Role updated successfully");
          this.dialogRef.close(true);
        },
        () => {
          this.spinner.hide();
          this.toastr.error("Error updating role");
        }
      );
    }
  }

  private createRolForm(): void {
    this.rolForm = this.formBuilder.group({
      Id: [
        this.data.rol ? this.data.rol.id : this.data.lastId + 1,
        Validators.required,
      ],
      Rol_Name: [
        this.data.rol ? this.data.rol.rol_Name : "",
        Validators.required,
      ],
      Status: [this.data.rol ? this.data.rol.status : true],
      FeaturesId: this.formBuilder.array([]),
    });
    this.rolForm.get("Id").disable();
    this.addNewFeatures();
  }

  get features(): UntypedFormArray {
    return this.rolForm.get("FeaturesId") as UntypedFormArray;
  }

  private addNewFeatures() {
    if (this.data.rol) {
      this.data.rol.featuresId.forEach((feature) => {
        const featureName = this.data.features.find(
          (f) => f.id === feature.idFeature
        ).featureName;
        this.features.push(
          this.formBuilder.group({
            IdFeatureRelationshipRoles: 0,
            IdRol: 0,
            IdFeature: feature.idFeature,
            Create: feature.create,
            Read: feature.read,
            Write: feature.write,
            Delete: feature.delete,
            Status: true,
            Name: featureName,
          })
        );
      });
    } else {
      this.data.features.forEach((feature) => {
        this.features.push(
          this.formBuilder.group({
            IdFeatureRelationshipRoles: 0,
            IdRol: 0,
            IdFeature: feature.id,
            Create: false,
            Read: false,
            Write: false,
            Delete: false,
            Status: false,
            Name: feature.featureName,
          })
        );
      });
    }
  }
}
