import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: "app-advances-table",
  templateUrl: "./advances-table.component.html",
})
export class AdvancesTableComponent implements OnInit {
  constructor(
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loadSideBarConfiguration();
  }

  loadSideBarConfiguration() {
    let sidebar = document.querySelector(".sidebar");
    let closeBtn = document.querySelector("#btnMenuAdvances");

    closeBtn.addEventListener("click", () => {
      sidebar.classList.toggle("open");
      menuBtnChange();
    });

    function menuBtnChange() {
      if (sidebar.classList.contains("open")) {
        closeBtn.classList.replace("bx-menu", "bx-menu-alt-right");
        document.querySelector(".margin-screen").classList.add("md:ml-14");
        document.querySelector(".margin-screen").classList.remove("md:ml-3");
      } else {
        closeBtn.classList.replace("bx-menu-alt-right", "bx-menu");
        document.querySelector(".margin-screen").classList.add("md:ml-3");
        document.querySelector(".margin-screen").classList.remove("md:ml-14");
      }
    }
  }

  logout() {
    this.authService.logout();
  }

  get fullName() {
    return sessionStorage.getItem("nombreCompleto");
  }
}
