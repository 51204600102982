import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CanComponentDeactivate } from 'src/app/config/prevent-change-route/prevent-change-route.guard';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-companyname',
  templateUrl: './companyname.component.html'
})
export class CompanynameComponent implements OnInit, CanComponentDeactivate {

  companyNameForm: UntypedFormGroup;

  constructor() { }

  async validateForm(): Promise<boolean> {
    if (this.companyNameForm.dirty) {
        const confirm = await Swal.fire({
        title: '¿Estás seguro que deseas salir?',
        text: "Perderas los cambios si sales de la página",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No, deseo quedarme',
        confirmButtonText: 'Si, deseo salir'
      }).then((result) => {
        if (result.isConfirmed) {
          return false;
        } else {
          return true;
        }
      });
      return confirm;
    } else {
      return false;
    }
  }

  ngOnInit(): void {
  }

  companyForm(event) {
    this.companyNameForm = event;
  }

}
