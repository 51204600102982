import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { InternalWorksResponse } from 'src/app/models/works/internal.works.response.model';

@Component({
  selector: 'app-calculatecards',
  templateUrl: './calculatecards.component.html'
})
export class CalculatecardsComponent implements OnInit {

  inProgressJobs = [];
  totalInprogressJobs: string | number;
  invoiceJobs = [];
  totalInvoiceJobs: string | number;
  paidJobs = [];
  totalPaidJobs: string | number;
  remaingJobs = [];
  totalRemaingJobs: string | number;
  activeJobs = [];    
  totalActiveJobs: string | number;
  pastDueJobs = [];
  totalPastDueDays: number = 0;

  @Input() set internalJobs(jobs: InternalWorksResponse[]) {
    this.calculatedCards(jobs);
    this.calculatePastDue(jobs);
  }

  constructor() { }

  ngOnInit(): void {

  }

  calculatePastDue(jobs: InternalWorksResponse[]) {
    this.totalPastDueDays = 0;
    this.pastDueJobs = jobs;
    jobs.forEach(job => {
      this.totalPastDueDays = this.totalPastDueDays += Math.abs(moment(job.due_Date).diff(moment(new Date()), 'days'));
    });
  }

  calculatedCards(jobs: InternalWorksResponse[]) {

    this.inProgressJobs = jobs.filter(job => job.job_Status === 'In Progress');
    this.totalInprogressJobs = this.kFormatter(this.inProgressJobs.reduce((total, job) => total + job.equivalent_USD, 0)); 

    this.invoiceJobs = jobs.filter(job => job.job_Status === 'Invoice');
    this.totalInvoiceJobs = this.kFormatter(this.invoiceJobs.reduce((total, job) => total + job.equivalent_USD, 0)); 

    this.paidJobs = jobs.filter(job => job.job_Status === 'Paid');
    this.totalPaidJobs = this.kFormatter(this.paidJobs.reduce((total, job) => total + job.equivalent_USD, 0)); 

    this.remaingJobs = jobs.filter(job => job.job_Status === 'Remaining');
    this.totalRemaingJobs = this.kFormatter(this.remaingJobs.reduce((total, job) => total + job.equivalent_USD, 0));             

    this.activeJobs = jobs.filter(job => job.job_Status === 'Activo');
    this.totalActiveJobs = this.kFormatter(this.activeJobs.reduce((total, job) => total + job.equivalent_USD, 0));      

  }

  kFormatter(num) {
    return Math.abs(num) > 999 ? Math.sign(num)*Number((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
  }

}


