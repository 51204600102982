import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { CountryResponse } from "../../../models/localizations/country.reponse.model";
import { StateResponse } from "../../../models/localizations/state.response.model";
import { CityResponse } from "../../../models/localizations/city.response.model";
import { LocalizationsService } from "../../../services/localizations/localizations.service";
import { combineLatest } from "rxjs";
import { CompanyNameService } from "../../../services/admin-services/company-name/company-name.service";
import { ToastrService } from "ngx-toastr";
import { CompanyNameResponse } from "src/app/models/company-names/company-name.response.model";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: "app-form-companyname",
  templateUrl: "./form-companyname.component.html",
})
export class FormCompanynameComponent implements OnInit {
  public companyNameForm: UntypedFormGroup;
  @Output() companyNameFormEvent = new EventEmitter<UntypedFormGroup>();
  public countriesResponse: CountryResponse[];
  public statesResponse: StateResponse[];
  public citiesResponse: CityResponse[];
  public companyNamesResponse: CompanyNameResponse[];
  public states: StateResponse[];
  public cities: CityResponse[];
  public countryId = [];
  public stateId = [];
  public showCompanyNameForm = false;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly localizationsService: LocalizationsService,
    private readonly companyNameService: CompanyNameService,
    private readonly toastr: ToastrService,
    private readonly spinner: NgxSpinnerService,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.buildFormCompanyName();
    this.getLocalizations();
  }

  private buildFormCompanyName() {
    this.companyNameForm = this.formBuilder.group({
      companyName: this.formBuilder.array([
        this.formBuilder.group({
          Nombre: ["", Validators.required],
          Nit: ["", Validators.required],
          Direccion: ["", Validators.required],
          Pais: [null, Validators.required],
          Departamento: [null, Validators.required],
          Ciudad: [null, Validators.required],
          CodigoZip: ["", Validators.required],
          Telefono: ["", Validators.required],
          CorreoFirmaElectronica: ["", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
          Guid: [sessionStorage.getItem("guidClient"), Validators.required],
          Cliente: [sessionStorage.getItem("companyName"), Validators.required],
          GuidCliente: [sessionStorage.getItem("guidCompany"), Validators.required]
        }),
      ]),
    });
    this.companyNameFormEvent.emit(this.companyNameForm);
  }

  public get companyNames() {
    return this.companyNameForm.get("companyName") as UntypedFormArray;
  }

  public addCompanyName() {
    this.companyNames.push(
      this.formBuilder.group({
        Nombre: ["", Validators.required],
        Nit: ["", Validators.required],
        Direccion: ["", Validators.required],
        Pais: [null, Validators.required],
        Departamento: [null, Validators.required],
        Ciudad: [null, Validators.required],
        CodigoZip: ["", Validators.required],
        Telefono: ["", Validators.required],
        CorreoFirmaElectronica: ["", Validators.required],
        Guid: [sessionStorage.getItem("guidClient"), Validators.required],
        Cliente: [sessionStorage.getItem("companyName"), Validators.required],
        GuidCliente: [sessionStorage.getItem("guidCompany"), Validators.required]
      })
    );
  }

  public deleteCompanyName(index) {
    this.companyNames.removeAt(index);
  }

  public getLocalizations() {
    this.spinner.show();
    combineLatest([
      this.localizationsService.currentCountries,
      this.localizationsService.currentStates,
      this.localizationsService.currentCities,
      this.companyNameService.currentCompanyNames
    ]).subscribe(([countries, states, cities, companyNames]) => {
      if (countries !== null && states !== null && cities !== null && companyNames !== null) {
        this.countriesResponse = countries;
        this.statesResponse = states;
        this.citiesResponse = cities;
        this.companyNamesResponse = companyNames;
        this.spinner.hide();
      }
    });
  }

  public selectCountry(indexCountry) {
    if (this.countryId[indexCountry]) {
      this.states = this.statesResponse.filter(
        (item) => item.country_ID == this.countryId[indexCountry]
      );
    }
  }

  public selectState(indexState) {
    if (this.stateId[indexState]) {
      this.cities = this.citiesResponse.filter(
        (item) => item.department_ID == this.stateId[indexState]
      );
    }
  }

  public saveCompanyNames() {
    if (this.companyNameForm.invalid) {
      return Object.values(this.companyNameForm.controls).forEach((control) => {
        if (control instanceof UntypedFormGroup) {
          Object.values(control.controls).forEach((ctrl) =>
            ctrl.markAsTouched()
          );
        } else {
          control.markAllAsTouched();
        }
      });
    } else {
      this.spinner.show();
      this.companyNameService
        .saveCompanyNames(this.companyNameForm.value.companyName)
        .subscribe((resp) => {
          if (resp) {
            this.toastr.success(
              "Se ha registrado exitosamente la/las razon(es) sociales",
              "Registro razones sociales",
              {
                progressBar: true,
                progressAnimation: "increasing",
              }
            );
            this.companyNameForm.reset();
            this.getNewCompanyNames();
            this.spinner.hide();
            this.showCompanyNameForm = false;
          } else {
            this.toastr.error(
              "Se han presentado problemas para almacenar la/las razon(es) sociales",
              "Registro razones sociales",
              {
                progressBar: true,
                progressAnimation: "increasing",
              }
            );
            this.spinner.hide();
          }
        });
    }
  }

  public getNewCompanyNames() {
    this.companyNameService.getCompanyNames().subscribe( (resp:CompanyNameResponse[]) => {
      this.companyNameService.setCompanyNames(resp);
    })
  }

  public toggleCompanyNameForm() {
    this.showCompanyNameForm = !this.showCompanyNameForm;
  }

  logout() {
    this.authService.logout();
  }

  get fullName() {
    return sessionStorage.getItem("nombreCompleto");
  }

}
