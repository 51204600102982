import { Component, Input, OnInit, ViewChild } from "@angular/core";

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexResponsive,
  ApexXAxis,
  ApexLegend,
  ApexFill,
  ApexTooltip,
  ApexYAxis,
} from "ng-apexcharts";
import { TaskModel } from "src/app/models/tasks/tasks.model";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  legend: ApexLegend;
  fill: ApexFill;
  tooltip: ApexTooltip;
};
@Component({
  selector: "app-tasks-graphics",
  templateUrl: "./tasks-graphics.component.html",
})
export class TasksGraphicsComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  @Input() title: string;
  @Input() horizontal: boolean;
  @Input() taskStatus: any[];
  @Input() taskCodes: any[];
  @Input() set tasks(tasks: TaskModel[]) {
    this.settingsChart(tasks);
  };

  types: any[] = [];
  assigneds: any[] = [];
  public chartOptions: Partial<ChartOptions>;

  constructor() {}

  ngOnInit(): void { }

  settingsChart(tasks: TaskModel[]) {
    this.chartOptions = {
      series: this.getSeries(tasks),
      chart: {
        type: "bar",
        height: 470,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: this.horizontal
        },
      },
      xaxis: {
        type: "category",
        categories: this.getCategories(tasks),
      },
      yaxis: {
        labels: {
          formatter(val) {
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          },
        },
      },
      legend: {
        position: this.horizontal ? "bottom" : "right",
        offsetY: 10,
        markers: {
          fillColors: [
            '#415AA8',
            '#1A468D',
            '#254382',
            '#51737F',
            '#0080C8',
            '#03B1E5',
            '#ABC8E6',
            '#5D6770',
            '#FE0000',
            '#FD4600',
            '#FF6D3E',
            '#FAA21C',
            '#B4B5B7',
            '#FDE3C0',
          ],
        },
      },
      fill: {
        colors: [
          '#415AA8',
          '#1A468D',
          '#254382',
          '#51737F',
          '#0080C8',
          '#03B1E5',
          '#ABC8E6',
          '#5D6770',
          '#FE0000',
          '#FD4600',
          '#FF6D3E',
          '#FAA21C',
          '#B4B5B7',
          '#FDE3C0',
        ],
        opacity: 1,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          if (this.horizontal) {
            const serie = w.globals.seriesNames[seriesIndex];
            const assigned = this.assigneds.find(
              (assign) => assign.assigned_to_Name === serie
            );
            return (
              "<div class='flex flex-col p-2'>" +
              "<span>" +
              "<b>Status: </b> " +
              this.taskStatus?.find(
                (status) => status.name_Status === assigned.task_Status_Description
              )?.name_Status +
              "</span>" +
              "<span>" +
              "<b>Task Category: </b> " +
              this.taskCodes?.find(
                (status) => status.name_Code === assigned.task_Category_Description
              )?.name_Code +
              "</span>" +
              "<span>" +
              "<b>Assigned to: </b> " +
              assigned?.assigned_to +
              "</span>" +
              "<span>" +
              "<b>Start Date: </b> " +
              assigned?.startt_Date.split("T")[0] +
              "</span>" +
              "<span>" +
              "<b>Expiration Date: </b> " +
              assigned?.expiration_Date.split("T")[0] +
              "</span>" +
              "</div>"
            );
          } else {
            const serie = w.globals.seriesNames[seriesIndex];
            const type = this.types.find(
              (typeValue) => typeValue.task_Status_Description === serie
            );
            return (
              "<div class='flex flex-col p-2'>" +
              "<span>" +
              "<b>Status: </b> " +
              type?.task_Status_Description +
              "</span>" +
              "<span>" +
              "<b>Task Category: </b> " +
              type?.task_Category_Description +
              "</span>" +
              "<span>" +
              "<b>Assigned to: </b> " +
              type?.assigned_to +
              "</span>" +
              "<span>" +
              "<b>Start Date: </b> " +
              type?.startt_Date.split("T")[0] +
              "</span>" +
              "<span>" +
              "<b>Expiration Date: </b> " +
              type?.expiration_Date.split("T")[0] +
              "</span>" +
              "</div>"
            );
          }
        },
        marker: {
          fillColors: [
            '#415AA8',
            '#1A468D',
            '#254382',
            '#51737F',
            '#0080C8',
            '#03B1E5',
            '#ABC8E6',
            '#5D6770',
            '#FE0000',
            '#FD4600',
            '#FF6D3E',
            '#FAA21C',
            '#B4B5B7',
            '#FDE3C0',
          ],
        },
      },
    };
  }

  getSeries(tasks: TaskModel[]) {
    let series = [];
    const types = tasks
      .map((task: TaskModel) => task.task_Category_Description)
      .filter(
        (value: string, index: number, self: string[]) =>
          self.indexOf(value) === index
      );

    const status = tasks
      .map((task: TaskModel) => task.task_Status_Description)
      .filter(
        (value: string, index: number, self: string[]) =>
          self.indexOf(value) === index
      );

    const assigneds = tasks
      .map((task: TaskModel) => task.assigned_to_Name)
      .filter(
        (value: string, index: number, self: string[]) =>
          self.indexOf(value) === index
      );

    if (this.horizontal) {
      assigneds.forEach((assigned: string) => {
        series.push({ name: assigned, data: [] });
      });
      series.forEach((serie) => {
        assigneds.forEach((assigned: string) => {
          if (serie.name === assigned) {
            const assigneds = tasks.filter(
              (task: TaskModel) => task.assigned_to_Name === assigned
            );
            assigneds.forEach((value) => {
              this.assigneds.push(value);
            });
            serie.data.push(assigneds.length);
          } else {
            serie.data.push(0);
          }
        });
      });
    } else {
      status.forEach((status: string) => {
        series.push({ name: status, data: [] });
      });
      series.forEach((serie) => {
        types.forEach((type: string) => {
          const types = tasks.filter(
            (task: TaskModel) =>
              task.task_Category_Description === type && task.task_Status_Description === serie.name
          );
          if (types.length > 0) {
            types.forEach((value) => {
              this.types.push(value);
            });
            serie.data.push(types.length);
          } else {
            serie.data.push(0);
          }
        });
      });
      if (series.length === 1) {
        series.push({ name: "No existen más tipos", data: [0] });
      }
    }
    return series;
  }

  getCategories(tasks: TaskModel[]) {
    if (this.horizontal) {
      return tasks
        .map((task: TaskModel) => task?.assigned_to_Name)
        .filter(
          (value: string, index: number, self: string[]) =>
            self.indexOf(value) === index
        );
    } else {
      return tasks
        .map((task: TaskModel) => task?.task_Category_Description)
        .filter(
          (value: string, index: number, self: string[]) =>
            self.indexOf(value) === index
        );
    }
  }
}
