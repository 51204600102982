import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-auth-navbar',
  templateUrl: './auth-navbar.component.html'
})
export class AuthNavbarComponent implements OnInit {

  navbarOpen = false;

  constructor(private readonly router: Router, private readonly auth: AuthService) { }

  ngOnInit(): void {
  }

  setNavbarOpen() {
    this.navbarOpen = !this.navbarOpen;
  }

  navagiteToLogin() {
    this.router.navigate(['/auth/login']);
  }

}
