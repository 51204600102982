import { Component, Input, OnInit, TemplateRef } from "@angular/core";

@Component({
  selector: "app-custom-tooltip",
  templateUrl: "./custom-tooltip.component.html",
})
export class CustomTooltipComponent implements OnInit {
  @Input() contentTemplate: TemplateRef<any>;

  constructor() {}

  ngOnInit(): void {}
}
