import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { EditTaskDialogComponent } from "src/app/components/tables/tasks/internal/internal-table-tasks/edit-task-dialog/edit-task-dialog.component";
import { InternalTasksService } from "src/app/services/admin-services/tasks/internal-tasks.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { cleanNullAttributes, findByMatchingProperty, loadSideBarConfiguration } from "src/app/utils/utils";
import { TaskModel } from "../../../../../models/tasks/tasks.model";
import * as moment from "moment";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-internal-tasks",
  templateUrl: "./internal-tasks.component.html",
})
export class InternalTasksComponent implements OnInit {
  tasks: TaskModel[];
  filterTasksResponse: TaskModel[];
  filtersTasksForm: UntypedFormGroup;
  task: string;
  taskStatus: string;
  taskCategory: string;
  taskType: string;
  assigned_to_Name: string;
  taskStatusSelect = [];
  taskTypeSelect = [];
  taskCategorySelect = [];
  assignedToSelect = [];
  taskStatusList: any = [];
  taskCategoryList: any = [];
  taskTypeList: any = [];
  taskCodesList: any = [];

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly spinner: NgxSpinnerService,
    private readonly internalTasksService: InternalTasksService,
    private readonly toastr: ToastrService,
    private readonly dialog: MatDialog,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.buildFiltersTasksForms();
    this.manageDependenceTasks();
    loadSideBarConfiguration("btnMenuInternalTasks");
  }

  manageDependenceTasks() {

    const taskCategories$ = this.internalTasksService.getTaskCategory();
    const taskTypes$ = this.internalTasksService.getTaskType();
    const taskCodes$ = this.internalTasksService.getTaskCodes();
    const taskStatus$ = this.internalTasksService.getTaskStatus();

    forkJoin([taskCategories$, taskTypes$, taskCodes$, taskStatus$]).subscribe((resp) => {
      this.taskCategoryList = resp[0];
      this.taskTypeList = resp[1];
      this.taskCodesList = resp[2];
      this.taskStatusList = resp[3];
      this.getTasksByUser();
    });

  }

  getTaskStatusName(id: number) {
    return this.taskStatusList.find((status) => status.id === id)?.name_Status;
  }

  getTaskTypeName(id: number) {
    return this.taskTypeList.find((type) => type.id === id)?.name_Option;
  }

  getTaskCategoryName(id: number) {
    return this.taskCategoryList.find((category) => category.id === id)
      ?.name_Category;
  }

  buildFiltersTasksForms() {
    this.filtersTasksForm = this.formBuilder.group({
      task_Status: [null, Validators.required],
      task_Category: [null, Validators.required],
      task_Type: [null, Validators.required],
      assigned_to_Name: [null, Validators.required],
    });
  }

  getTasksByUser() {
    this.spinner.show();
    this.internalTasksService.getTasksByUser().subscribe(
      (response: TaskModel[]) => {
        this.tasks = response
          .sort(
            (a, b) =>
              Number(moment(a.startt_Date)) - Number(moment(b.startt_Date))
          )
          .reverse();

        this.tasks = this.tasks?.map((task) => {
          const taskCategory = this.taskCodesList?.find(
            (category) => category.id === task.task_Category
          )?.name_Code;
          const taskStatus = this.taskStatusList?.find(
            (status) => status.id === task.task_Status
          )?.name_Status;
          return {
            ...task,
            task_Category_Description: taskCategory,
            task_Status_Description: taskStatus,
          };
        });

        sessionStorage.setItem("tasks", JSON.stringify(this.tasks));
        this.getUniqueValuesOnchangeSelect();
        this.spinner.hide();
      },
      () => {
        this.toastr.error(
          "Se ha presentado un problema cargando las tareas",
          "Tareas",
          {
            progressBar: true,
            progressAnimation: "increasing",
          }
        );
        this.spinner.hide();
      }
    );
  }

  getUniqueValuesOnchangeSelect() {
    this.taskTypeSelect = this.tasks
      .map((property) => property.task_Type)
      .filter((value, index, self) => self.indexOf(value) === index);

    this.assignedToSelect = this.tasks
      .map((account) => account.assigned_to_Name)
      .filter((value, index, self) => self.indexOf(value) === index);

    this.taskStatusSelect = this.tasks
      .map((status) => status.task_Status)
      .filter((value, index, self) => self.indexOf(value) === index);

    this.taskCategorySelect = this.tasks
      .map((category) => category.task_Category)
      .filter((value, index, self) => self.indexOf(value) === index);
  }

  updateInternalFilterJobs(event) {
    this.tasks = event;
  }

  filterTasks() {
    this.filterTasksResponse = JSON.parse(sessionStorage.getItem("tasks"));

    const filters = cleanNullAttributes(this.filtersTasksForm.value);
    const resultFilter = findByMatchingProperty(
      this.filterTasksResponse,
      filters
    );

    this.tasks = resultFilter;
  }

  clearFilters() {
    this.filtersTasksForm.patchValue({
      task_Status: undefined,
      task_Category: undefined,
      task_Type: undefined,
      assigned_to_Name: undefined,
    });
    this.tasks = JSON.parse(sessionStorage.getItem("tasks"));
  }

  logout() {
    this.authService.logout();
  }

  openEditTaskDialog(task?: TaskModel) {
    const dialogRef = this.dialog.open(EditTaskDialogComponent, {
      autoFocus: false,
      disableClose: false,
      data: {
        task,
        taskStatus: this.taskStatusList,
        taskCategory: this.taskCategoryList,
        taskType: this.taskTypeList,
        taskCodes: this.taskCodesList,
        action: "create",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        window.location.reload();
      }
    });
  }

  get fullName() {
    return sessionStorage.getItem("nombreCompleto");
  }
}
