import { Component, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { TaskModel } from "src/app/models/tasks/tasks.model";
import { loadSideBarConfiguration, searchJson } from "src/app/utils/utils";
import { InternalWorksService } from "../../../../../services/admin-services/works/internal-works.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { InternalWorksResponse } from "src/app/models/works/internal.works.response.model";
import { EditTaskDialogComponent } from "../../../tasks/internal/internal-table-tasks/edit-task-dialog/edit-task-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { AuthService } from "src/app/services/auth/auth.service";
import { CanComponentDeactivate } from "src/app/config/prevent-change-route/prevent-change-route.guard";
import { ConfirmDialogNewVersionComponent } from "src/app/components/modals/confirm-dialog/confirm-dialog.component";
import { InternalTasksService } from "src/app/services/admin-services/tasks/internal-tasks.service";
import { InternalContactModel } from "src/app/models/internal-contacts/internal-contact.model";
import { AccountRelationalontactModel } from "src/app/models/accounts/relational-contact.model";
import { InternalContactsService } from "src/app/services/admin-services/internal-contacts/internal-contacts.service";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import {
  UploadFilesComponent
} from "../../../../../utils/upload-files/upload-files.component";
import { ProjectFileModel } from "../../../../../models/projects/project.file.model";
import { ProjectsService } from "../../../../../services/admin-services/projects/projects.service";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { getExtensionIcon } from "../../../../../utils/file-extensions-allowed";
import { map } from "rxjs/operators";
import {
  UpdateDocumentDialogComponent
} from "../../../../../utils/update-document-dialog/update-document-dialog.component";
import { Group } from "../../../../../models/general/group-column.class";
import {
  ManageInternalContactDialogComponent
} from "../../../../../views/admin/internal-contacts/manage-internal-contact-dialog/manage-internal-contact-dialog.component";
import { ProjectResponse } from "../../../../../models/projects/project.response.model";
import {
  InternalPropertiesService
} from "../../../../../services/admin-services/properties/internal-properties.service";
import { CollaborationModel } from "../../../../../models/works/collaboration.model";
import { CollaborationDialogComponent } from "./collaboration-dialog/collaboration-dialog.component";
import { InspectionModel } from "../../../../../models/works/inspection.model";
import { forkJoin } from "rxjs";
import { InspectionDialogComponent } from "./inspection-dialog/inspection-dialog.component";
import { InspectionStatusModel } from "../../../../../models/works/inspection-status.model";

@Component({
  selector: "app-internal-details",
  templateUrl: "./internal-details.component.html",
})
export class InternalDetailsComponent
  implements OnInit, CanComponentDeactivate
{

  @ViewChild(MatTable) matTables : QueryList<MatTable<any>>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChildren(MatPaginator)
  set paginator(pagination: any) {
    this.dataSource.paginator = pagination.toArray()[1];
    this.dataSourceInspections.paginator = pagination.toArray()[0];
    this.dataSource.sort = this.sort;
  }
  groupByColumns: string[] = ['documentType'];
  displayedColumns: string[] = [
    "item",
    "documentName",
    "documentType",
    "createdOn",
    "createdBy",
    "modified",
    "modifiedBy",
    "status",
    "fileActions"
  ];

  displayedInspectionColumns: string[] = [
    "inspectionNumber",
    "inspectionDate",
    "inspectionBy",
    "createdBy",
    "modifiedBy",
    "status",
    "actions"
  ];

  dataSource = new MatTableDataSource<ProjectFileModel | Group>([]);
  dataSourceInspections = new MatTableDataSource<InspectionModel>([]);
  indexInternalWorks: number;
  internalWorks: string[] = [];
  taskResponse: TaskModel[];
  jobsForm: UntypedFormGroup;
  assigned_to: string;
  taskStatusSelect = [];
  taskTypeSelect = [];
  taskCategorySelect = [];
  assignedToSelect = [];
  jobStatusSelect: any = [];
  sectorSelect: any = [];
  contactSelect: any = [];
  countrySelect: any[] = [];
  territoriesSelect: any[] = [];
  internalProperties = [];
  internalProjects = [];
  projectByJob: ProjectResponse;
  propertiesResponse: any[] = [];
  client: string;
  isButtonDisabled = false;
  isUploadFile = false;
  isInsepctions = false;
  routerUrl: string;
  taskStatusList: any = [];
  taskCategoryList: any = [];
  taskTypeList: any = [];
  taskCodesList: any = [];
  contactRecords: InternalContactModel[];
  idClient: number;
  documentTypes: any[] = [];
  documents: ProjectFileModel[] = [];
  documentsUploaded: number;
  contactIds: number[] = [];
  collaborations: CollaborationModel[] = [];
  userType: string = sessionStorage.getItem("tipo");
  inspections: InspectionModel[] = [];
  inspectionStatus: InspectionStatusModel[] = [];

  constructor(
    private readonly _Activatedroute: ActivatedRoute,
    private readonly _InternalWorksServices: InternalWorksService,
    private readonly internalTasksService: InternalTasksService,
    private readonly internalContactService: InternalContactsService,
    private readonly _ProjectsService: ProjectsService,
    private readonly internalPropertiesService: InternalPropertiesService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: ToastrService,
    private readonly dialog: MatDialog,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly location: Location
  ) {}

  ngOnInit(): void {
    this.manageMasterLists();
    this.getUserProjects();
    this.getFilesUploaded();
    this.getParameteresURL();
    loadSideBarConfiguration("btnMenuInternalDetails");
    this.buildJobForm();
    this.getDocumentsType();
    this.getCollaborations();
    this.internalProperties = JSON.parse(
      sessionStorage.getItem("internalProperties")
    );
    this.router.events.subscribe((event) => {
      this.routerUrl = event["url"];
      if (this.routerUrl === "/") {
        this.routerUrl = "/home/jobs";
      }
    });
  }

  manageMasterLists() {

    this.spinner.show();
    const taskCategories$ = this.internalTasksService.getTaskCategory();
    const taskTypes$ = this.internalTasksService.getTaskType();
    const taskCodes$ = this.internalTasksService.getTaskCodes();
    const taskStatus$ = this.internalTasksService.getTaskStatus();
    const jobStatus$ = this._InternalWorksServices.getStatusJobs();
    const propertyType$ = this.internalPropertiesService.getPropertiesType();
    const internalContacts$ = this._InternalWorksServices.getInternalContacts();
    const countries$ = this._InternalWorksServices.getCountries();
    const territories$ = this._InternalWorksServices.getTerritories();
    const inspectionStatus$ = this._InternalWorksServices.getInspectionStatus();

    forkJoin(
      [
        taskCategories$,
        taskTypes$,
        taskCodes$,
        taskStatus$,
        jobStatus$,
        propertyType$,
        internalContacts$,
        countries$,
        territories$,
        inspectionStatus$])
      .subscribe((
        [
          taskCategories,
          taskTypes,
          taskCodes,
          taskStatus,
          jobStatus,
          propertyTypes,
          internalContacts,
          countries,
          territories,
          inspectionStatus
        ]) => {
      this.taskCategoryList = taskCategories;
      this.taskTypeList = taskTypes;
      this.taskCodesList = taskCodes;
      this.taskStatusList = taskStatus;
      this.jobStatusSelect = jobStatus;
      this.sectorSelect = propertyTypes;
      this.contactSelect = internalContacts;
      this.countrySelect = countries;
      this.territoriesSelect = territories;
      this.inspectionStatus = inspectionStatus;
      this.getInspections();
    }, () => {
        this.toastr.error("Error! Please try again", "Error");
        this.spinner.hide();
      });
  }

  getUserProjects() {
    this.spinner.show();
    this._ProjectsService.getProjects().subscribe(
      (resp: ProjectResponse[]) => {
        sessionStorage.setItem("internalProjects", JSON.stringify(resp));
        this.internalProjects = resp;
        this.getClient();
        this.spinner.hide();
      },
      () => {
        this.toastr.error(
          "Se presento un problema obteniendo los proyectos",
          "Proyectos",
          {
            progressBar: true,
            progressAnimation: "increasing",
          }
        );
        this.spinner.hide();
      }
    );
  }

  openInternalContacModal(): void {
    const dialogRef = this.dialog.open(ManageInternalContactDialogComponent, {
      autoFocus: false,
      disableClose: true,
      width: "1500px",
      height: "500pt",
      data: {
        action: "jobs",
        idCompany: this.internalWorks[this.indexInternalWorks]["iD_Account"]
      }
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.getCompanyxContacts(this.idClient);
      }
    });
  }

  applyInspectionFilter(event: Event) {
    const inspectionsFiltered = [];
    const filterValue = (event.target as HTMLInputElement).value;

    this.inspections.forEach((element) => {
      const result = searchJson(element, filterValue);
      if (Object.keys(result).length > 0) {
        inspectionsFiltered.push(result);
      }
    });

    this.dataSourceInspections.data = inspectionsFiltered;
  }

  applyFilter(event: Event) {
    const documentsFiltered = [];
    const filterValue = (event.target as HTMLInputElement).value;

    this.documents.forEach((element) => {
      const result = searchJson(element, filterValue);
      if (Object.keys(result).length > 0) {
        documentsFiltered.push(result);
      }
    });

    this.dataSourceConfiguration(documentsFiltered);
  }

  getFilesUploaded(): void {
    this._ProjectsService.currentFilesUploaded.subscribe((files) => {
      this.documentsUploaded = files;
    });
  }

  getIcon(document: ProjectFileModel): string {
    const extension = document.documentName.substring(document.documentName.lastIndexOf('.') + 1);
    return getExtensionIcon(extension);
  }

  getDocumentTypeName(id: number) {
    return this.documentTypes.find((x) => x.id === id)?.documentType;
  }

  getDocumentsType() {
    this._ProjectsService
      .getDocumentsTypeBlob()
      .pipe(
        map((response: any[]) => response.filter((type) => type.sourceTable === "Jobs")),
      )
      .subscribe((types: any) => {
        this.documentTypes = types
      });
  }

  manageDocument(document: ProjectFileModel, action): void {
    const dialogRef = this.dialog.open(UpdateDocumentDialogComponent, {
      autoFocus: false,
      disableClose: true,
      width: "700px",
      data: {
        documentTypes: this.documentTypes,
        document: document,
        action: action
      }
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        setTimeout(() => {
          this.getDocumentLocationByItem('Active');
        }, 2000)
      }
    });
  }

  setStatusDocument(document: ProjectFileModel, status: boolean): void {
    this.spinner.show();
    const data = {
      ...document,
      status: status
    }
    this._ProjectsService.setStatusDocument(data).subscribe({
      next: (response: any) => {
        const documentStatus = status ? 'Inactive' : 'Active';
        this.getDocumentLocationByItem(documentStatus);
        this.toastr.success("Document updated successfully", "Success");
      },
      error: () => {
        this.spinner.hide();
        this.toastr.error("Error! Please try again", "Error");
      }
    });
  }

  viewDocument(documentUrl: string): void {
    if (documentUrl.includes(".pdf")) {
      window.open(documentUrl, "_blank");
    } else {
      window.open(`https://view.officeapps.live.com/op/view.aspx?src=${documentUrl}`, "_blank");
    }
  }

  downloadDocument(documentName: string): void {
    this._ProjectsService.downloadDocument(documentName).subscribe({
      next: (response: any) => {
        const linkSource = `data:image/jpg;base64,${response}`;
        const downloadLink = document.createElement("a");
        const fileName = documentName;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      },
      error: () => {
        this.toastr.error("Error! Please try again", "Error");
      }
    });
  }

  groupByDocumentType(status: boolean): void {
    this.groupByColumns = status ? ['documentType'] : [];
    this.dataSource.data = this.addGroups(this.documents, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  openUploadFileProject(): void {
    const dialogRef = this.dialog.open(UploadFilesComponent, {
      autoFocus: false,
      disableClose: true,
      width: "1500px",
      data: {
        documentTypes: this.documentTypes,
        id: this.internalWorks[this.indexInternalWorks]["id"],
        regarding: 'Jobs',
        idFolder: this.internalWorks[this.indexInternalWorks]["job_Number"],
        documents: this.documents
      }
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        setTimeout(() => {
          this.getDocumentLocationByItem('Active');
        }, 2000)
      }
    });
  }

  tabChanged(event) {
    if (event.index > 0) {
      this.isUploadFile = event.index === 4;
      this.isInsepctions = event.index === 1;
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
      this.isUploadFile = false;
      this.isInsepctions = false;
    }
  }

  getTaskStatusName(id: number) {
    return this.taskStatusList.find((status) => status.id === id)?.name_Status;
  }

  getTaskTypeName(id: number) {
    return this.taskTypeList.find((type) => type.id === id)?.name_Option;
  }

  getTaskCategoryName(id: number) {
    return this.taskCategoryList.find((category) => category.id === id)
      ?.name_Category;
  }

  validateForm(): Promise<boolean> {
    if (this.jobsForm.dirty) {
      sessionStorage.setItem("jobDirty", "true");
      const dialogRef = this.dialog.open(ConfirmDialogNewVersionComponent, {
        autoFocus: false,
        disableClose: true,
        data: {
          title: "Are you sure to quit without saving?",
          textSaveButton: "Save changes",
          textCloseButton: "Exit without saving",
        },
      });
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          sessionStorage.setItem("jobDirty", "false");
          this.saveFormJob();
          this.jobsForm.reset();
          this.location.back();
        } else {
          sessionStorage.setItem("jobDirty", "false");
          this.jobsForm.reset();
          this.location.back();
        }
      });
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  }

  openConfirmUpdateJobDialog(
    textSaveButton: string,
    textCloseButton: string,
    title: string,
    action: string
  ): void {
    const dialogRef = this.dialog.open(ConfirmDialogNewVersionComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        title,
        textSaveButton,
        textCloseButton,
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        if (action === "close" || action === "cancel") {
          this.saveFormJob();
          this.jobsForm.reset();
          this.router.navigate(["/home/jobs"]);
        } else {
          this.jobsForm.markAsPristine();
          this.saveFormJob();
        }
      } else {
        if (action === "cancel") {
          this.jobsForm.reset();
          this.router.navigate(["/home/jobs"]);
        }
      }
    });
  }

  getClient() {
    this.projectByJob = this.internalProjects.find(
      (project) =>
        this.internalWorks[this.indexInternalWorks]["project_Number"] ===
        project.project_Number
    );
    this.client = this.internalProjects?.find(
      (project) =>
        this.internalWorks[this.indexInternalWorks]["project_Number"] ===
        project.project_Number
    )?.client;
    this.idClient = this.internalProjects.find(
      (project) =>
        project.project_Number ===
        this.internalWorks[this.indexInternalWorks]["project_Number"]
    ).id_Client;
    this.getCompanyxContacts(this.idClient);
  }

  getUniqueValuesOnchangeSelect() {
    this.taskTypeSelect = this.taskResponse
      .map((property) => property.task_Type)
      .filter((value, index, self) => self.indexOf(value) === index);

    this.assignedToSelect = this.taskResponse
      .map((account) => account.assigned_to)
      .filter((value, index, self) => self.indexOf(value) === index);

    this.taskStatusSelect = this.taskResponse
      .map((task) => task.task_Status)
      .filter((value, index, self) => self.indexOf(value) === index);

    this.taskCategorySelect = this.taskResponse
      .map((category) => category.task_Category)
      .filter((value, index, self) => self.indexOf(value) === index);
  }

  getParameteresURL() {
    this._Activatedroute.paramMap.subscribe((params) => {
      this.getWorks(params.get("id"));
      this.getTasks();
    });
  }

  buildJobForm() {
    this.jobsForm = this.formBuilder.group({
      id: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["id"] : null],
      job_Number: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["job_Number"] : null],
      project_Number: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["project_Number"] : null],
      property_Number: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["property_Number"] : null],
      job_type: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["job_type"] : null],
      country: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["country"] : null],
      business_Unit: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["business_Unit"] : null],
      sector: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["sector"] : null],
      job_Manager: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["job_Manager"] : null],
      iD_Job_Manager: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["iD_Job_Manager"] : null],
      job_Comments: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["job_Comments"] : null],
      external_Fees: [Number(this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["external_Fees"] : null)],
      internal_fees: [Number(this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["internal_fees"] : null)],
      due_Date: [new Date(this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["due_Date"] : null)],
      created: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["created"] : null],
      address: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["address"] : null],
      currency: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["currency"] : null],
      equivalent_USD: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["equivalent_USD"] : null],
      account: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["account"] : null],
      account_Officer: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["account_Officer"] : null],
      phone_Account_Officer: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["phone_Account_Officer"] : null],
      email_Account_Officer: [ this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["email_Account_Officer"] : null],
      deliverable: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["deliverable"] : null],
      property_Name: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["property_Name"] : null],
      latitude: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["latitude"] : null],
      longitude: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["longitude"] : null],
      iD_Collaborating_Territory: [null],
      iD_Collaborator: [null],
      iD_Job_Status: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["iD_Job_Status"] : null],
      percentage_of_Collaboration: [null],
      Id_property_Contact: [this.internalWorks[this.indexInternalWorks]["id_Property_Contact"]],
      Id_Inspection_Contact: [this.internalWorks[this.indexInternalWorks]["id_Inspection_Contact"]],
      Valuation_Scenarios: [this.internalWorks[this.indexInternalWorks]["valuation_Scenarios"]],
      Interest_Valued: [this.internalWorks[this.indexInternalWorks]["interest_Valued"]],
      Type_of_Value: [this.internalWorks[this.indexInternalWorks]["type_of_Value"]],
      Inspection_Required: [this.internalWorks[this.indexInternalWorks]["inspection_Required"]],
      Days_to_Deliver: [this.internalWorks[this.indexInternalWorks]["days_to_Deliver"]],
      id_Property_Type: [this.internalWorks[this.indexInternalWorks] ? this.internalWorks[this.indexInternalWorks]["id_Property_Type"] : null, Validators.required],
    });
    this.jobsForm.markAllAsTouched();
  }

  getWorks(jobNumber: string) {
    this.internalWorks = JSON.parse(sessionStorage.getItem("internalJobs"));
    const indexJob = this.internalWorks.findIndex(
      (job) => job["job_Number"] === jobNumber
    );
    this.indexInternalWorks = Number(indexJob);
    this.getDocumentLocationByItem('Active');
  }

  getTasks() {
    this.spinner.show();
    this._InternalWorksServices
      .getTasks(
        this.internalWorks[this.indexInternalWorks]
          ? this.internalWorks[this.indexInternalWorks]["job_Number"]
          : null
      )
      .subscribe(
        (response: any) => {
          this.taskResponse = response;
          sessionStorage.setItem("tasksByJobNumber", JSON.stringify(response));
          this.getUniqueValuesOnchangeSelect();
          this.spinner.hide();
        },
        () => {
          this.toastr.error(
            "Se ha presentado un error consultando las tareas",
            "Tasks",
            {
              progressBar: true,
              progressAnimation: "increasing",
            }
          );
          this.spinner.hide();
        }
      );
  }

  logout() {
    this.authService.logout();
  }

  get fullName() {
    return sessionStorage.getItem("nombreCompleto");
  }

  saveFormJob() {
    this.spinner.show();
    this.jobsForm.get("iD_Job_Manager").setValue(this.getInternalContactId(this.jobsForm.get("job_Manager").value));
    this._InternalWorksServices.updateJobs(this.jobsForm.value).subscribe(
      () => {
        this.toastr.success("Se ha editado exitosamente el job", "Jobs", {
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.getJobsRol();
        this.spinner.hide();
      },
      () => {
        this.toastr.error(
          "Se ha presentado un error al editar el job",
          "Jobs",
          {
            progressBar: true,
            progressAnimation: "increasing",
          }
        );
        this.spinner.hide();
      }
    );
  }

  getJobsRol() {
    this._InternalWorksServices.getJobsRol().subscribe(
      (resp: InternalWorksResponse[]) => {
        sessionStorage.setItem("internalJobs", JSON.stringify(resp));
      },
      () => {
        this.toastr.error(
          "Se han presentando inconvenientes al obtener los datos de los jobs",
          "Obteniendo datos",
          {
            progressBar: true,
            progressAnimation: "increasing",
          }
        );
      }
    );
  }

  openEditTaskDialog(task?: TaskModel, action?: string) {
    const dialogRef = this.dialog.open(EditTaskDialogComponent, {
      autoFocus: false,
      disableClose: false,
      data: {
        task,
        taskStatus: this.taskStatusList,
        taskCategory: this.taskCategoryList,
        taskType: this.taskTypeList,
        taskCodes: this.taskCodesList,
        action: action,
        jobNumber: this.internalWorks[this.indexInternalWorks]["job_Number"],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getJobsRol();
      }
    });
  }

  openInspectionDialog(inspection?: InspectionModel, action?: string) {
    const dialogRef = this.dialog.open(InspectionDialogComponent, {
      hasBackdrop: true,
      autoFocus: false,
      disableClose: false,
      data: {
        inspection,
        internalContacts: this.contactSelect,
        jobId: this.internalWorks[this.indexInternalWorks]["id"],
        action: action,
        inspectionConsecutive: this.inspections.length + 1,
        inspectionStatus: this.inspectionStatus
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getInspections();
    });
  }

  openCollaborationDialog(collaboration?: CollaborationModel, action?: string) {
    const dialogRef = this.dialog.open(CollaborationDialogComponent, {
      hasBackdrop: true,
      autoFocus: false,
      disableClose: false,
      data: {
        collaboration,
        territories: this.territoriesSelect,
        collaborators: this.contactSelect,
        jobId: this.internalWorks[this.indexInternalWorks]["id"],
        action: action
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getCollaborations();
    });
  }

  getIndexProperty(task: TaskModel, propertyNumber?: string) {
    const propertyNumberToSeach = task ? task.property_Number : propertyNumber;
    return this.internalProperties.findIndex(
      (x) => x.property_Number === propertyNumberToSeach
    );
  }

  getPropertyName(task: TaskModel) {
    return this.internalProperties?.find(
      (x) => x.property_Number === task.property_Number
    )?.identifier;
  }

  getTerritoryName(id: number) {
    return this.territoriesSelect.find((x) => x.id === id)?.name_Territory;
  }

  getInternalContactName(id: number) {
    return this.contactSelect.find((x) => x.id === id)?.full_Name;
  }

  getInspectionStatusName(id: number) {
    return this.inspectionStatus.find((status) => status.id === id)?.nameStatus;
  }

  getInspectorsName(inspectionId: number): string {
    return this.inspections?.filter(inspection => inspection.idInspection === inspectionId)
      .map(inspection => inspection.inspectedBy)
      .map(inspector => inspector.map(inspector => this.getInternalContactName(inspector)))
      .join(', ') || '';
  }

  validateCollaborator(collaborator: number) {
    return collaborator?.toString() === sessionStorage.getItem("userID");
  }

  getInspectionsByStatus(status: string) {
    if ( status === 'All') {
      return this.dataSourceInspections.data = this.inspections;
    } else {
      return this.dataSourceInspections.data = this.inspections.filter(inspection => inspection.status === status);
    }
  }

  getDocumentLocationByItem(status: string): void {
    this.spinner.show();
    this._ProjectsService.getDocumentLocationByItem(this.internalWorks[this.indexInternalWorks]["id"], status).subscribe(
      {
        next: (response: ProjectFileModel[]) => {
          this.documents = response;
          this.dataSourceConfiguration(response);
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error("Error! Please try again", "Error");
        }
      }
    );
  }

  private getInternalContactId(fullName: string) {
    return this.contactSelect?.find((x) => x.full_Name === fullName)?.id;
  }

  private getCompanyxContacts(idClient: number) {
    this.spinner.show();
    this.internalContactService.getCompanyxContacts(idClient).subscribe(
      (contacts: AccountRelationalontactModel[]) => {
        this.contactIds = contacts.map(
          (contact) => contact.idContactInformationRecord
        );
        this.getRecordsIds(this.contactIds);
      },
      () => {
        this.spinner.hide();
        this.toastr.error(
          `Error! Does not exist an ID Client in ${
            this.internalWorks[this.indexInternalWorks]["project_Number"]
          }`,
          "Error"
        );
      }
    );
  }

  private getRecordsIds(contacts: number[]) {
    this.internalContactService.getRecordsIds(contacts).subscribe(
      (contactRecords: InternalContactModel[]) => {
        this.spinner.hide();
        this.contactRecords = contactRecords;
      },
      () => {
        this.spinner.hide();
        this.toastr.error("Error! Please try again", "Error");
      }
    );
  }

  private dataSourceConfiguration(response: ProjectFileModel[]) {
    this.dataSource.data = this.addGroups(response, this.groupByColumns);
    this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
    this.dataSource.sort = this.sort;
  }

  private addGroups(data: any[], groupByColumns: string[]): any[] {
    var rootGroup = new Group();
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  private getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    // Recursive function, stop when there are no more levels.
    if (level >= groupByColumns.length)
      return data;

    var groups = this.uniqueBy(
      data.map(
        row => {
          var result = new Group();
          result.level = level + 1;
          result.parent = parent;
          for (var i = 0; i <= level; i++)
            result[groupByColumns[i]] = row[groupByColumns[i]];
          return result;
        }
      ),
      JSON.stringify);

    const currentColumn = groupByColumns[level];

    var subGroups = [];
    groups.forEach(group => {
      let rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn])
      let subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    })
    return subGroups;
  }

  private uniqueBy(a, key) {
    var seen = {};
    return a.filter(function (item) {
      var k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    })
  }

  private customFilterPredicate(data: ProjectFileModel | Group): boolean {
    return (data instanceof Group) ? data.visible : this.getDataRowVisible(data);
  }

  private getDataRowVisible(data: ProjectFileModel): boolean {
    const groupRows = this.dataSource.data.filter(
      row => {
        if (!(row instanceof Group)) return false;

        let match = true;
        this.groupByColumns.forEach(
          column => {
            if (!row[column] || !data[column] || row[column] !== data[column]) match = false;
          }
        );
        return match;
      }
    );

    if (groupRows.length === 0) return true;
    if (groupRows.length > 1) throw "Data row is in more than one group!";
    const parent = <Group>groupRows[0];

    return parent.visible && parent.expanded;
  }

  groupHeaderClick(row) {
    row.expanded = !row.expanded
    this.dataSource.filter = performance.now().toString();
  }

  isGroup(index, item): boolean {
    return item.level;
  }

  private getCollaborations() {
    this.spinner.show();
    this._InternalWorksServices.getCollaborations(this.internalWorks[this.indexInternalWorks]["id"])
      .pipe(
        map((collaboration: CollaborationModel[]) => {
          collaboration.forEach((collaboration: CollaborationModel) => {
            collaboration.terrirotyName = this.getTerritoryName(collaboration.territoryCollaborating);
            collaboration.collaboratorName = this.getInternalContactName(collaboration.collaborator);
            return collaboration;
          });
          return collaboration;
        })
      )
      .subscribe(
      (collaborations: CollaborationModel[]) => {
        this.collaborations = collaborations;
        this.spinner.hide();
      }
    );
  }

  private getInspections() {
    this.spinner.show();
    this._InternalWorksServices.getInspectionsByJob(this.internalWorks[this.indexInternalWorks]["id"])
      .subscribe(
      (inspections: InspectionModel[]) => {
        this.inspections = inspections;
        this.dataSourceInspections.data = inspections;
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
        this.toastr.error("Error! Please try again", "Error");
      }
    );
  }

}
