import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { CompanynameComponent } from "./views/admin/companyname/companyname.component";
import { ContactsComponent } from "./views/admin/contacts/contacts.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";
import { LandingComponent } from "./views/landing/landing.component";
import { AuthGuard } from "./config/guard/auth.guard";
import { PreventChangeRouteGuard } from "./config/prevent-change-route/prevent-change-route.guard";
import { ProjectsComponent } from "./views/admin/projects/projects.component";
import { PropertiesComponent } from "./views/admin/properties/properties.component";
import { WorksComponent } from "./views/admin/works/works.component";
import { TasksComponent } from "./views/admin/tasks/tasks.component";
import { AdvancesComponent } from "./views/admin/advances/advances.component";
import { ProjectsDetailsComponent } from "./components/tables/projects/details/projects-details.component";
import { PropertiesDetailsComponent } from "./components/tables/properties/details/properties-details.component";
import { WorksDetailsComponent } from "./components/tables/works/details/works-details.component";
import { RecoveryPasswordComponent } from "./views/auth/recovery-password/recovery-password.component";
import { InternalDetailsComponent } from "./components/tables/works/internal/internal-details/internal-details.component";
import { InternalDetailsProjectsComponent } from "./components/tables/projects/internal/internal-details-projects/internal-details-projects.component";
import { InternalDetailsPropertiesComponent } from "./components/tables/properties/internal/internal-details-properties/internal-details-properties.component";
import { AdvancedSettingsComponent } from "./views/admin/advanced-settings/advanced-settings.component";
import { UsersComponent } from "./views/admin/advanced-settings/security/users/users.component";
import { SecurityRolesComponent } from "./views/admin/advanced-settings/security/security-roles/security-roles.component";
import { FeaturesComponent } from "./views/admin/advanced-settings/security/features/features.component";
import { RuleJobsComponent } from "./views/admin/advanced-settings/production-settings/rule-jobs/rule-jobs.component";
import { InternalContactsComponent } from "./views/admin/internal-contacts/internal-contacts.component";
import { AccountsComponent } from "./views/admin/accounts/accounts.component";
import { ManageAccountDialogComponent } from "./views/admin/accounts/manage-account-dialog/manage-account-dialog.component";
import { ManageInternalContactDialogComponent } from "./views/admin/internal-contacts/manage-internal-contact-dialog/manage-internal-contact-dialog.component";
import { EmailComponent } from "./views/auth/recovery-password/steps/email/email.component";
import { CodeComponent } from "./views/auth/recovery-password/steps/code/code.component";
import { NewPasswordComponent } from "./views/auth/recovery-password/steps/new-password/new-password.component";
import {
  CodeConfirmationComponent
} from "./views/auth/recovery-password/steps/code-confirmation/code-confirmation.component";
import { TokenExpiredComponent } from "./views/auth/recovery-password/steps/token-expired/token-expired.component";

const routes: Routes = [
  // admin views
  {
    path: "home",
    component: AdminComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: { disable: true, label: "Logan" },
    },
    children: [
      {
        path: "projects",
        component: ProjectsComponent,
        data: {
          breadcrumb: "Projects",
        },
        children: [
          {
            path: "projects-details/:projectNumber",
            component: ProjectsDetailsComponent,
            data: {
              breadcrumb: (resolvedId: string) => `${resolvedId}`,
            },
          },
          {
            path: "projects-internal-details/:projectNumber",
            component: InternalDetailsProjectsComponent,
            canDeactivate: [PreventChangeRouteGuard],
            data: {
              breadcrumb: (resolvedId: string) => {
                let id = resolvedId.split("/")[1];
                let project = JSON.parse(
                  sessionStorage.getItem("internalProjects")
                ).find((project) => project.project_Number === id);
                return `${project.project_Number} - ${project.projectName}`;
              },
            },
          },
          {
            path: "properties-internal-details/:id",
            component: InternalDetailsPropertiesComponent,
            canDeactivate: [PreventChangeRouteGuard],
            data: {
              breadcrumb: (resolvedId: string) => {
                let id = resolvedId.split("/")[1];
                let propertyNumber = JSON.parse(
                  sessionStorage.getItem("internalProperties")
                )[id].property_Number;
                return `${propertyNumber}`;
              },
            },
          },
        ],
      },
      {
        path: "properties",
        component: PropertiesComponent,
        data: {
          breadcrumb: "Properties",
        },
        children: [
          {
            path: "properties-details/:id",
            component: PropertiesDetailsComponent,
            data: {
              breadcrumb: (resolvedId: string) => `${resolvedId}`,
            },
          },
        ],
      },
      {
        path: "properties-internal-details/:id",
        component: InternalDetailsPropertiesComponent,
        data: {
          breadcrumb: (resolvedId: string) => {
            let id = resolvedId.split("/")[1];
            let propertyNumber = JSON.parse(
              sessionStorage.getItem("internalProperties")
            )[id].property_Number;
            return `${propertyNumber}`;
          },
        },
      },
      {
        path: "jobs",
        component: WorksComponent,
        data: {
          breadcrumb: "Jobs",
        },
        children: [
          {
            path: "jobs-details/:id",
            component: WorksDetailsComponent,
            data: {
              breadcrumb: (resolvedId: string) => `${resolvedId}`,
            },
          },
          {
            path: "jobs-internal-details/:id",
            component: InternalDetailsComponent,
            canDeactivate: [PreventChangeRouteGuard],
            data: {
              breadcrumb: (resolvedId: string) => {
                let id = resolvedId.split("/")[1];
                const jobNumber = JSON.parse(
                  sessionStorage.getItem("internalJobs")
                )?.find((job) => job.job_Number === id);
                const propertyName = JSON.parse(
                  sessionStorage.getItem("internalProperties")
                )?.find(
                  (property) =>
                    property.project_Number === jobNumber.project_Number
                )?.property_Name;

                return `${jobNumber.job_Number} - ${propertyName}`;
              },
            },
          },
        ],
      },
      {
        path: "tasks",
        component: TasksComponent,
        data: {
          breadcrumb: "Tasks",
        },
      },
      {
        path: "advances",
        component: AdvancesComponent,
        data: {
          breadcrumb: "Advances",
        },
      },
      {
        path: "company-name",
        component: CompanynameComponent,
        canDeactivate: [PreventChangeRouteGuard],
        data: {
          breadcrumb: "Company-name",
        },
      },
      {
        path: "contacts",
        component: ContactsComponent,
        canDeactivate: [PreventChangeRouteGuard],
        data: {
          breadcrumb: "Contacts",
        },
      },
      {
        path: "advanced-settings",
        component: AdvancedSettingsComponent,
        data: {
          breadcrumb: "Advanced Settings",
        },
        children: [
          {
            path: "user",
            component: UsersComponent,
            data: {
              breadcrumb: "User",
            },
          },
          {
            path: "security-roles",
            component: SecurityRolesComponent,
            data: {
              breadcrumb: "Security Roles",
            },
          },
          {
            path: "features",
            component: FeaturesComponent,
            data: {
              breadcrumb: "Features",
            },
          },
          {
            path: "rule-jobs",
            component: RuleJobsComponent,
            data: {
              breadcrumb: "Job Channeling",
            },
          },
        ],
      },
      {
        path: "internal-contacts",
        component: InternalContactsComponent,
        data: {
          breadcrumb: "Contacts",
        },
        children: [
          {
            path: "manage-internal-contact",
            component: ManageInternalContactDialogComponent,
            data: {
              breadcrumb: "New Contact",
            },
          },
          {
            path: "manage-internal-contact/:idInternalContact",
            component: ManageInternalContactDialogComponent,
            data: {
              breadcrumb: "Edit Contact",
            },
          },
        ],
      },
      {
        path: "accounts",
        component: AccountsComponent,
        data: {
          breadcrumb: "Accounts",
        },
        children: [
          {
            path: "manage-account",
            component: ManageAccountDialogComponent,
            data: {
              breadcrumb: "New Account",
            },
          },
          {
            path: "manage-account/:idAccount",
            component: ManageAccountDialogComponent,
            data: {
              breadcrumb: "Edit Account",
            },
          },
        ],
      },
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
    ],
  },
  // auth views
  {
    path: "auth",
    component: AuthComponent,
    children: [
      { path: "login", component: LoginComponent },
      { path: "register", component: RegisterComponent },
      { path: "forgot-password", component: RecoveryPasswordComponent },
      { path: "validate-email", component: EmailComponent },
      { path: "send-email", component: CodeComponent },
      { path: "code-confirmation", component: CodeConfirmationComponent },
      { path: "token-expired", component: TokenExpiredComponent },
      { path: "new-password", component: NewPasswordComponent },
      { path: "", redirectTo: "login", pathMatch: "full" },
      { path: "**", redirectTo: "login", pathMatch: "full" },
    ],
  },
  { path: "landing", component: LandingComponent },
  { path: "", redirectTo: "landing", pathMatch: "full" },
  { path: "**", redirectTo: "landing", pathMatch: "full" },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [PreventChangeRouteGuard],
})
export class AppRoutingModule {}
