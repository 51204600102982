import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AdvancesResponse } from "src/app/models/properties/advances.response";
import { RelationDocumentsResponse } from "src/app/models/properties/relation.documents.response.model";
import { PropertiesService } from "src/app/services/admin-services/properties/properties.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-properties-details",
  templateUrl: "./properties-details.component.html",
})
export class PropertiesDetailsComponent implements OnInit {
  openTab: number = 1;
  indexProperties: number;
  properties: string[] = [];
  relationDocuments: RelationDocumentsResponse[] = [];
  advances: AdvancesResponse[] = [];
  reloadData = false;
  documentFileTrustUrl = null;
  documentFileResourceUrl = null;

  constructor(
    private readonly _Activatedroute: ActivatedRoute,
    private readonly _propertiesService: PropertiesService,
    private readonly spinner: NgxSpinnerService,
    private readonly sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.getParameteresURL();
  }

  reloadDataTable(reload: boolean) {
    if (reload) {
      this.getParameteresURL();
    }
  }

  getParameteresURL() {
    this._Activatedroute.paramMap.subscribe((params) => {
      this.indexProperties = Number(params.get("id"));
      this.getProperties();
      this.getRelationDocuments();
      this.getAdvances();
    });
  }

  getProperties() {
    this.properties = JSON.parse(sessionStorage.getItem("properties"));
  }

  toggleTabs($tabNumber: number) {
    this.openTab = $tabNumber;
  }

  getRelationDocuments() {
    this.spinner.show();
    this._propertiesService
      .getRelationDocuments(
        this.properties[this.indexProperties]["project_Number"],
        this.properties[this.indexProperties]["property_Number"]
      )
      .subscribe((response: RelationDocumentsResponse[]) => {
        this.relationDocuments = response;
        this.spinner.hide();
      });
  }

  getAdvances() {
    this.spinner.show();
    this._propertiesService
      .getAdvances(
        this.properties[this.indexProperties]["project_Number"],
        this.properties[this.indexProperties]["property_Number"]
      )
      .subscribe((response: AdvancesResponse[]) => {
        this.advances = response;
        this.spinner.hide();
      });
  }

  deleteDocument(document: any) {
    let bodyDelete = {
      NombreDocumento: "",
      DocumentoCarga: "",
      Proyecto: document.project_Number,
      Propiedad: document.property,
      IdDocumentoProyecto: document.id_RelacionDocumentoSH,
      UrlDocumentoRelacionado: document.document_url,
    };
    Swal.fire({
      title: "¿Estás seguro que deseas eliminar el documento?",
      text: "Al eliminar el documento pierde la relación",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Si, deseo eliminarlo",
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this._propertiesService.deleteRelationDocuments(bodyDelete).subscribe(
          (response) => {
            let bodyUpdate = {
              ID: document.id,
              Document_Name: document.document_Name,
              Document_Category: document.document_Category,
              Project_Number: document.project_Number,
              Property: document.property,
              Document_Upload: false,
              Document_url: "",
              Created: document.created,
              Id_RelacionDocumentoSH: 0
            };
            this._propertiesService.updateRelationDocument(bodyUpdate).subscribe(resp => {
              if(resp) {
                this.getRelationDocuments();
                this.spinner.hide();
              } else {
                Swal.fire({
                  title: "Error!",
                  text: "Se presentó un inconveniente al actualizar el documento",
                  icon: "error",
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "OK",
                });
                this.spinner.hide();
              }
            });
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
          }
        );
      } else {
        return true;
      }
    });
  }

  viewDocument(document: any) {
    this._propertiesService
      .viewDocument(document.document_url)
      .subscribe(
        (response) => {
          const byteArray = new Uint8Array(
            atob(response)
              .split("")
              .map((char) => char.charCodeAt(0))
          );
          let extension = document.document_url.substr(document.document_url.lastIndexOf('.') + 1);
          let mimeType = this.getMimeType(extension);
          const blob = new Blob([byteArray], { type: mimeType });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
          this.documentFileTrustUrl =
            this.sanitizer.bypassSecurityTrustUrl(url);
          this.documentFileResourceUrl =
            this.sanitizer.bypassSecurityTrustResourceUrl(
              this.documentFileTrustUrl
            );
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  getMimeType(fileExtension: string) {
    switch (fileExtension) {
      case "doc":
        return "application/msword";
      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case "xls":
        return "application/vnd.ms-excel";
      case "xlsx":
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      case "ppt":
        return "application/vnd.ms-powerpoint";
      case "pptx":
        return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      case "jpg":
        return "image/jpeg";
      case "png":
        return "image/png";
      default:
        return "application/pdf";
    }
  }
}
