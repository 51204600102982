import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { InternalWorksService } from "../../../../../../services/admin-services/works/internal-works.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import {
  ConfirmationCollaborationDialogComponent
} from "./confirmation-collaboration-dialog/confirmation-collaboration-dialog.component";

@Component({
  selector: 'app-collaboration-dialog',
  templateUrl: './collaboration-dialog.component.html'
})
export class CollaborationDialogComponent implements OnInit {

  collaborationForm: UntypedFormGroup;
  hasBeenSaved = false;

  constructor(
    private readonly dialogRef: MatDialogRef<CollaborationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialog: MatDialog,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly internalWorksService: InternalWorksService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.buildcollaborationForm();
  }

  confirmationManageCollaboration() {
    if (this.collaborationForm.dirty) {
      const dialogRef = this.dialog.open(ConfirmationCollaborationDialogComponent, {
        hasBackdrop: true,
        autoFocus: false,
        disableClose: false,
        data: {
          collaborationForm: this.collaborationForm
        },
      });
      dialogRef.afterClosed().subscribe((action: string) => {
        if ( action === 'discardChanges') {
          this.dialogRef.close();
        } else if ( action === 'saveChanges') {
          this.manageCollaboration('saveAndClose');
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  manageCollaboration(action: string): void {
    if (this.data.action === 'edit') {
      this.updateCollaboration(action);
    } else {
      this.createCollaboration(action);
    }
  }

  private createCollaboration(action: string): void {
    if ( this.hasBeenSaved ) {
      this.keepOrCloseDialog(action);
    } else {
      this.spinner.show();
      this.collaborationForm.get('id').setValue(0);
      this.internalWorksService.createCollaboration(this.collaborationForm.value).subscribe(
        () => {
          this.toastr.success('Collaboration created successfully.');
          this.spinner.hide();
          this.keepOrCloseDialog(action);
        },
        () => {
          this.toastr.error('Error, please try again later.');
          this.spinner.hide();
        }
      );
    }
  }

  private updateCollaboration(action: string): void {
    this.spinner.show();
    this.internalWorksService.updateCollaboration(this.collaborationForm.value).subscribe(
      () => {
        this.toastr.success('Collaboration updated successfully.');
        this.spinner.hide();
        this.keepOrCloseDialog(action);
      },
      () => {
        this.toastr.error('Error, please try again later.');
        this.spinner.hide();
      }
    );
  }

  private keepOrCloseDialog(action: string): void {
    if ( action === 'saveAndClose') {
      this.dialogRef.close();
    } else {
      this.hasBeenSaved = true;
    }
  }

  private buildcollaborationForm(): void {
     this.collaborationForm = this.formBuilder.group({
       id: [this.data?.collaboration?.id],
       territoryCollaborating: [this.data?.collaboration?.territoryCollaborating, Validators.required],
       collaborator: [this.data?.collaboration?.collaborator, Validators.required],
       percentageOfCollaboration: [this.data?.collaboration?.percentageOfCollaboration, Validators.required],
       statusCode: [this.data?.collaboration?.statusCode, Validators.required],
       createdOn: [this.data?.collaboration ? this.data?.collaboration?.createdOn : new Date()],
       modifiedOn: [new Date()],
       createdBy: [this.data?.collaboration ? this.data?.collaboration?.createdBy : sessionStorage.getItem('userID')],
       modifiedBy: [sessionStorage.getItem('userID')],
       idJob: [this.data?.collaboration ? this.data?.collaboration?.idJob : this.data?.jobId],
    });
  }

}
