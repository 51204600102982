import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AdvancedSettingsCountryModel } from "src/app/models/advanced-settings/country.model";
import { AdvancedSettingsRuleJobModel } from "src/app/models/advanced-settings/rule-job.model";
import { MasterInternalContactModel } from "src/app/models/contacts/internal-contact.model";
import { MasterBusinessUnitModel } from "src/app/models/master/business-unit.model";
import { MasterSectorModel } from "src/app/models/master/sector.model";
import { AdvancedSettingsService } from "src/app/services/admin-services/advanced-settings/advanced-settings.service";
import { InternalWorksService } from "src/app/services/admin-services/works/internal-works.service";
import { MasterService } from "src/app/services/master/master.service";
import { ManageRuleJobsComponent } from "./manage-rule-jobs/manage-rule-jobs.component";
import {
  InternalPropertiesService
} from "../../../../../services/admin-services/properties/internal-properties.service";

@Component({
  selector: "app-rule-jobs",
  templateUrl: "./rule-jobs.component.html",
})
export class RuleJobsComponent implements OnInit {
  countries: AdvancedSettingsCountryModel[];
  internalContacts: MasterInternalContactModel[];
  sectors: any[];
  businessUnits: MasterBusinessUnitModel[];
  ruleJobs: AdvancedSettingsRuleJobModel[];

  constructor(
    private readonly dialog: MatDialog,
    private readonly _advancedSettingsService: AdvancedSettingsService,
    private readonly masterServices: MasterService,
    private readonly internalJobsService: InternalWorksService,
    private readonly internalPropertiesService: InternalPropertiesService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getCountries();
    this.getPropertiesType();
    this.getbusinessUnits();
    this.getInternalContacts();
    this.getRulesJobs();
  }

  openManageRuleJobsDialog(action?: string, ruleJob?: any) {
    const dialogRef = this.dialog.open(ManageRuleJobsComponent, {
      width: "850pt",
      autoFocus: false,
      disableClose: false,
      data: {
        ruleJob,
        action,
        countries: this.countries.sort((a, b) => a.countryName.localeCompare(b.countryName)),
        internalContacts: this.internalContacts,
        sectors: this.sectors,
        businessUnits: this.businessUnits,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getRulesJobs();
      }
    });
  }

  private getInternalContacts() {
    this.spinner.show();
    this.internalJobsService.getInternalContacts().subscribe(
      (contacts) => {
        this.internalContacts = contacts;
        this.spinner.hide();
      },
      () => {
        this.toastr.error("Error! Please try again", "Error", {
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.spinner.hide();
      }
    );
  }

  private getCountries() {
    this.spinner.show();
    this._advancedSettingsService.getCountries().subscribe(
      (countries: AdvancedSettingsCountryModel[]) => {
        this.countries = countries;
        this.spinner.hide();
      },
      () => {
        this.toastr.error("Error! Please try again", "Error", {
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.spinner.hide();
      }
    );
  }

  private getPropertiesType() {
    this.spinner.show();
    this.internalPropertiesService.getPropertiesType().subscribe(
      (sectors: any[]) => {
        this.spinner.hide();
        this.sectors = sectors;
      },
      () => {
        this.toastr.error("Error! Please try again", "Error", {
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.spinner.hide();
      }
    );
  }

  private getbusinessUnits() {
    this.spinner.show();
    this.masterServices.getBusinessUnits().subscribe(
      (businessUnits: MasterBusinessUnitModel[]) => {
        this.spinner.hide();
        this.businessUnits = businessUnits;
      },
      () => {
        this.toastr.error("Error! Please try again", "Error", {
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.spinner.hide();
      }
    );
  }

  private getRulesJobs() {
    this.spinner.show();
    this._advancedSettingsService.getRulesJobs().subscribe(
      (rulesJobs: AdvancedSettingsRuleJobModel[]) => {
        this.spinner.hide();
        this.ruleJobs = rulesJobs;
      },
      () => {
        this.toastr.error("Error! Please try again", "Error", {
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.spinner.hide();
      }
    );
  }
}
