import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CompanyName } from 'src/app/models/company-names/company-name.model';
import { CompanyNameResponse } from 'src/app/models/company-names/company-name.response.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyNameService {

  protected companyNames = new BehaviorSubject<CompanyNameResponse[]>(null);
  currentCompanyNames = this.companyNames.asObservable();

  private headers = { headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
  })};

  constructor(
    public readonly http: HttpClient
  ) { }

  getCompanyNames() {
    return this.http.get(`${environment.companyNames}?guidCompany=${sessionStorage.getItem("guidCompany")}`, this.headers);
  }

  saveCompanyNames(companyNames: CompanyName[]) {
    return this.http.post(environment.saveCompanyName, companyNames, this.headers);
  }

  setCompanyNames(companyNames: CompanyNameResponse[]): void {
    this.companyNames.next(companyNames);
  }
}
