import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { WorksResponse } from "src/app/models/works/woks.response.model";
import { environment } from "src/environments/environment";
import { CollaborationModel } from "../../../models/works/collaboration.model";
import { InspectionModel } from "../../../models/works/inspection.model";
import { InspectionStatusModel } from "../../../models/works/inspection-status.model";

@Injectable({
  providedIn: "root",
})
export class InternalWorksService {
  protected works = new BehaviorSubject<WorksResponse[]>(null);
  currentWorks = this.works.asObservable();

  private headers = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    }),
  };

  constructor(public readonly http: HttpClient) {}

  getJobsRol() {
    return this.http
      .get(`${environment.internalServices.getJobsRol}?usuario=${sessionStorage.getItem("email")}&rol=${sessionStorage.getItem("rol")}`, this.headers);
  }

  updateJobs(job: any) {
    return this.http.put(
      `${environment.internalServices.updateJobs}`,
      job,
      this.headers
    );
  }

  getExpenses(job: string) {
    return this.http.get(
      `${environment.internalServices.getExpenses}?job=${job}`,
      this.headers
    );
  }

  getTasks(job: string) {
    return this.http.get(`${environment.tasks}?numeroJob=${job}`, this.headers);
  }

  getStatusJobs() {
    return this.http.get(`${environment.masters.statusJobs}`, this.headers);
  }

  getSectors() {
    return this.http.get(`${environment.masters.sector}`, this.headers);
  }

  getInternalContacts(): Observable<any> {
    return this.http.get(
      `${environment.masters.internalContacts}`,
      this.headers
    );
  }

  getExternalContacts(): Observable<any> {
    return this.http.get(
      `${environment.masters.externalContacts}`,
      this.headers
    );
  }

  getCountries() {
    return this.http.get(`${environment.countries}`, this.headers);
  }

  getTerritories() {
    return this.http.get(`${environment.masters.territory}`, this.headers);
  }

  getCollaborations(idJob: number): Observable<CollaborationModel[]> {
    return this.http.get<CollaborationModel[]>(`${environment.internalServices.collaborationByJob}?idjob=${idJob}`, this.headers);
  }

  createCollaboration(collaboration: CollaborationModel): Observable<any> {
    return this.http.post<any>(`${environment.internalServices.createCollaboration}`, collaboration, this.headers);
  }

  updateCollaboration(collaboration: CollaborationModel): Observable<any> {
    return this.http.put<any>(`${environment.internalServices.updateCollaboration}`, collaboration, this.headers);
  }

  getInspectionsByJob(idJob: number): Observable<InspectionModel[]> {
    return this.http.get<InspectionModel[]>(`${environment.inspection.getByJob}?idJob=${idJob}`, this.headers);
  }

  createInspection(inspection: InspectionModel): Observable<any> {
    return this.http.post<any>(`${environment.inspection.create}`, inspection, this.headers);
  }

  updateInspection(inspection: InspectionModel): Observable<any> {
    return this.http.put<any>(`${environment.inspection.update}`, inspection, this.headers);
  }

  getInspectionStatus(): Observable<InspectionStatusModel[]> {
    return this.http.get<InspectionStatusModel[]>(`${environment.masters.inspectionStatus}`, this.headers);
  }

}
