import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../../services/auth/auth.service";
import { AuthUser } from "../../../models/auth/auth.model";
import { AuthUserResponse } from "src/app/models/auth/auth.response.model";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  private userAuth: AuthUser;

  constructor(
    private readonly auth: AuthService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly router: Router,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.valideSSO();
    this.buildLoginForm();
  }

  private valideSSO() {
    if (sessionStorage.getItem("email")) {
      this.router.navigate(["/home/company-name"]);
    }
  }

  private buildLoginForm() {
    this.loginForm = this.formBuilder.group({
      Usuario: ["", Validators.required],
      Clave: ["", Validators.required],
    });
  }

  public getToken() {
    this.spinner.show();
    this.userAuth = this.loginForm.value;
    this.auth.getToken(this.userAuth).subscribe(
      (resp: any) => {
        sessionStorage.setItem("token", resp.token);
        this.spinner.hide();
        this.login();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          "El usuario o la contraseña no son validos",
          "Inicio de Sesión",
          {
            progressBar: true,
            progressAnimation: "increasing",
          }
        );
      }
    );
  }

  public login() {
    this.spinner.show();
    this.userAuth = this.loginForm.value;
    this.auth.signIn(this.userAuth).subscribe((resp: AuthUserResponse) => {
      if (resp.codigoRespuesta === 1) {
        this.toastr.error(
          "El usuario o la contraseña no son validos",
          "Inicio de Sesión",
          {
            progressBar: true,
            progressAnimation: "increasing",
          }
        );
        this.spinner.hide();
      } else {
        this.setSessionStorageAuthUser(resp);
        this.spinner.hide();
        this.router.navigate(["/home/projects"]);
      }
    });
  }

  public getTokenLogan() {
    this.spinner.show();
    this.auth.loginLogan().subscribe({
      next: (result) => {
        let authUserLogan: AuthUser = {
          Usuario: result.account.username,
          Clave: null,
        };
        this.auth.getToken(authUserLogan).subscribe(
          (resp: any) => {
            sessionStorage.setItem("token", resp.token);
            this.loginLogan(authUserLogan);
          },
          () => {
            this.toastr.error(
              "El usuario o la contraseña no son validos",
              "Inicio de Sesión",
              {
                progressBar: true,
                progressAnimation: "increasing",
              }
            );
          }
        );
        this.spinner.hide();
      },
      error: () => {
        this.toastr.error(
          "El usuario o la contraseña no son validos",
          "Inicio de Sesión",
          {
            progressBar: true,
            progressAnimation: "increasing",
          }
        );
        this.spinner.hide();
      },
    });
  }

  public loginLogan(userAuth: AuthUser) {
    this.spinner.show();
    this.auth.signIn(userAuth).subscribe((resp: AuthUserResponse) => {
      if (resp.codigoRespuesta === 1) {
        this.toastr.error(
          "El usuario o la contraseña no son validos",
          "Inicio de Sesión",
          {
            progressBar: true,
            progressAnimation: "increasing",
          }
        );
        this.spinner.hide();
      } else {
        this.setSessionStorageAuthUser(resp);
        this.spinner.hide();
        this.router.navigate(["/home/jobs"]);
      }
    });
  }

  private setSessionStorageAuthUser(resp: AuthUserResponse) {
    sessionStorage.setItem("email", resp.email);
    sessionStorage.setItem("guidCompany", resp.guidCompany);
    sessionStorage.setItem("guidClient", resp.guid);
    sessionStorage.setItem("companyName", resp.companyName);
    sessionStorage.setItem("nombreCompleto", resp.nombreCompleto);
    sessionStorage.setItem(
      "tipo",
      resp.type_User === 1 ? "Interno" : "Externo"
    );
    sessionStorage.setItem("rol", resp.rol);
    sessionStorage.setItem("country", resp.country);
    sessionStorage.setItem("userID", resp.id.toString());
  }

}
