import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AdvacedSettingsFeatureModel } from "src/app/models/advanced-settings/feature.model";
import { AdvancedSettingsService } from "src/app/services/admin-services/advanced-settings/advanced-settings.service";
import { ManageFeaturesDialogComponent } from "./manage-features-dialog/manage-features-dialog.component";

@Component({
  selector: "app-features",
  templateUrl: "./features.component.html",
})
export class FeaturesComponent implements OnInit {
  features: AdvacedSettingsFeatureModel[];

  constructor(
    private readonly dialog: MatDialog,
    private readonly _advancedSettingsService: AdvancedSettingsService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getAllFeatures();
  }

  openManageFeatureDialog(action?: string, feature?: any) {
    const dialogRef = this.dialog.open(ManageFeaturesDialogComponent, {
      width: "850pt",
      autoFocus: false,
      disableClose: false,
      data: {
        feature,
        action,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getAllFeatures();
      }
    });
  }

  private getAllFeatures() {
    this.spinner.show();
    this._advancedSettingsService.getAllFeatures().subscribe(
      (features: AdvacedSettingsFeatureModel[]) => {
        this.features = features;
        this.spinner.hide();
      },
      () => {
        this.toastr.error("Error! Please try again", "Error", {
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.spinner.hide();
      }
    );
  }
}
