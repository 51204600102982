import { Component, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { InternalWorksResponse } from "../../../../../models/works/internal.works.response.model";
import * as moment from "moment";
import { InternalWorksService } from "src/app/services/admin-services/works/internal-works.service";

@Component({
  selector: "app-internal-table",
  templateUrl: "./internal-table.component.html",
})
export class InternalTableComponent implements OnInit {
  filterTable: string;
  jobStatusSelect: any[] = [];
  contactSelect: any[] = [];
  isScreenSmall: boolean = false;

  private mediaQuery: MediaQueryList = window.matchMedia('(max-width: 768px)');

  @Output() updateInternalFilterJobs = new EventEmitter<any>();
  @Input() internalJobs: InternalWorksResponse[];
  @Input() internalProperties: any[];
  @Input() set filter(filter: string) {
    this.filterByWord(filter);
  }

  constructor(
    private readonly _InternalWorksServices: InternalWorksService
  ) {
    this.checkMediaQuery();
  }

  checkMediaQuery() {
    this.isScreenSmall = this.mediaQuery.matches;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkMediaQuery();
  }

  ngOnInit(): void {
    this.getStatusJobs();
    this.getInternalContacts();
  }

  identify(index, job) {
    return job.id;
  }

  filterByWord(filter: string) {
    this.filterTable = filter;
    this.internalJobs = JSON.parse(sessionStorage.getItem("internalJobs"));
    const internalJobsFiltered = this.filterByValue(this.internalJobs, filter);
    this.updateInternalFilterJobs.emit(internalJobsFiltered);
  }

  getStatusJobs() {
    this._InternalWorksServices
      .getStatusJobs()
      .subscribe((jobStatus: any[]) => (this.jobStatusSelect = jobStatus));
  }

  getStatusJobName(idStatusJob: number) {
    const statusJob = this.jobStatusSelect.find(
      (x) => x.id === idStatusJob
    )?.name_Status;
    return statusJob;
  }

  filterByValue(array, text) {
    return array?.filter((o) =>
      Object.keys(o).some((k: string) =>
        String(o[k])?.toLowerCase().includes(text?.toLowerCase())
      )
    );
  }

  validateDueDateState(date: any) {
    const today = moment(new Date());
    const dueDate = moment(date);
    const differenceDays = dueDate.diff(today, "days");

    if (differenceDays < -5) {
      return "text-red-600";
    } else if (differenceDays >= -5 && differenceDays <= 0) {
      return "text-orange-500";
    } else {
      return "text-black";
    }
  }

  getPropertyId(job: InternalWorksResponse) {
    return this.internalProperties?.findIndex(
      (x) => x.property_Number === job.property_Number
    );
  }

  getPropertyValues(job: InternalWorksResponse) {
    return this.internalProperties?.find(
      (property) => property.property_Number === job.property_Number
    );
  }

  validateCollaborator(collaborator: number) {
    return collaborator?.toString() === sessionStorage.getItem("userID");
  }

  private getInternalContacts() {
    this._InternalWorksServices
      .getInternalContacts()
      .subscribe((contacts: any[]) => (this.contactSelect = contacts));
  }
}
