import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PropertieResponse } from 'src/app/models/properties/properties.response.model';
import { PropertiesService } from 'src/app/services/admin-services/properties/properties.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-properties-table',
  templateUrl: './properties-table.component.html'
})
export class PropertiesTableComponent implements OnInit {

  propertiesResponse: PropertieResponse[];

  constructor(
    private readonly propertiesService: PropertiesService,
    private readonly spinner: NgxSpinnerService,
    private readonly authService: AuthService
  ) { }

  ngOnInit(): void {
    this.userProperties();
    this.loadSideBarConfiguration();
  }

  loadSideBarConfiguration() {
    let sidebar = document.querySelector(".sidebar");
    let closeBtn = document.querySelector("#btnMenuProperties");

    closeBtn.addEventListener("click", () => {
      sidebar.classList.toggle("open");
      menuBtnChange();
    });

    function menuBtnChange() {
      if (sidebar.classList.contains("open")) {
        closeBtn.classList.replace("bx-menu", "bx-menu-alt-right");
        document.querySelector(".margin-screen").classList.add("md:ml-14");
        document.querySelector(".margin-screen").classList.remove("md:ml-3");
      } else {
        closeBtn.classList.replace("bx-menu-alt-right", "bx-menu");
        document.querySelector(".margin-screen").classList.add("md:ml-3");
        document.querySelector(".margin-screen").classList.remove("md:ml-14");
      }
    }

  }

  userProperties() {
    this.spinner.show();
    this.propertiesService.currentPropiertes.subscribe( (resp: PropertieResponse[]) => {
      this.propertiesResponse = resp;
      this.spinner.hide();
    });
  }

  logout() {
    this.authService.logout();
  }

  get fullName() {
    return sessionStorage.getItem("nombreCompleto");
  }

}
