import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[inputNumber]",
})
export class InputNumberDirective {
  constructor(private el: ElementRef) {}

  regexNumber = "[0-9.]";
  @HostListener("keypress", ["$event"]) onKeyPress(event: KeyboardEvent) {
    return new RegExp(this.regexNumber).test(event.key);
  }

  @HostListener("paste", ["$event"]) blockPaste() {
    this.validateFields();
  }

  validateFields() {
    this.el.nativeElement.value = this.el.nativeElement.value
      .replace(/[^A-Za-z0-9À-ÖØ-öø-ÿ., ]+/g, "")
      .replace(/[ ]*$/g, "");
  }
}
