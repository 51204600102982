import { Component, OnInit } from "@angular/core";
import { LocalizationsService } from '../../services/localizations/localizations.service';
import { CountryResponse } from '../../models/localizations/country.reponse.model';
import { StateResponse } from '../../models/localizations/state.response.model';
import { CityResponse } from '../../models/localizations/city.response.model';
import { CompanyNameService } from '../../services/admin-services/company-name/company-name.service';
import { CompanyNameResponse } from '../../models/company-names/company-name.response.model';
import { ContactsService } from '../../services/admin-services/contacts/contacts.service';
import { ProjectsService } from "src/app/services/admin-services/projects/projects.service";
import { ProjectResponse } from "src/app/models/projects/project.response.model";
import { PropertiesService } from "src/app/services/admin-services/properties/properties.service";
import { PropertieResponse } from "src/app/models/properties/properties.response.model";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
})
export class AdminComponent implements OnInit {

  numberProjects: string[] = [];
  jobsTypes: string[] = [];

  constructor(
    private readonly localizations: LocalizationsService,
    private readonly companyName:  CompanyNameService,
    private readonly contacts: ContactsService,
    private readonly projects: ProjectsService,
    private readonly properties: PropertiesService
  ) {}

  ngOnInit(): void {
    this.loadMasters();
  }

  loadMasters() {
    this.listCountries();
    this.listStates();
    this.listCities();
    this.listCompanyNames();
    this.listContacts();
    this.listProjects();
  }

  listCountries() {
    this.localizations.getCountries().subscribe( (resp:CountryResponse[]) => {
      this.localizations.setCountries(resp);
    }, (error) => {
      console.log(error);
    })
  };

  listStates() {
    this.localizations.getStates().subscribe( (resp:StateResponse[]) => {
      this.localizations.setStates(resp);
    }, (error) => {
      console.log(error);
    })
  };

  listCities() {
    this.localizations.getCities().subscribe( (resp:CityResponse[]) => {
      this.localizations.setCities(resp);
    }, (error) => {
      console.log(error);
    })
  };

  listCompanyNames() {
    this.companyName.getCompanyNames().subscribe( (resp:CompanyNameResponse[]) => {
      this.companyName.setCompanyNames(resp);
    }, (error) => {
      console.log(error);
    })
  }

  listContacts() {
    this.contacts.getContacts().subscribe( (resp: any[]) => {
      this.contacts.setContacts(resp);
    }, (error) => {
      console.log(error);
    })
  }

  listProjects() {
    this.projects.getProjects().subscribe( (resp: ProjectResponse[]) => {
      resp.forEach(project => {
        this.numberProjects.push(project.project_Number);
      });
      sessionStorage.setItem("numberProjects", JSON.stringify(this.numberProjects));
      this.projects.setProjects(resp);
      this.listProperties();
    })
  }

  listProperties() {
    this.properties.getAllProperties().subscribe( (resp: PropertieResponse[]) => {
      sessionStorage.setItem("properties", JSON.stringify(resp));
      sessionStorage.setItem("internalProperties", JSON.stringify(resp));
      this.properties.setProperties(resp);
    });
  }

}
