import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertieResponse } from '../../../../../models/properties/properties.response.model';

@Component({
  selector: 'app-internal-table-properties',
  templateUrl: './internal-table-properties.component.html'
})
export class InternalTablePropertiesComponent implements OnInit {

  filterPropertiesTable: string;

  @Output() updateInternalFilterProperties = new EventEmitter<any>();
  @Input() propertiesResponse: PropertieResponse[];
  @Input() set filter(filter: string) {
    this.filterByWord(filter);
  }

  constructor() { }

  ngOnInit(): void {
  }

  identifyProperties(index, propertie) {
    return propertie.id;
  }

  filterByWord(filter: string) {
    if (filter) {
      this.filterPropertiesTable = filter;
      this.propertiesResponse = JSON.parse(sessionStorage.getItem("internalProperties"));
      var internalPropertiesFiltered = this.filterByValue(this.propertiesResponse, filter);
      this.updateInternalFilterProperties.emit(internalPropertiesFiltered);
    }
  }

  filterByValue(array, string) {
    return array?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k])?.toLowerCase().includes(string?.toLowerCase())
      )
    );
  }

}
