import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-replace-keep-file',
  templateUrl: './replace-keep-file.component.html'
})
export class ReplaceKeepFileComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      fileName: string
    },
  ) { }

  ngOnInit(): void {
  }

}
