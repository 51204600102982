import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InternalPropertiesService {

  private headers = { headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
  })};

  constructor(
    public readonly http: HttpClient
  ) { }

  sendNotificationDetail(notificationDetail: any): Observable<any> {
    return this.http.post(`${environment.internalServices.notificationDetails}`, notificationDetail, this.headers);
  }

  getDocumentsType() {
    return this.http.get(`${environment.masters.documentsType}`, this.headers);
  }

  getDocumentsCategory() {
    return this.http.get(`${environment.masters.documentsCategory}`, this.headers);
  }

  saveDocuments(documents: any[]) {
    return this.http.post(`${environment.internalServices.saveDocuments}`, documents, this.headers);
  }

  getPropertiesType() {
    return this.http.get(`${environment.masters.propertiesType}`, this.headers);
  }

}
