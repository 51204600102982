import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { ProjectsComponent } from "./views/admin/projects/projects.component";
import { PropertiesComponent } from "./views/admin/properties/properties.component";
import { WorksComponent } from "./views/admin/works/works.component";
import { TasksComponent } from "./views/admin/tasks/tasks.component";
import { AdvancesComponent } from "./views/admin/advances/advances.component";
import { CompanynameComponent } from "./views/admin/companyname/companyname.component";
import { ContactsComponent } from "./views/admin/contacts/contacts.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";

// components for views and layouts

import { AdminNavbarComponent } from "./components/navbars/admin-navbar/admin-navbar.component";
import { FooterComponent } from "./components/footers/footer/footer.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { LandingComponent } from "./views/landing/landing.component";
import { FormContactsComponent } from "./components/forms/form-contacts/form-contacts.component";
import { FormCompanynameComponent } from "./components/forms/form-companyname/form-companyname.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxPaginationModule } from "ngx-pagination";
import { PreventChangeRouteGuard } from "./config/prevent-change-route/prevent-change-route.guard";
import { WorksTableComponent } from "./components/tables/works/works-table.component";
import { WorksDetailsComponent } from "./components/tables/works/details/works-details.component";
import { TasksTableComponent } from "./components/tables/tasks/tasks-table.component";
import { TasksDetailsComponent } from "./components/tables/tasks/details/tasks-details.component";
import { PropertiesTableComponent } from "./components/tables/properties/properties-table.component";
import { PropertiesDetailsComponent } from "./components/tables/properties/details/properties-details.component";
import { ProjectsTableComponent } from "./components/tables/projects/projects-table.component";
import { ProjectsDetailsComponent } from "./components/tables/projects/details/projects-details.component";
import { AdvancesTableComponent } from "./components/tables/advances/advances-table.component";
import { AdvancesDetailsComponent } from "./components/tables/advances/details/advances-details.component";
import { RecoveryPasswordComponent } from "./views/auth/recovery-password/recovery-password.component";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { EmailComponent } from "./views/auth/recovery-password/steps/email/email.component";
import { CodeComponent } from "./views/auth/recovery-password/steps/code/code.component";
import { NewPasswordComponent } from "./views/auth/recovery-password/steps/new-password/new-password.component";
import { CodeInputModule } from "angular-code-input";
import { BreadcrumbModule } from "xng-breadcrumb";
import { FileUploadModule } from "@iplab/ngx-file-upload";
import { UploadDocumentComponent } from "./components/modals/upload-document/upload-document.component";
import { AuthNavbarComponent } from "./components/navbars/auth-navbar/auth-navbar.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { MsalModule } from "@azure/msal-angular";
import { PublicClientApplication } from "@azure/msal-browser";
import { InternalTableComponent } from "./components/tables/works/internal/internal-table/internal-table.component";
import { InternalWorksComponent } from "./views/admin/works/internal/internal-works/internal-works.component";
import { GooglemapsComponent } from "./components/googlemaps/googlemaps.component";
import { CalculatecardsComponent } from "./components/calculatecards/calculatecards.component";
import { GraphicsComponent } from "./components/graphics/graphics.component";
import { AgmCoreModule } from "@agm/core";
import { NgApexchartsModule } from "ng-apexcharts";
import { InternalDetailsComponent } from "./components/tables/works/internal/internal-details/internal-details.component";
import { DatepickerModule } from "ng2-datepicker";
import { InternalProjectsComponent } from "./views/admin/projects/internal/internal-projects/internal-projects.component";
import { InternalDetailsProjectsComponent } from "./components/tables/projects/internal/internal-details-projects/internal-details-projects.component";
import { InternalTableProjectsComponent } from "./components/tables/projects/internal/internal-table-projects/internal-table-projects.component";
import { InternalPropertiesComponent } from "./views/admin/properties/internal/internal-properties/internal-properties.component";
import { InternalDetailsPropertiesComponent } from "./components/tables/properties/internal/internal-details-properties/internal-details-properties.component";
import { InternalTablePropertiesComponent } from "./components/tables/properties/internal/internal-table-properties/internal-table-properties.component";
import { InternalDetailsTasksComponent } from "./components/tables/tasks/internal/internal-details-tasks/internal-details-tasks.component";
import { InternalTableTasksComponent } from "./components/tables/tasks/internal/internal-table-tasks/internal-table-tasks.component";
import { InternalTasksComponent } from "./views/admin/tasks/internal/internal-tasks/internal-tasks.component";
import { EditTaskDialogComponent } from "./components/tables/tasks/internal/internal-table-tasks/edit-task-dialog/edit-task-dialog.component";
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSelectModule } from "@angular/material/select";
import { MatMenuModule } from "@angular/material/menu";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatButtonModule } from "@angular/material/button";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatCardModule } from "@angular/material/card";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from "@angular-material-components/datetime-picker";
import { CustomTooltipComponent } from "./components/custom-tooltip/custom-tooltip.component";
import { ToolTipRendererDirective } from "./config/directives/tool-tip-renderer.directive";
import { TruncateTextPipe } from "./config/pipes/truncate-text.pipe";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { SelectSearchPipe } from "./config/pipes/select-search.pipe";
import { TasksGraphicsComponent } from "./components/tables/tasks/graphics/tasks-graphics.component";
import { AdvancedSettingsComponent } from "./views/admin/advanced-settings/advanced-settings.component";
import { UsersComponent } from "./views/admin/advanced-settings/security/users/users.component";
import { SecurityRolesComponent } from "./views/admin/advanced-settings/security/security-roles/security-roles.component";
import { MatNativeDateModule } from "@angular/material/core";
import { NgxMatMomentModule } from "@angular-material-components/moment-adapter";
import { ManageSecurityRolesDialogComponent } from "./views/admin/advanced-settings/security/security-roles/manage-security-roles-dialog/manage-security-roles-dialog.component";
import { ManageUserDialogComponent } from "./views/admin/advanced-settings/security/users/manage-user-dialog/manage-user-dialog.component";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { FeaturesComponent } from "./views/admin/advanced-settings/security/features/features.component";
import { ManageFeaturesDialogComponent } from "./views/admin/advanced-settings/security/features/manage-features-dialog/manage-features-dialog.component";
import { RuleJobsComponent } from "./views/admin/advanced-settings/production-settings/rule-jobs/rule-jobs.component";
import { ManageRuleJobsComponent } from "./views/admin/advanced-settings/production-settings/rule-jobs/manage-rule-jobs/manage-rule-jobs.component";
import { InternalContactsComponent } from "./views/admin/internal-contacts/internal-contacts.component";
import { ManageInternalContactDialogComponent } from "./views/admin/internal-contacts/manage-internal-contact-dialog/manage-internal-contact-dialog.component";
import { AccountsComponent } from "./views/admin/accounts/accounts.component";
import { ManageAccountDialogComponent } from "./views/admin/accounts/manage-account-dialog/manage-account-dialog.component";
import { InputNumberDirective } from "./config/directives/input-number.directive";
import { InputEmailDirective } from "./config/directives/input-email.directive";
import { ConfirmDialogNewVersionComponent } from "./components/modals/confirm-dialog/confirm-dialog.component";
import { ClickStopPropagationDirective } from "./config/directives/click-stop-propagation.directive";
import { environment } from "src/environments/environment";
import {
  CurrencyMaskConfig,
  CurrencyMaskModule,
  CURRENCY_MASK_CONFIG,
} from "ng2-currency-mask";
import { ProjectGraphicComponent } from "./components/tables/projects/graphics/project-graphic/project-graphic.component";
import { UploadFilesComponent } from './utils/upload-files/upload-files.component';
import { MatSortModule } from "@angular/material/sort";
import { UpdateDocumentDialogComponent } from './utils/update-document-dialog/update-document-dialog.component';
import { ReplaceKeepFileComponent } from './utils/replace-keep-file/replace-keep-file.component';
import { CollaborationDialogComponent } from './components/tables/works/internal/internal-details/collaboration-dialog/collaboration-dialog.component';
import { ConfirmationCollaborationDialogComponent } from './components/tables/works/internal/internal-details/collaboration-dialog/confirmation-collaboration-dialog/confirmation-collaboration-dialog.component';
import { CodeConfirmationComponent } from './views/auth/recovery-password/steps/code-confirmation/code-confirmation.component';
import { RecaptchaModule } from "ng-recaptcha";
import { TokenExpiredComponent } from './views/auth/recovery-password/steps/token-expired/token-expired.component';
import { InspectionDialogComponent } from './components/tables/works/internal/internal-details/inspection-dialog/inspection-dialog.component';
import { ConfirmationInspectionDialogComponent } from './components/tables/works/internal/internal-details/inspection-dialog/confirmation-inspection-dialog/confirmation-inspection-dialog.component';

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  decimal: ",",
  precision: 0,
  prefix: "$ ",
  suffix: "",
  thousands: ".",
};

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    FooterComponent,
    AdminNavbarComponent,
    AdminComponent,
    AuthComponent,
    LoginComponent,
    LandingComponent,
    RegisterComponent,
    CompanynameComponent,
    ContactsComponent,
    FormContactsComponent,
    FormCompanynameComponent,
    ProjectsComponent,
    PropertiesComponent,
    WorksComponent,
    TasksComponent,
    AdvancesComponent,
    WorksTableComponent,
    WorksDetailsComponent,
    TasksTableComponent,
    TasksDetailsComponent,
    PropertiesTableComponent,
    PropertiesDetailsComponent,
    ProjectsTableComponent,
    ProjectsDetailsComponent,
    AdvancesTableComponent,
    AdvancesDetailsComponent,
    RecoveryPasswordComponent,
    EmailComponent,
    CodeComponent,
    NewPasswordComponent,
    UploadDocumentComponent,
    AuthNavbarComponent,
    InternalTableComponent,
    InternalWorksComponent,
    GooglemapsComponent,
    CalculatecardsComponent,
    GraphicsComponent,
    InternalDetailsComponent,
    InternalProjectsComponent,
    InternalDetailsProjectsComponent,
    InternalTableProjectsComponent,
    InternalPropertiesComponent,
    InternalDetailsPropertiesComponent,
    InternalTablePropertiesComponent,
    InternalDetailsTasksComponent,
    InternalTableTasksComponent,
    InternalTasksComponent,
    EditTaskDialogComponent,
    CustomTooltipComponent,
    ToolTipRendererDirective,
    TruncateTextPipe,
    SelectSearchPipe,
    TasksGraphicsComponent,
    AdvancedSettingsComponent,
    UsersComponent,
    SecurityRolesComponent,
    ManageSecurityRolesDialogComponent,
    ManageUserDialogComponent,
    ConfirmDialogComponent,
    FeaturesComponent,
    ManageFeaturesDialogComponent,
    RuleJobsComponent,
    ManageRuleJobsComponent,
    InternalContactsComponent,
    ManageInternalContactDialogComponent,
    AccountsComponent,
    ManageAccountDialogComponent,
    InputNumberDirective,
    InputEmailDirective,
    ConfirmDialogNewVersionComponent,
    ClickStopPropagationDirective,
    ProjectGraphicComponent,
    UploadFilesComponent,
    UpdateDocumentDialogComponent,
    ReplaceKeepFileComponent,
    CollaborationDialogComponent,
    ConfirmationCollaborationDialogComponent,
    CodeConfirmationComponent,
    TokenExpiredComponent,
    InspectionDialogComponent,
    ConfirmationInspectionDialogComponent
  ],
  imports: [
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatExpansionModule,
    NgApexchartsModule,
    DatepickerModule,
    RecaptchaModule,
    AgmCoreModule.forRoot({
      apiKey: environment.agmConfiguration.apiKey,
    }),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.clientID,
          authority:
            "https://login.microsoftonline.com/14e28cbc-0266-4ab7-bf6b-9e0082c2e11e",
          redirectUri: window.location.origin,
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: isIE,
        },
      }),
      null,
      null
    ),
    AppRoutingModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    NgxPaginationModule,
    CdkStepperModule,
    CodeInputModule,
    BreadcrumbModule,
    FileUploadModule,
    Ng2SearchPipeModule,
    MatDialogModule,
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule,
    MatDividerModule,
    MatIconModule,
    MatCheckboxModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatDatepickerModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    NgxMatMomentModule,
    CurrencyMaskModule,
  ],
  providers: [
    PreventChangeRouteGuard,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
