import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth/auth.service';
import { passwordStrength } from 'check-password-strength'
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html'
})
export class NewPasswordComponent implements OnInit {

  newPasswordForm: UntypedFormGroup;
  passwordStrength: string[] = [];
  code: string;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly _AuthService: AuthService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _toastr: ToastrService,
    private readonly router: Router,
    private readonly _spinner: NgxSpinnerService
  ) {
    this.buildNewPasswodForm();
   }

  ngOnInit(): void {
    this.buildNewPasswodForm();
    this.validateToken();

    this.newPasswordForm.get('newPassword').valueChanges.subscribe((value: string) => {
      this.passwordStrength = passwordStrength(value).contains;
    });

    this._activatedRoute.queryParams.subscribe(params => {
      this.code = params['token'];
    });

  }

  private buildNewPasswodForm() {
    this.newPasswordForm = this.formBuilder.group({
      newPassword: ["", Validators.required],
      confirmPassword: ["", Validators.required]
    });
  }

  validateCode() {
    const data = {
      Codigo: decodeURIComponent(this.code),
      Email: '',
      ClaveNueva: this.newPasswordForm?.get('newPassword').value
    }

    if(this.newPasswordForm?.get('newPassword').value !== this.newPasswordForm?.get('confirmPassword').value) {
      this._toastr.error('Las contraseñas no coinciden');
      return;
    }

    if ( !(this.passwordStrength.includes('lowercase') && this.passwordStrength.includes('uppercase') && this.passwordStrength.includes('number')) ) {
      this._toastr.error('Debe cumplir con los requerimientos para crear una nueva contraseña');
      return;
    }

    this._spinner.show();
    this._AuthService.createNewPassword(data).subscribe({
      next: () => {
        this._spinner.hide();
        this._toastr.success('Contraseña actualizada correctamente', 'Éxito');
        this.router.navigate(['/auth/login']);
      },
      error: () => {
        this._spinner.hide();
        this._toastr.error('Error al actualizar la contraseña', 'Error');
      }
    });
  }

  private validateToken() {
    this._spinner.show();
    const data = {
      Codigo: decodeURIComponent(this.code),
      Email: '',
      ClaveNueva: ''
    }
    this._AuthService.validateCode(data).subscribe({
      next: () => {
        this._spinner.hide();
        this._toastr.success('Código validado correctamente');
      },
      error: () => {
        this._spinner.hide();
        this._toastr.error('Error al validar el código');
        this.router.navigate(['/auth/token-expired']);
      }
    });
  }
}
