import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectSearch'
})
export class SelectSearchPipe implements PipeTransform {

  transform(items: any[], searchCriteria: string, key: string): any[] {
    if (!items || !items.length) { return items; }
    if (!searchCriteria || !searchCriteria.length) { return items; }
    return items.filter(item => {
      return item[key].toString().toLowerCase().indexOf(searchCriteria.toLowerCase()) > -1;
    });
  }

}
