import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AccountModel } from "src/app/models/accounts/account.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AccountsService {
  protected accounts = new BehaviorSubject<AccountModel[]>(null);
  currentAccounts = this.accounts.asObservable();

  private headers = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    }),
  };

  constructor(public readonly http: HttpClient) {}

  getAccounts() {
    return this.http.get(environment.accounts.get, this.headers);
  }

  setAccounts(accounts: AccountModel[]) {
    this.accounts.next(accounts);
  }

  createAccount(data: any) {
    return this.http.post(environment.accounts.create, data, this.headers);
  }

  updateAccount(data: any) {
    return this.http.put(environment.accounts.update, data, this.headers);
  }

  getCompanyXproject(idCompany: number) {
    return this.http.get(environment.accounts.projectsXcompany, {
      params: {
        idCompany: idCompany.toString(),
      },
      headers: this.headers.headers,
    });
  }

  getProjectsCompany(projects: any) {
    return this.http.post(
      environment.accounts.projectsCompany,
      projects,
      this.headers
    );
  }
}
