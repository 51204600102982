import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CountryResponse } from '../../models/localizations/country.reponse.model';
import { StateResponse } from '../../models/localizations/state.response.model';
import { CityResponse } from '../../models/localizations/city.response.model';

@Injectable({
  providedIn: 'root'
})
export class LocalizationsService {

  protected countries = new BehaviorSubject<CountryResponse[]>(null);
  currentCountries = this.countries.asObservable();

  protected states = new BehaviorSubject<StateResponse[]>(null);
  currentStates = this.states.asObservable();

  protected cities = new BehaviorSubject<CityResponse[]>(null);
  currentCities = this.cities.asObservable();

  private headers = { headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
  })};

  constructor(
    public readonly http: HttpClient
  ) { }

  getCountries() {
    return this.http.get(environment.countries, this.headers);
  }

  getStates() {
    return this.http.get(environment.states, this.headers);
  }

  getCities() {
    return this.http.get(environment.cities, this.headers);
  }

  setCountries(countries: CountryResponse[]): void {
    this.countries.next(countries);
  }

  setStates(states: StateResponse[]): void {
    this.states.next(states);
  }

  setCities(cities: CityResponse[]): void {
    this.cities.next(cities);
  }


}
