import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html'
})
export class EmailComponent implements OnInit {

  public emailForm: UntypedFormGroup;
  recaptChaToken: string;
  environment = environment;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly _AuthService: AuthService,
    private readonly _toastr: ToastrService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _router: Router
  ) {
    this.buildEmailForm();
  }

  ngOnInit(): void {
  }

  private buildEmailForm() {
    this.emailForm = this.formBuilder.group({
      email: ["", Validators.required]
    });
  }

  resolvedRecaptcha(captchaResponse: string) {
    this.recaptChaToken = captchaResponse;
  }

  validateEmail() {
    if ( this.emailForm.invalid ) {
      this._toastr.error( "El correo es requerido" );
      return;
    }
    if ( !this.recaptChaToken ) {
      this._toastr.error( "Por favor, valida el captcha" );
      return;
    }
    this._spinner.show();
    this._AuthService.validateEmail( { Email: this.emailForm.get( "email" ).value }).subscribe({
      next: () => {
        this._toastr.success( "El correo ha sido validado correctamente" );
        this._spinner.hide();
        this._router.navigate( [ "/auth/send-email" ], { queryParams: { email: this.emailForm.get( "email" ).value } } );
      },
      error: () => {
        this._toastr.error( "Ha ocurrido un error al validar el correo" );
        this._spinner.hide();
      }
    });
  }

}
