import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { FileUploadValidators } from "@iplab/ngx-file-upload";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ProjectsService } from "../../services/admin-services/projects/projects.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import { ProjectFileModel } from "../../models/projects/project.file.model";
import { ReplaceKeepFileComponent } from "../replace-keep-file/replace-keep-file.component";

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html'
})
export class UploadFilesComponent implements OnInit {

  private filesControl = new UntypedFormControl(
    null,
    FileUploadValidators.filesLimit(10)
  );

  public fileForm = new UntypedFormGroup({
    files: this.filesControl,
    documentFiles: new FormArray([], Validators.required)
  });

  constructor(
    private dialogRef: MatDialogRef<UploadFilesComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      documentTypes: any[];
      id: number;
      regarding: string;
      idFolder: string;
      documents: ProjectFileModel[]
    },
    private readonly _ProjectsService: ProjectsService,
    private readonly toastr: ToastrService,
    private readonly spinner: NgxSpinnerService,
    private readonly dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.documentFilesValueChanges();
  }

  async documentFilesValueChanges() {
    this.filesControl.valueChanges.subscribe((files) => {
      const documentsData = this.fileForm.get('documentFiles') as FormArray;
      let fileName = "";
      let keepOrReplaceFile = false;
      documentsData.clear();
      files.forEach(async (file: any) => {
        const extension = file.name.substring(file.name.lastIndexOf('.') + 1);
        const name = file.name.replace(/^.*[\\\/]/, '').replace(/\.[^/.]+$/, '');
        fileName = file.name.replace(/\s/g, "");

        if(this.keepOrReplaceFile(file)) {
          keepOrReplaceFile = await this.openReplaceKeepFileDialog(file.name);
          if (!keepOrReplaceFile) {
            this.filesControl.value.splice(this.filesControl.value.indexOf(file), 1);
            this.fileForm.get('files')?.setValue(this.filesControl.value);
            return;
          }
        }

        if(keepOrReplaceFile) {
          fileName = `${name}_${moment(new Date()).format('YYYYMMDDHHmmss')}-Copy.${extension}`;
        }

        const documentData = new UntypedFormGroup({
          documento: new UntypedFormControl(file),
          id: new UntypedFormControl("0"),
          idItem: new UntypedFormControl(this.data.id),
          regarding: new UntypedFormControl(this.data.regarding),
          url: new UntypedFormControl(fileName),
          item: new UntypedFormControl("1"),
          documentName: new UntypedFormControl(fileName),
          documentType: new UntypedFormControl(null),
          createdOn: new UntypedFormControl(new Date()),
          createdBy: new UntypedFormControl(sessionStorage.getItem("userID")),
          modifiedOn: new UntypedFormControl(new Date()),
          modifiedBy: new UntypedFormControl(sessionStorage.getItem("userID")),
          status: new UntypedFormControl("true"),
        });

        documentsData.push(documentData);

      });
    });
  }

  uploadDocuments(): void {
    let filesToUpload = this.filesControl.value.length;

    this.filesFormArray.value.forEach((file: any) => {

      const formData = new FormData();
      formData.append('Documento', file.documento);
      formData.append('Id', file.id);
      formData.append('IdItem', file.idItem);
      formData.append('Regarding', file.regarding);
      formData.append('Url', file.url);
      formData.append('Item', file.item);
      formData.append('DocumentName', `/${this.data.regarding}/${this.data.idFolder}/${file.documentName}`);
      formData.append('DocumentType', file.documentType);
      formData.append('CreatedOn', moment(file.createdOn).format('YYYY-MM-DD'));
      formData.append('CreatedBy', file.createdBy);
      formData.append('ModifiedOn', moment(file.modifiedOn).format('YYYY-MM-DD'));
      formData.append('ModifiedBy', file.modifiedBy);
      formData.append('Status', file.status);

      this._ProjectsService.uploadDocuments(formData).subscribe({
        next: () => {
          filesToUpload--;
          this.toastr.success("Documents uploaded successfully", "Success");
          this._ProjectsService.setFilesUploaded(filesToUpload);
        },
        error: () => {
          this.toastr.error("Error uploading documents", "Error");
        }
      });

    });

    this.dialogRef.close(true);

  }

  openReplaceKeepFileDialog(fileName: string): Promise<any> {
    const dialogRef = this.dialog.open(ReplaceKeepFileComponent, {
      autoFocus: false,
      disableClose: true,
      width: "500px",
      data: {
        fileName: fileName
      }
    });

    return new Promise((resolve, reject) => {
      dialogRef.afterClosed().subscribe(result => {
        resolve(result);
      });
    });
  }

  private keepOrReplaceFile(file: any): boolean {
    return this.data.documents.some( ( document: any ) => document.documentName === `/${this.data.regarding}/${this.data.idFolder}/${file.name}` );
  }

  get filesFormArray(): any {
    return this.fileForm.get('documentFiles') as FormArray;
  }

}
