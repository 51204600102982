import { Component, OnInit, QueryList, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { combineLatest } from "rxjs";
import { ProjectResponse } from "src/app/models/projects/project.response.model";
import { PropertieResponse } from "src/app/models/properties/properties.response.model";
import { WorksResponse } from "src/app/models/works/woks.response.model";
import { ProjectsService } from "src/app/services/admin-services/projects/projects.service";
import { PropertiesService } from "src/app/services/admin-services/properties/properties.service";
import { WorksService } from "src/app/services/admin-services/works/works.service";
import { cleanNullAttributes, findByMatchingProperty, loadSideBarConfiguration, searchJson } from "src/app/utils/utils";
import * as moment from "moment";
import { InternalWorksResponse } from "src/app/models/works/internal.works.response.model";
import { InternalWorksService } from "src/app/services/admin-services/works/internal-works.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { AccountRelationalontactModel } from "src/app/models/accounts/relational-contact.model";
import { InternalContactsService } from "src/app/services/admin-services/internal-contacts/internal-contacts.service";
import { InternalContactModel } from "src/app/models/internal-contacts/internal-contact.model";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogNewVersionComponent } from "src/app/components/modals/confirm-dialog/confirm-dialog.component";
import { CanComponentDeactivate } from "src/app/config/prevent-change-route/prevent-change-route.guard";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { ProjectFileModel } from "../../../../../models/projects/project.file.model";
import { UploadFilesComponent } from "../../../../../utils/upload-files/upload-files.component";
import { MatPaginator } from "@angular/material/paginator";
import { map } from "rxjs/operators";
import { MatSort } from "@angular/material/sort";
import { UpdateDocumentDialogComponent } from "../../../../../utils/update-document-dialog/update-document-dialog.component";
import { getExtensionIcon } from "../../../../../utils/file-extensions-allowed";
import { Group } from "../../../../../models/general/group-column.class";

@Component({
  selector: "app-internal-details-projects",
  templateUrl: "./internal-details-projects.component.html",
})
export class InternalDetailsProjectsComponent implements OnInit, CanComponentDeactivate {
  @ViewChild(MatTable) matTables : QueryList<MatTable<any>>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator)
  set paginator(pagination: MatPaginator) {
    this.dataSource.paginator = pagination;
    this.dataSource.sort = this.sort;
  }
  groupByColumns: string[] = ['documentType'];
  displayedColumns: string[] = [
    "item",
    "documentName",
    "documentType",
    "createdOn",
    "createdBy",
    "modified",
    "modifiedBy",
    "status",
    "fileActions"
  ];
  dataSource = new MatTableDataSource<ProjectFileModel | Group>([]);
  projects: string;
  projectNumber: string;
  propertiesResponse: PropertieResponse[] = [];
  city: string;
  type: string;
  account: string;
  citySelect = [];
  propertieTypeSelect = [];
  propertieAccountSelect = [];
  worksResponse: WorksResponse[] = [];
  filterInternalJobs: InternalWorksResponse[] = [];
  statusJob: string;
  country: string;
  cityJobs: string;
  countrySelect = [];
  cityJobsSelect = [];
  contactSelect: any[] = [];
  projectsResponse: ProjectResponse[] = [];
  projectForm: UntypedFormGroup;
  filtersPropertiesForm: UntypedFormGroup;
  filtersJobsForm: UntypedFormGroup;
  startDateValue = null;
  endDateValue = null;
  internalProperties = [];
  contactRecords: InternalContactModel[];
  idClient: number;
  projectStatusSelect: any[] = [];
  isButtonDisabled = false;
  routerUrl: string;
  documentTypes: any[] = [];
  documents: ProjectFileModel[] = [];
  documentsUploaded: number;
  userType: string = sessionStorage.getItem("tipo");

  constructor(
    private readonly _Activatedroute: ActivatedRoute,
    private readonly _PropertiesService: PropertiesService,
    private readonly _WorksSevice: WorksService,
    private readonly _InternalWorksServices: InternalWorksService,
    private readonly _ProjectsService: ProjectsService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: ToastrService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly authService: AuthService,
    private readonly internalContactService: InternalContactsService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly location: Location
  ) {}

  ngOnInit(): void {
    this.getFilesUploaded();
    this.getParameteresURL();
    this.getProjectStatus();
    this.buildFiltersPropertiesForms();
    this.buildFiltersJobsForms();
    this.getInternalContacts();
    this.getDocumentsType();
    this.internalProperties = JSON.parse(
      sessionStorage.getItem("internalProperties")
    );
    loadSideBarConfiguration("btnMenuInternalProjectsDetails");
    this.router.events.subscribe((event) => {
      this.routerUrl = event["url"];
      if (this.routerUrl === "/") {
        this.routerUrl = "/home/projects";
      }
    });
  }

  applyFilter(event: Event) {
    const documentsFiltered = [];
    const filterValue = (event.target as HTMLInputElement).value;

    this.documents.forEach((element) => {
      const result = searchJson(element, filterValue);
      if (Object.keys(result).length > 0) {
        documentsFiltered.push(result);
      }
    });

    this.dataSourceConfiguration(documentsFiltered);

  }

  getIcon(document: ProjectFileModel): string {
    const extension = document.documentName.substring(document.documentName.lastIndexOf('.') + 1);
    return getExtensionIcon(extension);
  }

  getFilesUploaded(): void {
    this._ProjectsService.currentFilesUploaded.subscribe((files) => {
      this.documentsUploaded = files;
    });
  }

  viewDocument(documentUrl: string): void {
    if (documentUrl.includes(".pdf")) {
      window.open(documentUrl, "_blank");
    } else {
      window.open(`https://view.officeapps.live.com/op/view.aspx?src=${documentUrl}`, "_blank");
    }
  }

  downloadDocument(documentName: string): void {
    this._ProjectsService.downloadDocument(documentName).subscribe({
      next: (response: any) => {
        const linkSource = `data:image/jpg;base64,${response}`;
        const downloadLink = document.createElement("a");
        const fileName = documentName;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      },
      error: () => {
        this.toastr.error("Error! Please try again", "Error");
      }
    });
  }

  groupByDocumentType(status: boolean): void {
    this.groupByColumns = status ? ['documentType'] : [];
    this.dataSource.data = this.addGroups(this.documents, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  setStatusDocument(document: ProjectFileModel, status: boolean): void {
    this.spinner.show();
    const data = {
      ...document,
      status: status
    }
    this._ProjectsService.setStatusDocument(data).subscribe({
      next: (response: any) => {
        const documentStatus = status ? 'Inactive' : 'Active';
        this.getDocumentLocationByItem(documentStatus);
        this.toastr.success("Document updated successfully", "Success");
      },
      error: () => {
        this.spinner.hide();
        this.toastr.error("Error! Please try again", "Error");
      }
    });
  }

  getDocumentsType() {
    this._ProjectsService
      .getDocumentsTypeBlob()
      .pipe(
        map((response: any[]) => response.filter((type) => type.sourceTable === "Projects")),
      )
      .subscribe((types: any) => {
        this.documentTypes = types
      });
  }

  getDocumentTypeName(id: number) {
    return this.documentTypes.find((x) => x.id === id)?.documentType;
  }

  tabChanged(event) {
    if (event.index > 0) {
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }

  validateForm(): Promise<boolean> {
    if (this.projectForm.dirty) {
      sessionStorage.setItem("projectDirty", "true");
      const dialogRef = this.dialog.open(ConfirmDialogNewVersionComponent, {
        autoFocus: false,
        disableClose: true,
        data: {
          title: "Are you sure to quit without saving?",
          textSaveButton: "Save changes",
          textCloseButton: "Exit without saving",
        },
      });
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          sessionStorage.setItem("projectDirty", "false");
          this.updateGeneralProject();
          this.projectForm.reset();
          this.location.back();
        } else {
          sessionStorage.setItem("projectDirty", "false");
          this.projectForm.reset();
          this.location.back();
        }
      });
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  }

  manageDocument(document: ProjectFileModel, action): void {
    const dialogRef = this.dialog.open(UpdateDocumentDialogComponent, {
      autoFocus: false,
      disableClose: true,
      width: "700px",
      data: {
        documentTypes: this.documentTypes,
        document: document,
        action: action
      }
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        setTimeout(() => {
          this.getDocumentLocationByItem('Active');
        }, 2000)
      }
    });
  }

  openUploadFileProject(): void {
    const dialogRef = this.dialog.open(UploadFilesComponent, {
      autoFocus: false,
      disableClose: true,
      width: "1500px",
      data: {
        documentTypes: this.documentTypes,
        id: this.projectsResponse[0].id,
        regarding: 'Projects',
        idFolder: this.projectsResponse[0].project_Number,
        documents: this.documents
      }
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        setTimeout(() => {
          this.getDocumentLocationByItem('Active');
        }, 2000)
      }
    });
  }

  openConfirmUpdateProjectDialog(
    textSaveButton: string,
    textCloseButton: string,
    title: string,
    action: string
  ): void {
    const dialogRef = this.dialog.open(ConfirmDialogNewVersionComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        title,
        textSaveButton,
        textCloseButton,
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        if (action === "close" || action === "cancel") {
          this.updateGeneralProject();
          this.projectForm.reset();
          this.router.navigate(["/home/projects"]);
        } else {
          this.updateGeneralProject();
        }
      } else if ( action === "cancel" ) {
        this.projectForm.reset();
        this.router.navigate( [ "/home/projects" ] );
      }
    });
  }

  buildGeneralForm() {
    this.projectForm = this.formBuilder.group({
      status: [this.projectsResponse[0]["status"]],
      account_officer: [this.projectsResponse[0]["account_officer"]],
      production_Admin: [this.projectsResponse[0]["production_Admin"]],
      payments: [this.projectsResponse[0]["payments"]],
      other_Payments: [this.projectsResponse[0]["other_Payments"]],
      special_Conditions: [this.projectsResponse[0]["special_Conditions"]],
      other_Special_Conditions: [
        this.projectsResponse[0]["other_Special_Conditions"],
      ],
      termoAgreement: [this.projectsResponse[0]["termoAgreement"]],
      other_Termof_Agreement: [
        this.projectsResponse[0]["other_Termof_Agreement"],
      ],
      annual_Price_Ajustments: [
        this.projectsResponse[0]["annual_Price_Ajustments"],
      ],
      iD_Referred_By: [this.projectsResponse[0]["iD_Referred_By"]],
      referralFee: [this.projectsResponse[0]["referralFee"]],
      id_Client: [this.projectsResponse[0]["id_Client"]],
      id_Account_Officer: [this.projectsResponse[0]["id_Account_Officer"]],
      id_Production_Admin: [this.projectsResponse[0]["id_Production_Admin"]],
      id_Status_Project: [this.projectsResponse[0]["id_Status_Project"]],
    });
  }

  getParameteresURL() {
    this._Activatedroute.paramMap.subscribe((params) => {
      this.projectNumber = params.get("projectNumber");
    });
    this.projectDetails();
  }

  updateGeneralProject() {
    this.spinner.show();
    const data = {
      id: this.projectsResponse[0].id,
      project_Number: this.projectsResponse[0].project_Number,
      client: this.projectsResponse[0].client,
      projectName: this.projectsResponse[0].projectName,
      porpouse: this.projectsResponse[0].porpouse,
      production_Admin: this.projectsResponse[0]["production_Admin"],
      assigned_to: this.projectsResponse[0].assigned_to,
      status: this.projectForm.get("status").value,
      account_officer: this.projectsResponse[0]["account_officer"],
      guiD_client: this.projectsResponse[0].guiD_client,
      special_Conditions: this.projectForm.get("special_Conditions").value,
      other_Special_Conditions:
        this.projectsResponse[0].other_Special_Conditions,
      annual_Price_Ajustments: this.projectForm.get("annual_Price_Ajustments")
        .value,
      termoAgreement: this.projectForm.get("termoAgreement").value,
      payments: this.projectForm.get("payments").value,
      descriptions: this.projectsResponse[0].descriptions,
      other_Termof_Agreement: this.projectsResponse[0].other_Termof_Agreement,
      other_Payments: this.projectsResponse[0].other_Payments,
      dateValue_or_Analysis_Specific:
        this.projectsResponse[0].dateValue_or_Analysis_Specific,
      date_Of_Value_or_Analysis:
        this.projectsResponse[0].date_Of_Value_or_Analysis,
      quote_type: this.projectsResponse[0].quote_type,
      enSpecialCondition: this.projectsResponse[0]["enSpecialCondition"],
      enTermof_Agreement: this.projectsResponse[0]["enTermof_Agreement"],
      country: this.projectsResponse[0]["country"],
      project_Number_text: this.projectsResponse[0]["project_Number_text"],
      created: this.projectsResponse[0]["created"],
      es_Payments: this.projectsResponse[0].es_Payments,
      id_Order: this.projectsResponse[0].id_Order,
      phone_Account_Officer: this.projectsResponse[0]["phone_Account_Officer"],
      email_Account_Officer: this.projectsResponse[0]["email_Account_Officer"],
      project_Fees: this.projectsResponse[0]["project_Fees"],
      currency: this.projectsResponse[0]["currency"],
      number_of_jobs: this.projectsResponse[0]["number_of_jobs"],
      number_of_Properties: this.projectsResponse[0]["number_of_Properties"],
      iD_Referred_By: this.projectForm.get("iD_Referred_By").value,
      referralFee: this.projectForm.get("referralFee").value,
      id_Client: this.projectsResponse[0]["id_Client"],
      id_Account_Officer: this.projectForm.get("id_Account_Officer").value,
      id_Production_Admin: this.projectForm.get("id_Production_Admin").value,
      id_Status_Project: this.projectForm.get("id_Status_Project").value,
    };

    this._ProjectsService.updateProject(data).subscribe(
      () => {
        this._ProjectsService
          .getProjects()
          .subscribe((response: ProjectResponse[]) => {
            this.projectsResponse = response;
            this.toastr.success(
              "Se ha actualizado exitosamente la información general del proyecto",
              "Detalle proyecto",
              {
                progressBar: true,
                progressAnimation: "increasing",
              }
            );
            window.location.reload();
            this.spinner.hide();
          });
      },
      () => {
        this.toastr.error(
          "Se ha presentado un problema actualizando la sección General del Proyecto ",
          "Detalle proyecto",
          {
            progressBar: true,
            progressAnimation: "increasing",
          }
        );
        this.spinner.hide();
      }
    );
  }

  projectDetails() {
    this.spinner.show();
    combineLatest([
      this._PropertiesService.getPropertieByProjectNumber(this.projectNumber),
      this._WorksSevice.getWorksByProjectNumber(this.projectNumber),
      this._ProjectsService.currentProjects,
    ]).subscribe(
      ([properties, works, projects]) => {
        if (properties !== null && works !== null && projects) {
          this.propertiesResponse = properties;
          sessionStorage.setItem(
            "internalDetailProperties",
            JSON.stringify(properties)
          );
          this.worksResponse = works;
          sessionStorage.setItem("internalDetailJobs", JSON.stringify(works));
          this.projectsResponse = projects.filter(
            (project) => project.project_Number === this.projectNumber
          );
          this.idClient = projects.find(
            (project) => project.project_Number === this.projectNumber
          ).id_Client;
          this.spinner.hide();
          this.buildGeneralForm();
          this.getUniqueValuesOnchangeSelectProperties();
          this.getUniqueValuesOnchangeSelectJobs();
          this.getCompanyxContacts(this.idClient);
          this.getDocumentLocationByItem('Active');
        }
      },
      () => {
        this.toastr.error(
          "Se ha presentado un problema cargando el detalle del proyecto " +
            this.projectNumber,
          "Detalle proyecto",
          {
            progressBar: true,
            progressAnimation: "increasing",
          }
        );
        this.spinner.hide();
      }
    );
  }

  logout() {
    this.authService.logout();
  }

  get fullName() {
    return sessionStorage.getItem("nombreCompleto");
  }

  buildFiltersPropertiesForms() {
    this.filtersPropertiesForm = this.formBuilder.group({
      city: [null],
      property_Type: [null],
      property_Account: [null],
    });
  }

  buildFiltersJobsForms() {
    this.filtersJobsForm = this.formBuilder.group({
      iD_Job_Status: [null],
      country: [null],
      city: [null],
    });
  }

  getUniqueValuesOnchangeSelectJobs() {
    this.countrySelect = this.worksResponse
      .map((job) => job["country"])
      .filter((value, index, self) => self.indexOf(value) === index);

    this.cityJobsSelect = this.worksResponse
      .map((job) => job["city"])
      .filter((value, index, self) => self.indexOf(value) === index);
  }

  getUniqueValuesOnchangeSelectProperties() {
    this.citySelect = this.propertiesResponse
      .map((property) => property["city"])
      .filter((value, index, self) => self.indexOf(value) === index);

    this.propertieTypeSelect = this.propertiesResponse
      .map((property) => property["property_Type"])
      .filter((value, index, self) => self.indexOf(value) === index);

    this.propertieAccountSelect = this.propertiesResponse
      .map((property) => property["property_Account"])
      .filter((value, index, self) => self.indexOf(value) === index);
  }

  filterProperties() {
    const filters = cleanNullAttributes(this.filtersPropertiesForm.value);
    const resultFilter = findByMatchingProperty(
      this.propertiesResponse,
      filters
    );

    this.propertiesResponse = resultFilter;
  }

  filterJobs() {
    let startDate = this.startDate;
    let endDate = this.endDate;

    this.filterInternalJobs = JSON.parse(
      sessionStorage.getItem("internalDetailJobs")
    ).map((job) => ({
      ...job,
      created: job.created.split("T")[0],
    }));

    if (this.startDateValue && this.endDateValue) {
      const resultStartEndDate = this.filterInternalJobs.filter(
        (job) => job.created >= startDate && job.created <= endDate
      );
      this.filterInternalJobs = resultStartEndDate;
    }

    const filtersForm = {
      ...this.filtersJobsForm.value,
      iD_Job_Status: +this.filtersJobsForm.value.iD_Job_Status,
    };

    const filters = cleanNullAttributes(filtersForm);
    const resultFilter = findByMatchingProperty(
      this.filterInternalJobs,
      filters
    );

    this.worksResponse = resultFilter;
  }

  clearFiltersProperties() {
    this.filtersPropertiesForm.patchValue({
      city: undefined,
      property_Type: undefined,
      property_Account: undefined,
    });
    this.propertiesResponse = JSON.parse(
      sessionStorage.getItem("internalDetailProperties")
    );
  }

  clearFiltersJobs() {
    this.startDateValue = undefined;
    this.endDateValue = undefined;
    this.filtersJobsForm.patchValue({
      iD_Job_Status: undefined,
      country: undefined,
      city: undefined,
    });
    this.worksResponse = JSON.parse(
      sessionStorage.getItem("internalDetailJobs")
    );
  }

  get startDate() {
    return moment(this.startDateValue).format("YYYY-MM-DD");
  }

  get endDate() {
    return moment(this.endDateValue).format("YYYY-MM-DD");
  }

  getInternalContacts() {
    this._InternalWorksServices
      .getInternalContacts()
      .subscribe((contacts: any[]) => (this.contactSelect = contacts));
  }

  getIndexProperty(property: any) {
    return this.internalProperties.findIndex(
      (x) => x.property_Number === property["property_Number"]
    );
  }

  getInternalContactName(id: string) {
    return this.contactSelect.find((x) => x.id === id)?.full_Name;
  }

  getRelationalContactName(id: number) {
    if (id) {
      const fullContactName = this.contactRecords?.find(
        (x) => x.idContactInformationRecord === id
      );
      return `${fullContactName?.firstName} ${fullContactName?.lastName}`;
    }
  }

  private getProjectStatus() {
    this._ProjectsService
      .getStatusProjects()
      .subscribe((projectStatus: any[]) => (this.projectStatusSelect = projectStatus));
  }

  private getCompanyxContacts(idClient: number) {
    this.spinner.show();
    this.internalContactService.getCompanyxContacts(idClient).subscribe(
      (contacts: AccountRelationalontactModel[]) => {
        this.spinner.hide();
        const contactIds = contacts.map(
          (contact) => contact.idContactInformationRecord
        );
        this.getRecordsIds(contactIds);
      },
      () => {
        this.spinner.hide();
        this.toastr.error(
          `Error! Does not exist an ID Client in ${this.projectNumber}`,
          "Error"
        );
      }
    );
  }

  private getRecordsIds(contacts: number[]) {
    this.spinner.show();
    this.internalContactService.getRecordsIds(contacts).subscribe(
      (contactRecords: InternalContactModel[]) => {
        this.spinner.hide();
        this.contactRecords = contactRecords;
      },
      () => {
        this.spinner.hide();
        this.toastr.error("Error! Please try again", "Error");
      }
    );
  }

  private getDocumentLocationByItem(status: string): void {
    this._ProjectsService.getDocumentLocationByItem(this.projectsResponse[0].id, status).subscribe(
      {
        next: (response: ProjectFileModel[]) => {
          this.documents = response;
          this.dataSourceConfiguration(response);
        },
        error: () => {
          this.toastr.error("Error! Please try again", "Error");
        }
      }
    );
  }

  private dataSourceConfiguration(response: ProjectFileModel[]) {
    this.dataSource.data = this.addGroups(response, this.groupByColumns);
    this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
    this.dataSource.sort = this.sort;
  }

  private addGroups(data: any[], groupByColumns: string[]): any[] {
    var rootGroup = new Group();
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  private getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    // Recursive function, stop when there are no more levels.
    if (level >= groupByColumns.length)
      return data;

    var groups = this.uniqueBy(
      data.map(
        row => {
          var result = new Group();
          result.level = level + 1;
          result.parent = parent;
          for (var i = 0; i <= level; i++)
            result[groupByColumns[i]] = row[groupByColumns[i]];
          return result;
        }
      ),
      JSON.stringify);

    const currentColumn = groupByColumns[level];

    var subGroups = [];
    groups.forEach(group => {
      let rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn])
      let subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    })
    return subGroups;
  }

  private uniqueBy(a, key) {
    var seen = {};
    return a.filter(function (item) {
      var k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    })
  }

  private customFilterPredicate(data: ProjectFileModel | Group): boolean {
    return (data instanceof Group) ? data.visible : this.getDataRowVisible(data);
  }

  private getDataRowVisible(data: ProjectFileModel): boolean {
    const groupRows = this.dataSource.data.filter(
      row => {
        if (!(row instanceof Group)) return false;

        let match = true;
        this.groupByColumns.forEach(
          column => {
            if (!row[column] || !data[column] || row[column] !== data[column]) match = false;
          }
        );
        return match;
      }
    );

    if (groupRows.length === 0) return true;
    if (groupRows.length > 1) throw "Data row is in more than one group!";
    const parent = <Group>groupRows[0];  // </Group> (Fix syntax coloring)

    return parent.visible && parent.expanded;
  }

  groupHeaderClick(row) {
    row.expanded = !row.expanded
    this.dataSource.filter = performance.now().toString();
  }

  isGroup(index, item): boolean {
    return item.level;
  }
}
