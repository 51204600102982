import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  routerUrl: string;
  constructor(
    public readonly router: Router
  ) {}

  portalTitle: string;

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.routerUrl = event.url;
      }
    });

    this.portalTitle = sessionStorage.getItem("tipo");
  }

  get routerWorksDetails() {
    return this.routerUrl?.includes("jobs-details");
  }

}
