import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { CityResponse } from "src/app/models/localizations/city.response.model";
import { CountryResponse } from "src/app/models/localizations/country.reponse.model";
import { StateResponse } from "src/app/models/localizations/state.response.model";
import { LocalizationsService } from "src/app/services/localizations/localizations.service";
import { combineLatest } from "rxjs";
import { InternalContactsService } from "../../../../services/admin-services/internal-contacts/internal-contacts.service";
import { InternalContactModel } from "../../../../models/internal-contacts/internal-contact.model";
import { ConfirmDialogComponent } from "src/app/components/confirm-dialog/confirm-dialog.component";
import { MasterService } from "../../../../services/master/master.service";
import { MasterContactClassModel } from "src/app/models/master/contact-class.model";
import { AccountsService } from "src/app/services/admin-services/accounts/accounts.service";
import { AccountModel } from "src/app/models/accounts/account.model";
import { ActivatedRoute, Router } from "@angular/router";
import { InternalContactPropertyModel } from "src/app/models/internal-contacts/proprety-contact.model";
import { InternalContactJobModel } from "src/app/models/internal-contacts/job-contact.model";
import { PropertyModel } from "src/app/models/properties/property.model";
import { JobModel } from "src/app/models/works/job.model";
import { InternalContactProjectModel } from "src/app/models/internal-contacts/project-contact.model";
import { ProjectResponse } from "src/app/models/projects/project.response.model";
import { InternalWorksService } from "src/app/services/admin-services/works/internal-works.service";

@Component({
  selector: "app-manage-internal-contact-dialog",
  templateUrl: "./manage-internal-contact-dialog.component.html",
})
export class ManageInternalContactDialogComponent implements OnInit {
  internalContactForm: UntypedFormGroup;
  accounts: AccountModel[];
  filteredStates: StateResponse[];
  filteredCities: CityResponse[];
  countriesResponse: CountryResponse[];
  statesResponse: StateResponse[];
  citiesResponse: CityResponse[];
  internalContacts: InternalContactModel[];
  contactClasses: MasterContactClassModel[];
  internalContact: InternalContactModel;
  internalContactProperties: PropertyModel[];
  internalContactJobs: JobModel[];
  internalContactProjects: ProjectResponse[];
  internalProperties = [];
  idInternalContact: number;
  loadForm = false;
  jobStatusSelect: any[] = [];

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: ToastrService,
    private readonly dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private dialogRef: MatDialogRef<ManageInternalContactDialogComponent>,
    private readonly localizationService: LocalizationsService,
    private readonly internalContactService: InternalContactsService,
    private readonly masterService: MasterService,
    private readonly accountsService: AccountsService,
    private readonly route: ActivatedRoute,
    private readonly _InternalWorksServices: InternalWorksService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.idInternalContact =
      +this.route.snapshot.paramMap.get("idInternalContact");
    this.getLocalizations();
    this.getInternalContacts();
    this.getContactClasses();
    this.getAccounts();
    this.createInternalContactForm();
    this.getStatusJobs();
    if (this.idInternalContact) {
      this.getRecordsXProperties();
      this.getRecordsXJobs();
      this.getRecordsXProjects();
      this.internalProperties = JSON.parse(
        sessionStorage.getItem("internalProperties")
      );
    }
  }

  openConfirmInternalContactDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        title: "Save a New Contact",
        description: "¿Are you sure you want to save a new contact?",
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.managementInternalContact();
      }
    });
  }

  setCities(idState: number) {
    this.filteredCities = this.citiesResponse?.filter(
      (city) => city.department_ID === idState
    );
  }

  setStates(idCountry: number) {
    this.filteredStates = this.statesResponse?.filter(
      (state) => state.country_ID === idCountry
    );
  }

  getFullName() {
    return `${this.internalContactForm.value.firstName} ${this.internalContactForm.value.lastName}`;
  }

  getPropertyId(propertyNumber: string) {
    return this.internalProperties.findIndex(
      (property) => property.property_Number === propertyNumber
    );
  }

  getStatusJobName(idStatusJob: number) {
    const statusJob = this.jobStatusSelect.find(
      (x) => x.id === idStatusJob
    )?.name_Status;
    return statusJob;
  }

  getRouterLink(): void {
    if (this.data.action === "account") {
      this.dialogRef.close(true);
    } else {
      this.router.navigate(["/home/internal-contacts"]);
    }
  }

  private getStatusJobs() {
    this._InternalWorksServices
      .getStatusJobs()
      .subscribe((jobStatus: any[]) => (this.jobStatusSelect = jobStatus));
  }

  private getAccounts() {
    this.spinner.show();
    this.accountsService.getAccounts().subscribe(
      (accounts: AccountModel[]) => {
        this.accounts = accounts;
        this.spinner.hide();
      },
      () => {
        this.toastr.error("Error! Please try again", "Error");
        this.spinner.hide();
      }
    );
  }

  private getRecordsXJobs() {
    this.spinner.show();
    this.internalContactService
      .getRecordsXJobs(this.idInternalContact)
      .subscribe(
        (jobsContact: InternalContactJobModel[]) => {
          const jobs = jobsContact.map((job) => job.idJob);
          this.getContactJobs(jobs);
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
          this.toastr.error("Error! Please try again", "Error");
        }
      );
  }

  private getRecordsXProperties() {
    this.spinner.show();
    this.internalContactService
      .getRecordsXProperties(this.idInternalContact)
      .subscribe(
        (propertiesContact: InternalContactPropertyModel[]) => {
          const properties = propertiesContact.map(
            (property) => property.propertyNumber
          );
          this.getPropertiesContact(properties);
        },
        () => {
          this.spinner.hide();
          this.toastr.error("Error! Please try again", "Error");
        }
      );
  }

  private getRecordsXProjects() {
    this.spinner.show();
    this.internalContactService
      .getRecordsXProjects(this.idInternalContact)
      .subscribe(
        (projectsContact: InternalContactProjectModel[]) => {
          const projects = projectsContact.map(
            (project) => project.projectNumber
          );
          this.getContactProjects(projects);
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
          this.toastr.error("Error! Please try again", "Error");
        }
      );
  }

  private getPropertiesContact(properties: string[]) {
    this.spinner.show();
    this.internalContactService.getContactProperties(properties).subscribe(
      (propertiesContact: PropertyModel[]) => {
        this.internalContactProperties = propertiesContact;
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
        this.toastr.error("Error! Please try again", "Error");
      }
    );
  }

  private getContactJobs(jobs: number[]) {
    this.spinner.show();
    this.internalContactService.getContactJobs(jobs).subscribe(
      (jobs: JobModel[]) => {
        this.internalContactJobs = jobs;
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
        this.toastr.error("Error! Please try again", "Error");
      }
    );
  }

  private getContactProjects(projects: string[]) {
    this.spinner.show();
    this.internalContactService.getContactProjects(projects).subscribe(
      (projects: ProjectResponse[]) => {
        this.internalContactProjects = projects;
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
        this.toastr.error("Error! Please try again", "Error");
      }
    );
  }

  private getContactClasses() {
    this.spinner.show();
    this.masterService.getContactClasses().subscribe(
      (contactClasses: MasterContactClassModel[]) => {
        this.contactClasses = contactClasses;
        this.spinner.hide();
      },
      () => {
        this.toastr.error("Error! Please try again", "Error");
        this.spinner.hide();
      }
    );
  }

  private getInternalContacts() {
    this.spinner.show();
    this.loadForm = true;
    this.internalContactService.getInternalContacts().subscribe(
      (contacts: InternalContactModel[]) => {
        this.spinner.hide();
        this.internalContacts = contacts;
        if (this.idInternalContact) {
          this.internalContact = contacts.find(
            (contact) =>
              contact.idContactInformationRecord === this.idInternalContact
          );
        }
        this.createInternalContactForm();
      },
      () => {
        this.spinner.hide();
        this.toastr.error("Error! Please try again", "Error");
      }
    );
  }

  private getLocalizations() {
    combineLatest([
      this.localizationService.currentCountries,
      this.localizationService.currentStates,
      this.localizationService.currentCities,
    ]).subscribe(([countries, states, cities]) => {
      if (countries !== null && states !== null && cities !== null) {
        this.countriesResponse = countries;
        this.statesResponse = states;
        this.citiesResponse = cities;
      }
    });
  }

  private managementInternalContact(): void {
    this.spinner.show();
    if (!this.idInternalContact) {
      this.internalContactService
        .createInternalContact(this.internalContactForm.value)
        .subscribe(
          (response) => {
            this.spinner.hide();
            if (response["statusCode"] === "428") {
              this.toastr.warning("Contact information record already exist");
            } else {
              this.toastr.success("Contact created successfully");
              if ( this.data?.action === 'jobs' ) {
                this.dialogRef.close(true);
              } else if ( this.data?.action === 'account' ) {
                this.router.navigate(["/home/accounts"]);
              } else {
                this.router.navigate(["/home/internal-contacts"]);
              }
            }
          },
          () => {
            this.spinner.hide();
            this.toastr.error("Error creating user");
          }
        );
    } else {
      this.internalContactService
        .updateInternalContact(this.internalContactForm.value)
        .subscribe(
          () => {
            this.spinner.hide();
            this.toastr.success("Contact updated successfully");
            this.router.navigate(["/home/internal-contacts"]);
          },
          () => {
            this.spinner.hide();
            this.toastr.error("Error updating user");
          }
        );
    }
  }

  private createInternalContactForm() {
    this.internalContactForm = this.formBuilder.group({
      idContactInformationRecord: [
        this.internalContact
          ? this.internalContact.idContactInformationRecord
          : 0,
      ],
      status: [this.internalContact ? this.internalContact.status : true],
      idContactType: [
        this.internalContact ? this.internalContact.idContactType : "",
      ],
      idDocumentType: [
        this.internalContact ? this.internalContact.idDocumentType : "",
      ],
      contactDocumentNumber: [
        this.internalContact ? this.internalContact.contactDocumentNumber : "",
      ],
      firstName: [
        this.internalContact ? this.internalContact.firstName : "",
        Validators.required,
      ],
      lastName: [
        this.internalContact ? this.internalContact.lastName : "",
        Validators.required,
      ],
      title: [this.internalContact ? this.internalContact.title : ""],
      idCompany: [
        this.data?.idCompany
          ? this.data.idCompany
          : this.internalContact?.idCompany
      ],
      address1: [this.internalContact ? this.internalContact.address1 : ""],
      address2: [this.internalContact ? this.internalContact.address2 : ""],
      idCity: [this.internalContact ? this.internalContact.idCity : ""],
      zip: [this.internalContact ? this.internalContact.zip : ""],
      phoneNumber1: [
        this.internalContact ? this.internalContact.phoneNumber1 : "",
        Validators.required,
      ],
      phoneNumber2: [
        this.internalContact ? this.internalContact.phoneNumber2 : "",
        Validators.required,
      ],
      faxNumber: [this.internalContact ? this.internalContact.faxNumber : ""],
      email: [
        this.internalContact ? this.internalContact.email : "",
        Validators.required,
      ],
      classificationContact: [
        this.internalContact ? this.internalContact.classificationContact : "",
      ],
      tableName: [
        this.internalContact ? this.internalContact.tableName : "Contacts",
      ],
      dateUpdate: [
        this.internalContact ? this.internalContact.dateUpdate : new Date(),
      ],
      updateUser: [
        this.internalContact
          ? this.internalContact.updateUser
          : sessionStorage.getItem("email"),
      ],
      updateProgram: [
        this.internalContact
          ? this.internalContact.updateProgram
          : "Production Web App",
      ],
      gidContact: [this.internalContact ? this.internalContact.gidContact : ""],
      guidCountry: [
        this.internalContact ? this.internalContact.guidCountry : "",
      ],
      idCountry: [
        this.internalContact ? this.internalContact.idCountry : "",
        Validators.required,
      ],
      idDepartment: [
        this.internalContact ? this.internalContact.idDepartment : "",
        Validators.required,
      ],
      contactClass: [
        this.internalContact ? this.internalContact.contactClass : "",
        Validators.required,
      ],
      roleName: [this.internalContact ? this.internalContact.roleName : ""],
      idCityNavigation: [null],
      idCompanyNavigation: [null],
      idContactTypeNavigation: [null],
      idCountryNavigation: [null],
      idDepartmentNavigation: [null],
      idDocumentTypeNavigation: [null],
      companyRelationshipContacts: [],
      jobsRelationshipContactInformationRecords: [],
      proJectsRelationshipContactInformationRecords: [],
      projects: [],
      propertiesRelationshipContactInformationRecords: [],
      propertyProjectsJobsRelationshipContactsInformationRecords: [],
    });
    if (this.idInternalContact) {
      this.setStates(this.internalContact?.idCountry);
      this.setCities(this.internalContact?.idDepartment);
    }
    this.loadForm = false;
  }
}
