import { Component, OnInit, ViewChild } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/services/auth/auth.service";
import { loadSideBarConfiguration } from "src/app/utils/utils";
import { AccountsService } from "../../../services/admin-services/accounts/accounts.service";
import { AccountModel } from "src/app/models/accounts/account.model";
import { LocalizationsService } from "../../../services/localizations/localizations.service";
import { combineLatest } from "rxjs";
import { CountryResponse } from "src/app/models/localizations/country.reponse.model";
import { StateResponse } from "src/app/models/localizations/state.response.model";
import { CityResponse } from "src/app/models/localizations/city.response.model";
import { Router } from "@angular/router";
import { BreadcrumbService } from "xng-breadcrumb";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-accounts",
  templateUrl: "./accounts.component.html",
})
export class AccountsComponent implements OnInit {
  @ViewChild(MatPaginator)
  set paginator(pagination: MatPaginator) {
    this.dataSource.paginator = pagination;
  }
  displayedColumns: string[] = [
    "actions",
    "accountName",
    "businessPhone",
    "webSite",
    "parentAccount",
    "country",
    "state",
    "city",
    "address",
    "status",
  ];
  dataSource = new MatTableDataSource<AccountModel>();
  accounts: AccountModel[];
  countriesResponse: CountryResponse[];
  statesResponse: StateResponse[];
  citiesResponse: CityResponse[];
  showAccounts = true;
  type = false;

  constructor(
    private readonly authService: AuthService,
    private readonly accountService: AccountsService,
    private readonly localizationService: LocalizationsService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: ToastrService,
    private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.breadcrumbs$.subscribe((breadCrumbs) => {
      if (breadCrumbs.length == 2 && breadCrumbs[1].label == "Accounts") {
        this.getAccountsCache();
      }
    });
    router.events.subscribe((val) => {
      if (val["url"] === "/home/accounts") {
        this.showAccounts = true;
        if (sessionStorage.getItem("tipo") === "Interno") {
          this.type = true;
        } else {
          this.type = false;
        }
      } else {
        this.showAccounts = false;
      }
    });
  }

  ngOnInit(): void {
    loadSideBarConfiguration("btnMenuAccounts");
    this.getLocalizations();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  logout() {
    this.authService.logout();
  }

  get fullName() {
    return sessionStorage.getItem("nombreCompleto");
  }

  getCountryName(countryId: number) {
    return this.countriesResponse?.find((country) => country.id === countryId)
      ?.country_Name;
  }

  getStateName(stateId: number) {
    return this.statesResponse?.find((state) => state.id === stateId)
      ?.department_Name;
  }

  getCityName(cityId: number) {
    return this.citiesResponse?.find((city) => city.id === cityId)?.city_Name;
  }

  getParentAccountName(idParentAccount: number) {
    return this.accounts?.find(
      (account) => account.idCompany === idParentAccount
    )?.companyName;
  }

  private getAccountsCache() {
    this.spinner.show();
    this.accountService.currentAccounts.subscribe({
      next: (accounts: AccountModel[]) => {
        if (accounts !== null) {
          this.accounts = accounts;
          this.dataSource.data = accounts;
          this.spinner.hide();
        } else {
          this.getAccounts();
        }
      },
    });
  }

  getAccounts() {
    this.spinner.show();
    this.accountService.getAccounts().subscribe(
      (accounts: AccountModel[]) => {
        this.accounts = accounts;
        this.accountService.setAccounts(accounts);
        this.dataSource.data = accounts;
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
        this.toastr.error("Error! Please try again", "Error");
      }
    );
  }

  private getLocalizations() {
    combineLatest([
      this.localizationService.currentCountries,
      this.localizationService.currentStates,
      this.localizationService.currentCities,
    ]).subscribe(([countries, states, cities]) => {
      if (countries !== null && states !== null && cities !== null) {
        this.countriesResponse = countries;
        this.statesResponse = states;
        this.citiesResponse = cities;
      }
    });
  }
}
