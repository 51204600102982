import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirmation-inspection-dialog',
  templateUrl: './confirmation-inspection-dialog.component.html'
})
export class ConfirmationInspectionDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
