import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ProjectResponse } from "src/app/models/projects/project.response.model";
import { ProjectsService } from "src/app/services/admin-services/projects/projects.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { cleanNullAttributes, findByMatchingProperty, loadSideBarConfiguration } from "../../../../../utils/utils";
import { InternalWorksResponse } from "../../../../../models/works/internal.works.response.model";
import { ProjectStatusModel } from "../../../../../models/projects/project-status.model";

@Component({
  selector: "app-internal-projects",
  templateUrl: "./internal-projects.component.html",
})
export class InternalProjectsComponent implements OnInit {
  projects: string;
  filtersForm: UntypedFormGroup;
  projectsResponse: ProjectResponse[] = [];
  filterProjectsResponse: ProjectResponse[] = [];
  filterInternalJobs: InternalWorksResponse[] = [];
  statusProject: string;
  client: string;
  accountOfficer: string;
  minimumFee: number;
  maximumFee: number;
  statusProjectsSelect: ProjectStatusModel[] = [];
  clientSelect = [];
  accountOfficerSelect = [];
  internalJobs: InternalWorksResponse[];

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly projectService: ProjectsService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: ToastrService,
    private readonly authService: AuthService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    if (
      sessionStorage.getItem("projectDirty") === "true" ||
      sessionStorage.getItem("propertyDirty") === "true"
    ) {
      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
        this.router.navigate([window.location.pathname]);
      });
    }
    this.getStatusProjects();
    this.buildFiltersForms();
    this.getUserProjects();
    loadSideBarConfiguration("btnMenuInternalProjects");
    this.internalJobs = JSON.parse(sessionStorage.getItem("internalJobs"));
    this.filterInternalJobs = this.internalJobs;
  }

  getUserProjects() {
    this.spinner.show();
    this.projectService.getProjects().subscribe(
      (resp: ProjectResponse[]) => {
        this.projectsResponse = resp.sort((a, b) => {
          const createdA = new Date(a.created);
          const createdB = new Date(b.created);
          return createdB.getTime() - createdA.getTime();
        });
        sessionStorage.setItem("internalProjects", JSON.stringify(resp));
        this.getUniqueValuesOnchangeSelect();
        this.spinner.hide();
      },
      () => {
        this.toastr.error(
          "Se presento un problema obteniendo los proyectos",
          "Proyectos",
          {
            progressBar: true,
            progressAnimation: "increasing",
          }
        );
        this.spinner.hide();
      }
    );
  }

  buildFiltersForms() {
    this.filtersForm = this.formBuilder.group({
      id_Status_Project: [null],
      client: [null],
      id_Account_Officer: [null],
      minimum_fee: [null],
      maximum_fee: [null],
    });
  }

  getUniqueValuesOnchangeSelect() {
    this.clientSelect = this.projectsResponse
      .map((project) => project.client)
      .filter((value, index, self) => self.indexOf(value) === index)
      .map((client) => ({ client }));

    this.accountOfficerSelect = this.projectsResponse
      .map((project) => {
        return { idAccountOficcer: project.id_Account_Officer, accountOfficer: project.account_officer  }
      })
      .filter((value, index, self) =>
        self.findIndex((t) => t.idAccountOficcer === value.idAccountOficcer) === index);
  }

  filterJobs() {
    this.filterProjectsResponse = JSON.parse(
      sessionStorage.getItem("internalProjects")
    ).map((job) => ({
      ...job,
      created: job.created.split("T")[0],
    }));

    const filterForm = {
      ...this.filtersForm.value,
      id_Status_Project: +this.filtersForm.value.id_Status_Project || undefined,
      id_Account_Officer: +this.filtersForm.value.id_Account_Officer || undefined,
    };

    let filters = cleanNullAttributes(filterForm);
    let resultFilter = findByMatchingProperty(
      this.filterProjectsResponse,
      filters
    );

    this.projectsResponse = resultFilter;

    this.filterInternalJobs = this.internalJobs.filter((job) => {
      const project = this.projectsResponse.find(
        (project) => project.project_Number === job.project_Number
      );
      return project;
    });

  }

  clearFilters() {
    this.filtersForm.patchValue({
      id_Status_Project: undefined,
      client: undefined,
      id_Account_Officer: undefined,
      minimum_fee: undefined,
      maximum_fee: undefined,
    });
    this.projectsResponse = JSON.parse(
      sessionStorage.getItem("internalProjects")
    );
    this.filterInternalJobs = this.internalJobs;
  }

  updateInternalFilterProjects(event) {
    this.projectsResponse = event;
  }

  logout() {
    this.authService.logout();
  }

  get fullName() {
    return sessionStorage.getItem("nombreCompleto");
  }

  private getStatusProjects() {
    this.projectService.getStatusProjects()
      .subscribe((statusProject: ProjectStatusModel[]) => (this.statusProjectsSelect = statusProject));
  }

}
