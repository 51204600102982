import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { InternalTasksService } from "src/app/services/admin-services/tasks/internal-tasks.service";
import { InternalWorksService } from "../../../../../../services/admin-services/works/internal-works.service";
import { ToastrService } from "ngx-toastr";
import { TaskModel } from "src/app/models/tasks/tasks.model";
import { combineLatest } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-edit-task-dialog",
  templateUrl: "./edit-task-dialog.component.html",
})
export class EditTaskDialogComponent implements OnInit {
  taskForm: UntypedFormGroup;
  taskCodes: any = [];
  taskSubTypes: any = [];
  taskCodesFiltered: any = [];
  internalJobs: any = [];
  internalContacts: any = [];
  externalContacts: any = [];
  contacts: any = [];
  projectNumber: string;
  manager: string;
  isIspenctionDate = false;
  isLoadingForm = false;
  startDate: any;
  expirationDate: any;

  constructor(
    private readonly dialogRef: MatDialogRef<EditTaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly internalTasksService: InternalTasksService,
    private readonly internalJobsService: InternalWorksService,
    private readonly toastr: ToastrService,
    private readonly spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getTaskCodes();
    this.getTaskSubTypes();
    this.getJobsNumbers();
    this.getContacts();
  }

  getContacts() {
    combineLatest([
      this.internalJobsService.getInternalContacts(),
      this.internalJobsService.getExternalContacts(),
    ]).subscribe(([internalContacts, externalContacts]) => {
      if (internalContacts !== null && externalContacts !== null) {
        this.internalContacts = internalContacts;
        this.externalContacts = externalContacts;
        this.buildTaskForm();
        this.jobNumberChange();
        this.taskStatusChange();
        this.assignedToChange();
        this.taskSubtypeChange();
        this.taskTypeChange();
        this.taskStartDateChange();
        this.taskExpirationDateChange();
        this.isLoadingForm = true;
      }
    });
  }

  assignedToChange() {
    this.taskForm.get("Assigned_to").valueChanges.subscribe((value) => {
      const assignedToName = this.internalContacts.find(
        (contact) => contact.email === value
      )?.full_Name;
      this.taskForm.get("Assigned_to_Name").setValue(assignedToName);
    });
  }

  taskTypeChange() {
    this.taskForm.get("Task_Type").valueChanges.subscribe((resp) => {
      if (resp === 1) {
        this.contacts = this.internalContacts;
      } else {
        this.contacts = this.externalContacts;
      }
    });
  }

  taskSubtypeChange() {
    this.taskForm.get("Id_Task_SubType").valueChanges.subscribe((subtype) => {
      this.taskCodesFiltered = this.taskCodes.filter(
        (taskCode) => taskCode.id_Task_SubType === +subtype
      );
    });
  }

  taskStartDateChange() {
    this.taskForm.get("Startt_Date").valueChanges.subscribe((startDate) => {
      this.startDate = startDate;
      this.dateRangeValidator();
    });
  }

  taskExpirationDateChange() {
    this.taskForm
      .get("Expiration_Date")
      .valueChanges.subscribe((expirationDate) => {
        this.expirationDate = expirationDate;
        this.dateRangeValidator();
      });
  }

  jobNumberChange() {
    this.taskForm.get("Job_Number").valueChanges.subscribe((jobNumber) => {
      this.projectNumber = this.internalJobs.find(
        (x) => x.job_Number == jobNumber
      )?.project_Number;
      this.manager = this.internalJobs.find(
        (x) => x.job_Number == jobNumber
      ).job_Manager;
      this.taskForm.patchValue({
        Project_Number: this.projectNumber,
        Manager: this.manager,
        Manager_Name: this.manager,
      });
    });
  }

  taskStatusChange() {
    this.taskForm.get("Task_Status").valueChanges.subscribe(() => {
      if (
        this.taskForm.get("Task_Status").value === "Canceled" ||
        this.taskForm.get("Task_Status").value === "Finished"
      ) {
        this.taskForm.get("Comments").setValidators(Validators.required);
        this.taskForm.get("Comments").updateValueAndValidity();
      } else {
        this.taskForm.get("Comments").clearValidators();
        this.taskForm.get("Comments").updateValueAndValidity();
      }
    });
  }

  buildTaskForm() {
    let jobNumber = "";

    if (!this.data.task) {
      jobNumber = this.data.jobNumber;
    } else {
      jobNumber = this.data.task.job_Number;

      if (this.data.task.task_Category === 1) {
        this.isIspenctionDate = true;
      }

      if (this.data.task.task_Type === 1) {
        this.contacts = this.internalContacts;
      } else {
        this.contacts = this.externalContacts;
      }
    }

    this.taskForm = this.formBuilder.group({
      ID: [this.data.task === undefined ? "" : this.data.task.id],
      Task_Name: [this.data.task === undefined ? "" : this.data.task.task_Name],
      Task_Type: [
        this.data.task === undefined ? "" : this.data.task.task_Type,
        Validators.required,
      ],
      Id_Task_SubType: [
        this.data.task === undefined ? "" : +this.data.task.id_Task_SubType,
        Validators.required,
      ],
      Job_Number: [jobNumber, Validators.required],
      Startt_Date: [
        this.data.task === undefined
          ? null
          : new Date(this.data.task?.startt_Date),
        Validators.required,
      ],
      Expiration_Date: [
        this.data.task === undefined
          ? null
          : new Date(this.data.task?.expiration_Date),
        Validators.required,
      ],
      Date_Completed: [
        this.data.task === undefined
          ? new Date()
          : new Date(this.data.task.date_Completed),
      ],
      Assigned_to: [
        this.data.task === undefined ? "" : this.data.task.assigned_to,
        Validators.required,
      ],
      Task_Status: [
        this.data.task === undefined ? 1 : this.data.task?.task_Status,
        Validators.required,
      ],
      Project_Number: [
        this.data.task === undefined ? "" : this.data.task.project_Number,
      ],
      Manager: [this.data.task === undefined ? "" : this.data.task.manager],
      Created: [
        this.data.task === undefined
          ? new Date()
          : new Date(this.data.task.created),
        Validators.required,
      ],
      Modified: [new Date(), Validators.required],
      Assigned_to_Name: [
        this.data.task === undefined ? "" : this.data.task.assigned_to_Name,
      ],
      Manager_Name: [
        this.data.task === undefined ? "" : this.data.task.manager_Name,
      ],
      Comments: [this.data.task === undefined ? "" : this.data.task.comments],
      Task_Category: [
        this.data.task === undefined ? "" : this.data.task.task_Category,
        Validators.required,
      ],
      Inspection_Date: [
        this.data.task === undefined
          ? new Date()
          : new Date(this.data.task.inspection_Date),
      ],
    });

    if (this.data?.task?.id_Task_SubType) {
      this.taskCodesFiltered = this.taskCodes.filter(
        (taskCode) =>
          taskCode.id_Task_SubType === +this.data.task.id_Task_SubType
      );
    }
  }

  getTaskSubTypes() {
    this.internalTasksService.getTaskSubTypes().subscribe((resp) => {
      this.taskSubTypes = resp;
    });
  }

  getTaskCodes() {
    this.taskCodes = this.data.taskCodes;
    this.taskCodesFiltered = this.data.taskCodes;
  }

  getJobsNumbers() {
    this.internalJobs = JSON.parse(sessionStorage.getItem("internalJobs"));
  }

  createUpdateTask() {
    this.spinner.show();
    if (this.data.action === "create") {
      let taskCategoryName = this.data.taskCategory.find(
        (taskCategory) => taskCategory.id === this.taskForm.value.Task_Category
      ).name_Category;
      this.taskForm.removeControl("ID");
      this.taskForm
        .get("Id_Task_SubType")
        .setValue(+this.taskForm.value.Id_Task_SubType);
      this.taskForm.get("Task_Name").setValue(taskCategoryName);
      this.internalTasksService.createTask(this.taskForm.value).subscribe(
        () => {
          this.toastr.success(
            "The task has been created successfully",
            "Create Task",
            {
              progressBar: true,
              progressAnimation: "increasing",
            }
          );
          this.closeDialog(true);
          this.spinner.hide();
        },
        () => {
          this.toastr.error("Error! Please try again.", "Error", {
            progressBar: true,
            progressAnimation: "increasing",
          });
          this.spinner.hide();
        }
      );
    } else {
      this.taskForm
        .get("Id_Task_SubType")
        .setValue(+this.taskForm.value.Id_Task_SubType);
      this.internalTasksService.updateTask(this.taskForm.value).subscribe(
        () => {
          this.toastr.success(
            "The Task has been updated successfully",
            "Update Task",
            {
              progressBar: true,
              progressAnimation: "increasing",
            }
          );
          this.closeDialog(true);
          this.spinner.hide();
        },
        () => {
          this.toastr.error("Error! Please try again.", "Error", {
            progressBar: true,
            progressAnimation: "increasing",
          });
          this.spinner.hide();
        }
      );
    }
  }

  closeDialog(reload: boolean = false) {
    this.dialogRef.close(reload);
  }

  getInternalContacts() {
    this.internalJobsService
      .getInternalContacts()
      .subscribe(
        (internalContacts) => (this.internalContacts = internalContacts)
      );
  }

  getExternalContacs() {
    this.internalJobsService
      .getExternalContacts()
      .subscribe(
        (externalContacts) => (this.externalContacts = externalContacts)
      );
  }

  getTasksByUser() {
    this.internalTasksService.getTasksByUser().subscribe(
      (response: TaskModel[]) => {
        sessionStorage.setItem("tasks", JSON.stringify(response));
      },
      () => {
        this.toastr.error(
          "Se ha presentado un problema cargando las tareas",
          "Tareas",
          {
            progressBar: true,
            progressAnimation: "increasing",
          }
        );
      }
    );
  }

  private dateRangeValidator() {
    let difference = this.startDate.diff(this.expirationDate);
    if (this.startDate && this.expirationDate && difference > 0) {
      this.toastr.warning('The "Start Date" must be less than the "End Date"');
    }
  }
}
