import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PropertieResponse } from 'src/app/models/properties/properties.response.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PropertiesService {

  protected properties = new BehaviorSubject<PropertieResponse[]>(null);
  currentPropiertes = this.properties.asObservable();

  private headers = { headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
  })};

  constructor(
    public readonly http: HttpClient
  ) { }

  getAllProperties() {
    let body = sessionStorage.getItem("numberProjects");
    return this.http.post(`${environment.allProperties}`, body, this.headers);
  }

  getPropertieByProjectNumber(projectNumber: string): Observable<any> {
    return this.http.get(`${environment.properties}?numeroProyecto=${projectNumber}`, this.headers);
  }

  updatePropertie(propertie: any) {
    return this.http.put(environment.updateProperty, propertie, this.headers);
  }

  getRelationDocuments(projectNumber: string, propertieNumber: string): Observable<any> {
    return this.http.get(`${environment.relationDocuments}?numeroProyecto=${projectNumber}&numeroPropiedad=${propertieNumber}`, this.headers);
  }

  getAdvances(projectNumber: string, propertieNumber: string) {
    return this.http.get(`${environment.advances}?numeroProyecto=${projectNumber}&numeroPropiedad=${propertieNumber}`, this.headers);
  }

  deleteRelationDocuments(deleteDocument: any): Observable<any> {
    return this.http.post(environment.deleteRelationDocuments, deleteDocument, this.headers);
  }

  uploadRelationDocument(document: any): Observable<any> {
    return this.http.post(environment.uploadDocument, document, this.headers);
  }

  updateRelationDocument(document: any): Observable<any> {
    return this.http.post(environment.updateRelationDocumetns, document, this.headers);
  } 

  viewDocument(urlDocument: any): Observable<any> {
    return this.http.post(environment.viewDocument, JSON.stringify(urlDocument), this.headers);
  }

  setProperties(works: PropertieResponse[]): void {
    this.properties.next(works);
  }

}
