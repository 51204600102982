import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { InternalWorksService } from "../../../../../services/admin-services/works/internal-works.service";
import { ToastrService } from "ngx-toastr";
import { InternalWorksResponse } from "../../../../../models/works/internal.works.response.model";
import {
  cleanNullAttributes,
  findByMatchingProperties,
  loadSideBarConfiguration,
} from "../../../../../utils/utils";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "src/app/services/auth/auth.service";
import { Router } from "@angular/router";
import {
  AdvancedSettingsService
} from "../../../../../services/admin-services/advanced-settings/advanced-settings.service";
import { AdvancedSettingsCityModel } from "../../../../../models/advanced-settings/city.model";
import { AdvancedSettingsCountryModel } from "../../../../../models/advanced-settings/country.model";
import { map } from "rxjs/operators";
import { ProjectResponse } from "../../../../../models/projects/project.response.model";
import { PropertieResponse } from "../../../../../models/properties/properties.response.model";
import { PropertiesService } from "../../../../../services/admin-services/properties/properties.service";
import { ProjectsService } from "../../../../../services/admin-services/projects/projects.service";

@Component({
  selector: "app-internal-works",
  templateUrl: "./internal-works.component.html",
})
export class InternalWorksComponent implements OnInit {
  @ViewChild("startDate") resetStartDate: ElementRef;
  @ViewChild("endDate") resetEndDate: ElementRef;

  filtersForm: UntypedFormGroup;
  internalJobsResponse: InternalWorksResponse[] = [];
  filterInternalJobs: InternalWorksResponse[] = [];
  internalJobsSpecificStatus: InternalWorksResponse[] = [];
  date = new Date();
  country: string;
  city: string;
  countrySelect: AdvancedSettingsCountryModel[] = [];
  citySelect: AdvancedSettingsCityModel[] = [];
  jobs: string;
  startDateValue = null;
  endDateValue = null;
  jobStatusSelect: any[] = [];
  internalProperties = [];
  numberProjects: string[] = [];

  constructor(
    private readonly internalWorksServices: InternalWorksService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly toastr: ToastrService,
    private readonly spinner: NgxSpinnerService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly advancedSettingsService: AdvancedSettingsService,
    private readonly properties: PropertiesService,
    private readonly projects: ProjectsService,
  ) {}

  ngOnInit(): void {
    this.getCountries();
    this.getCities();
    this.getProjects();
    if (sessionStorage.getItem("jobDirty") === "true") {
      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
        this.router.navigate([window.location.pathname]);
      });
    }
    this.getStatusJobs();
    this.buildFiltersForms();
    this.internalProperties = JSON.parse(sessionStorage.getItem("internalProperties"));
    loadSideBarConfiguration("btnMenuInternalJobs");
  }

  getStatusJobs() {
    this.internalWorksServices
      .getStatusJobs()
      .subscribe((jobStatus: any[]) => (this.jobStatusSelect = jobStatus));
  }

  buildFiltersForms() {
    this.filtersForm = this.formBuilder.group({
      iD_Job_Status: [[]],
      country: [null],
      city: [null],
    });
  }

  getJobsRol() {
    this.spinner.show();
    this.internalWorksServices.getJobsRol()
      .pipe(map((resp: InternalWorksResponse[]) => {
        return resp.map((job) => ({
          ...job,
          city: this.internalProperties.find((property) => property.property_Number === job.property_Number)?.city,
          country: this.internalProperties.find((property) => property.property_Number === job.property_Number)?.country
        }));
      }))
      .subscribe(
      (resp: InternalWorksResponse[]) => {
        this.internalJobsResponse = resp
          .sort(
            (a, b) => Number(moment(a.due_Date)) - Number(moment(b.due_Date))
          )
          .reverse();
        this.internalJobsSpecificStatus = resp.filter((job) =>
          job.iD_Job_Status === 1 || job.iD_Job_Status === 3 || job.iD_Job_Status === 4 || job.iD_Job_Status === 7
        ).sort(
          (a, b) => Number(moment(a.due_Date)) - Number(moment(b.due_Date))
        ).reverse();
        sessionStorage.setItem("internalJobs", JSON.stringify(resp));
        this.spinner.hide();
      },
      () => {
        this.toastr.error(
          "Se han presentando inconvenientes al obtener los datos de los jobs",
          "Obteniendo datos",
          {
            progressBar: true,
            progressAnimation: "increasing",
          }
        );
        this.spinner.hide();
      }
    );
  }

  filterJobs() {
    const startDate = this.startDate;
    const endDate = this.endDate;

    this.filterInternalJobs = JSON.parse(
      sessionStorage.getItem("internalJobs")
    ).map((job) => ({
      ...job,
      due_Date: job.due_Date.split("T")[0],
    }));

    if (this.startDateValue && this.endDateValue) {
      this.filterInternalJobs = this.filterInternalJobs.filter(
        (job: InternalWorksResponse) =>
          job.due_Date >= startDate && job.due_Date <= endDate
      );
    }

    const filters = cleanNullAttributes(this.filtersForm.value);
    this.internalJobsResponse = findByMatchingProperties(
      this.filterInternalJobs,
      filters
    );

    this.internalJobsSpecificStatus = this.internalJobsResponse;

  }

  clearFilters() {
    this.startDateValue = undefined;
    this.endDateValue = undefined;
    this.filtersForm.patchValue({
      iD_Job_Status: undefined,
      country: undefined,
      city: undefined,
    });

    this.internalJobsResponse = JSON.parse(
      sessionStorage.getItem("internalJobs")
    );

    this.internalJobsSpecificStatus = this.internalJobsResponse.filter((job) =>
      job.iD_Job_Status === 1 || job.iD_Job_Status === 3 || job.iD_Job_Status === 4 || job.iD_Job_Status === 7
    ).sort(
      (a, b) => Number(moment(a.due_Date)) - Number(moment(b.due_Date))
    ).reverse();
  }

  updateInternalFilterJobs(event) {
    this.internalJobsResponse = event;
  }

  logout() {
    this.authService.logout();
  }

  get fullName() {
    return sessionStorage.getItem("nombreCompleto");
  }

  get startDate() {
    return moment(this.startDateValue).format("YYYY-MM-DD");
  }

  get endDate() {
    return moment(this.endDateValue).format("YYYY-MM-DD");
  }

  private getProjects() {
    this.projects.getProjects().subscribe( (resp: ProjectResponse[]) => {
      resp.forEach(project => {
        this.numberProjects.push(project.project_Number);
      });
      sessionStorage.setItem("numberProjects", JSON.stringify(this.numberProjects));
      this.projects.setProjects(resp);
      this.getInternalProperties();
    })
  }

  private getInternalProperties() {
    this.properties.getAllProperties().subscribe( (resp: PropertieResponse[]) => {
      sessionStorage.setItem("internalProperties", JSON.stringify(resp));
      this.internalProperties = JSON.parse(sessionStorage.getItem("internalProperties"));
      this.getJobsRol();
    });
  }

  private getCities() {
    this.advancedSettingsService.getCities().subscribe((cities) => {
      this.citySelect = cities;
    });
  }

  private getCountries() {
    this.advancedSettingsService.getCountries().subscribe((countries) => {
      this.countrySelect = countries;
    });
  }
}
