import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { FileUploadValidators } from "@iplab/ngx-file-upload";
import { NgxSpinnerService } from "ngx-spinner";
import { PropertiesService } from "src/app/services/admin-services/properties/properties.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-upload-document",
  templateUrl: "./upload-document.component.html",
})
export class UploadDocumentComponent implements OnInit {
  @Input() relationalDocument: any;

  private filesControl = new UntypedFormControl(
    null,
    FileUploadValidators.filesLimit(1)
  );

  public fileForm = new UntypedFormGroup({
    files: this.filesControl,
  });

  showModal = false;
  toggleModal() {
    this.showModal = !this.showModal;
  }

  constructor(
    private readonly _propertiesService: PropertiesService,
    private readonly spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {}

  saveDocument() {
    let body = {
      NombreDocumento: "",
      DocumentoCarga: "",
      Proyecto: this.relationalDocument.project_Number,
      Propiedad: this.relationalDocument.property,
      IdDocumentoProyecto: 0,
      UrlDocumentoRelacionado: "",
    };
    if (this.fileForm.get("files").value) {
      this.spinner.show();
      const reader = new FileReader();
      reader.readAsDataURL(this.fileForm.get("files").value[0]);
      reader.onload = () => {
        body.DocumentoCarga = reader.result.toString().split(",")[1];
        body.NombreDocumento = this.fileForm.get("files").value[0].name;
        this._propertiesService.uploadRelationDocument(body).subscribe(
          (res) => {
            let bodyUpdate = {
              ID: this.relationalDocument.id,
              Document_Name: this.relationalDocument.document_Name,
              Document_Category: this.relationalDocument.document_Category,
              Project_Number: this.relationalDocument.project_Number,
              Property: this.relationalDocument.property,
              Document_Upload: true,
              Document_url: res.UrlDocumentoRelacionado,
              Created: this.relationalDocument.created,
              Id_RelacionDocumentoSH: res.IdDocumentoProyecto,
            };
            this._propertiesService
              .updateRelationDocument(bodyUpdate)
              .subscribe((resp) => {
                if (resp) {
                  this.spinner.hide();
                } else {
                  this.spinner.hide();
                  Swal.fire({
                    title: "Error!",
                    text: "Se presentó un inconveniente al actualizar el documento",
                    icon: "error",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "OK",
                  });
                }
              });
            this.spinner.hide();
          },
          () => {
            this.spinner.hide();
            Swal.fire({
              title: "Error!",
              text: "Se presentó un inconveniente al subir el documento",
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
          }
        );
      };
    }
  }
}
