import { Injectable } from "@angular/core";
import { CanDeactivate, Router } from "@angular/router";

export interface CanComponentDeactivate {
  validateForm(): Promise<boolean>;
}

@Injectable()
export class PreventChangeRouteGuard
  implements CanDeactivate<CanComponentDeactivate>
{
  constructor(private readonly router: Router) {}

  canDeactivate(component: CanComponentDeactivate) {
    return component?.validateForm().then((resp) => {
      if (resp) {
        return false;
      } else {
        return true;
      }
    });
  }
}
