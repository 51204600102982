import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TaskModel } from "../../../../../models/tasks/tasks.model";
import { MatDialog } from "@angular/material/dialog";
import { EditTaskDialogComponent } from "./edit-task-dialog/edit-task-dialog.component";

@Component({
  selector: "app-internal-table-tasks",
  templateUrl: "./internal-table-tasks.component.html",
})
export class InternalTableTasksComponent implements OnInit {
  filterTable: string;
  internalProperties = [];
  @Input() taskStatus: any[];
  @Input() taskCategory: any[];
  @Input() taskType: any[];
  @Input() taskCodes: any[];

  @Output() updateInternalFilterTasks = new EventEmitter<any>();
  @Input() tasks: TaskModel[];
  @Input() set filter(filter: string) {
    this.filterByWord(filter);
  }

  constructor(private readonly dialog: MatDialog) {}

  ngOnInit(): void {
    this.internalProperties = JSON.parse(
      sessionStorage.getItem("internalProperties")
    );
  }

  getStatusName(status: number) {
    return this.taskStatus?.find((x) => x.id === status)?.name_Status;
  }

  getTypeName(type: number) {
    return this.taskType?.find((x) => x.id === type)?.name_Option;
  }

  getCategoryName(category: number) {
    return this.taskCodes?.find((x) => x.id === category)?.name_Code;
  }

  filterByWord(filter: string) {
    this.filterTable = filter;
    this.tasks = JSON.parse(sessionStorage.getItem("tasks"));
    var internalTasksFiltered = this.filterByValue(this.tasks, filter);
    this.updateInternalFilterTasks.emit(internalTasksFiltered);
  }

  filterByValue(array, string) {
    return array?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k])?.toLowerCase().includes(string?.toLowerCase())
      )
    );
  }

  openEditTaskDialog(task?: TaskModel) {
    const dialogRef = this.dialog.open(EditTaskDialogComponent, {
      autoFocus: false,
      disableClose: false,
      data: {
        task,
        taskStatus: this.taskStatus,
        taskCategory: this.taskCategory,
        taskType: this.taskType,
        taskCodes: this.taskCodes,
        action: "edit",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        window.location.reload();
      }
    });
  }

  getPropertyName(task: TaskModel) {
    return this.internalProperties?.find(
      (x) => x.project_Number === task.project_Number
    )?.property_Name;
  }

  getIndexProperty(task: TaskModel) {
    return this.internalProperties.findIndex(
      (x) => x.property_Number === task["property_Number"]
    );
  }
}
