import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ConfirmDialogComponent } from "src/app/components/confirm-dialog/confirm-dialog.component";
import { AdvancedSettingsService } from "src/app/services/admin-services/advanced-settings/advanced-settings.service";

@Component({
  selector: "app-manage-features-dialog",
  templateUrl: "./manage-features-dialog.component.html",
})
export class ManageFeaturesDialogComponent implements OnInit {
  featureForm: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<ManageFeaturesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly _advancedSettingsService: AdvancedSettingsService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: ToastrService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.createFeatureForm();
  }

  openConfirmFeatureDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        title: "Save a New Feature",
        description: "¿Are you sure you want to save a new feature?",
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.managementFeature();
      }
    });
  }

  private managementFeature(): void {
    this.spinner.show();
    if (this.data.action === "create") {
      this._advancedSettingsService
        .createFeature(this.featureForm.value)
        .subscribe(
          () => {
            this.spinner.hide();
            this.toastr.success("Feature created successfully");
            this.dialogRef.close(true);
          },
          () => {
            this.spinner.hide();
            this.toastr.error("Error creating role");
          }
        );
    } else {
      this._advancedSettingsService
        .updateFeature(this.featureForm.value)
        .subscribe(
          () => {
            this.spinner.hide();
            this.toastr.success("Feature updated successfully");
            this.dialogRef.close(true);
          },
          () => {
            this.spinner.hide();
            this.toastr.error("Error updating role");
          }
        );
    }
  }

  private createFeatureForm(): void {
    this.featureForm = this.formBuilder.group({
      Id: [this.data.feature ? this.data.feature.id : 0],
      FeatureName: [
        this.data.feature ? this.data.feature.featureName : "",
        Validators.required,
      ],
      Created: [this.data.feature ? this.data.feature.created : new Date()],
      Status: [this.data.feature ? this.data.feature.status : true],
    });
  }
}
