import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { Observable } from "rxjs";
import { AdvancedSettingsCityModel } from "../../../models/advanced-settings/city.model";
import { AdvancedSettingsCountryModel } from "../../../models/advanced-settings/country.model";

@Injectable({
  providedIn: "root",
})
export class AdvancedSettingsService {
  private headers = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    }),
  };

  constructor(public readonly http: HttpClient) {}

  getContacts() {
    return this.http.get(
      environment.advancedSettings.contacts.get,
      this.headers
    );
  }

  createContact(data: any) {
    return this.http.post(
      environment.advancedSettings.contacts.create,
      data,
      this.headers
    );
  }

  updateContact(data: any) {
    return this.http.put(
      environment.advancedSettings.contacts.update,
      data,
      this.headers
    );
  }

  getRoles() {
    return this.http.get(environment.advancedSettings.roles.get, this.headers);
  }

  getAllRoles() {
    return this.http.get(
      environment.advancedSettings.roles.getAll,
      this.headers
    );
  }

  getRolesFeatures() {
    return this.http.get(
      environment.advancedSettings.features.get,
      this.headers
    );
  }

  getAllFeatures() {
    return this.http.get(
      environment.advancedSettings.features.getAll,
      this.headers
    );
  }

  createFeature(data: any) {
    return this.http.post(
      environment.advancedSettings.features.create,
      data,
      this.headers
    );
  }

  updateFeature(data: any) {
    return this.http.put(
      environment.advancedSettings.features.update,
      data,
      this.headers
    );
  }

  createRol(data: any) {
    return this.http.post(
      environment.advancedSettings.roles.create,
      data,
      this.headers
    );
  }

  updateRol(data: any) {
    return this.http.put(
      environment.advancedSettings.roles.update,
      data,
      this.headers
    );
  }

  getCities(): Observable<AdvancedSettingsCityModel[]> {
    return this.http.get<AdvancedSettingsCityModel[]>(
      environment.advancedSettings.dropdowns.city,
      this.headers
    );
  }

  getCountries(): Observable<AdvancedSettingsCountryModel[]> {
    return this.http.get<AdvancedSettingsCountryModel[]>(
      environment.advancedSettings.dropdowns.countries,
      this.headers
    );
  }

  getSubtypes() {
    return this.http.get(
      environment.advancedSettings.dropdowns.subtypes,
      this.headers
    );
  }

  getTypes() {
    return this.http.get(
      environment.advancedSettings.dropdowns.types,
      this.headers
    );
  }

  getUserInfoGraph(email: string) {
    return this.http.get(environment.masters.userInfoGraph, {
      params: { email },
      ...this.headers,
    });
  }

  sendEmail(data: any) {
    return this.http.post(
      environment.advancedSettings.contacts.sendEmail,
      data,
      this.headers
    );
  }

  getRulesJobs() {
    return this.http.get(environment.advancedSettings.rules.get, this.headers);
  }

  createRuleJob(data: any) {
    return this.http.post(
      environment.advancedSettings.rules.create,
      data,
      this.headers
    );
  }

  updateRuleJob(data: any) {
    return this.http.put(
      environment.advancedSettings.rules.update,
      data,
      this.headers
    );
  }

  getUsersTenant() {
    return this.http.get(environment.masters.userTenant, this.headers);
  }
}
