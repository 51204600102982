import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ConfirmDialogComponent } from "src/app/components/confirm-dialog/confirm-dialog.component";
import { AdvancedSettingsService } from "src/app/services/admin-services/advanced-settings/advanced-settings.service";

@Component({
  selector: "app-manage-rule-jobs",
  templateUrl: "./manage-rule-jobs.component.html",
})
export class ManageRuleJobsComponent implements OnInit {
  ruleJobForm: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<ManageRuleJobsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly _advancedSettingsService: AdvancedSettingsService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: ToastrService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.createRuleJobForm();
  }

  openConfirmRuleJobDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        title: "Save a New Rule Job",
        description: "¿Are you sure you want to save a new rule job?",
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.managementRuleJob();
      }
    });
  }

  private managementRuleJob(): void {
    this.spinner.show();
    this.ruleJobForm.patchValue({
      CountryName: this.data.countries.find(
        (country) => country.id === this.ruleJobForm.value.CountryID
      ).countryName,
      BusinessUnit: this.data.businessUnits.find(
        (businessUnit) =>
          businessUnit.id == this.ruleJobForm.value.BusinessUnitID
      ).nameUnit,
      SectorName: this.data.sectors.find(
        (sector) => sector.id == this.ruleJobForm.value.SectorID
      )?.name,
      JobManagerName: this.data.internalContacts.find(
        (contact) => contact.id == this.ruleJobForm.value.JobManagerID
      ).full_Name,
      JobManagerEmail: this.data.internalContacts.find(
        (contact) => contact.id == this.ruleJobForm.value.JobManagerID
      ).email,
      ProductionAdminName: this.data.internalContacts.find(
        (contact) => contact.id == this.ruleJobForm.value.ProductionAdminID
      ).full_Name,
      ProductionAdminEmail: this.data.internalContacts.find(
        (contact) => contact.id == this.ruleJobForm.value.ProductionAdminID
      ).email,
    });
    if (this.data.action === "create") {
      this._advancedSettingsService
        .createRuleJob(this.ruleJobForm.value)
        .subscribe(
          () => {
            this.spinner.hide();
            this.toastr.success("Rule Job created successfully");
            this.dialogRef.close(true);
          },
          () => {
            this.spinner.hide();
            this.toastr.error("Error creating role");
          }
        );
    } else {
      this._advancedSettingsService
        .updateRuleJob(this.ruleJobForm.value)
        .subscribe(
          () => {
            this.spinner.hide();
            this.toastr.success("Rule Job updated successfully");
            this.dialogRef.close(true);
          },
          () => {
            this.spinner.hide();
            this.toastr.error("Error updating role");
          }
        );
    }
  }

  private createRuleJobForm(): void {
    this.ruleJobForm = this.formBuilder.group({
      Id: [this.data.ruleJob ? this.data.ruleJob.id : 0],
      CountryName: [this.data.ruleJob ? this.data.ruleJob.countryName : ""],
      CountryID: [
        this.data.ruleJob ? this.data.ruleJob.countryId : "",
        Validators.required,
      ],
      BusinessUnit: [this.data.ruleJob ? this.data.ruleJob.businessUnit : ""],
      BusinessUnitID: [
        this.data.ruleJob ? this.data.ruleJob.businessUnitId : "",
        Validators.required,
      ],
      SectorName: [this.data.ruleJob ? this.data.ruleJob.sectorName : ""],
      SectorID: [this.data.ruleJob ? this.data.ruleJob.sectorId : null],
      JobManagerName: [
        this.data.ruleJob ? this.data.ruleJob.jobManagerName : "",
      ],
      JobManagerID: [
        this.data.ruleJob ? this.data.ruleJob.jobManagerId : "",
        Validators.required,
      ],
      JobManagerEmail: [
        this.data.ruleJob ? this.data.ruleJob.jobManagerEmail : "",
      ],
      ProductionAdminName: [
        this.data.ruleJob ? this.data.ruleJob.productionAdminName : "",
      ],
      ProductionAdminID: [
        this.data.ruleJob ? this.data.ruleJob.productionAdminId : "",
        Validators.required,
      ],
      ProductionAdminEmail: [
        this.data.ruleJob ? this.data.ruleJob.productionAdminEmail : "",
      ],
      Status: [true, Validators.required],
    });
  }
}
