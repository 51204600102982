import { Component, OnInit, ViewChild } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/services/auth/auth.service";
import { loadSideBarConfiguration } from "src/app/utils/utils";
import { InternalContactsService } from "../../../services/admin-services/internal-contacts/internal-contacts.service";
import { Router } from "@angular/router";
import { InternalContactModel } from "../../../models/internal-contacts/internal-contact.model";
import { AccountsService } from "src/app/services/admin-services/accounts/accounts.service";
import { AccountModel } from "src/app/models/accounts/account.model";
import { combineLatest } from "rxjs";
import { LocalizationsService } from "src/app/services/localizations/localizations.service";
import { CountryResponse } from "src/app/models/localizations/country.reponse.model";
import { StateResponse } from "src/app/models/localizations/state.response.model";
import { CityResponse } from "src/app/models/localizations/city.response.model";
import { BreadcrumbService } from "xng-breadcrumb";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";

@Component({
  selector: "app-internal-contacts",
  templateUrl: "./internal-contacts.component.html",
})
export class InternalContactsComponent implements OnInit {
  @ViewChild(MatPaginator)
  set paginator(pagination: MatPaginator) {
    this.dataSource.paginator = pagination;
  }
  displayedColumns: string[] = [
    "actions",
    "firstName",
    "lastName",
    "contactClass",
    "jobTitle",
    "accountName",
    "email",
    "businessPhone",
    "mobilePhone",
    "country",
    "status",
  ];
  dataSource = new MatTableDataSource<InternalContactModel>();
  internalContacts: InternalContactModel[];
  accounts: AccountModel[];
  countriesResponse: CountryResponse[];
  statesResponse: StateResponse[];
  citiesResponse: CityResponse[];
  showInternalContacts = true;
  type = false;

  constructor(
    private readonly authService: AuthService,
    private readonly internalContactService: InternalContactsService,
    private readonly accountService: AccountsService,
    private readonly localizationService: LocalizationsService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: ToastrService,
    private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.breadcrumbs$.subscribe((breadCrumbs) => {
      if (breadCrumbs.length == 2 && breadCrumbs[1].label == "Contacts") {
        this.getInternalContactsCache();
      }
    });
    router.events.subscribe((val) => {
      if (val["url"] === "/home/internal-contacts") {
        this.showInternalContacts = true;
        this.type = sessionStorage.getItem( "tipo" ) === "Interno";
      } else {
        this.showInternalContacts = false;
      }
    });
  }

  ngOnInit(): void {
    loadSideBarConfiguration("btnMenuInternalContacts");
    this.getAccounts();
    this.getLocalizations();
  }

  getInternalContacts() {
    this.spinner.show();
    this.internalContactService.getInternalContacts().subscribe(
      (contacts: InternalContactModel[]) => {
        this.internalContacts = contacts;
        this.internalContactService.setInternalContacts(contacts);
        this.dataSource.data = contacts;
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
        this.toastr.error("Error! Please try again", "Error");
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  logout() {
    this.authService.logout();
  }

  get fullName() {
    return sessionStorage.getItem("nombreCompleto");
  }

  getCountryName(countryId: number) {
    return this.countriesResponse?.find((country) => country.id === countryId)
      ?.country_Name;
  }

  getAccountName(id: number) {
    const account = this.accounts?.find((x) => x.idCompany === id);
    return account ? account.companyName : "";
  }

  private getLocalizations() {
    combineLatest([
      this.localizationService.currentCountries,
      this.localizationService.currentStates,
      this.localizationService.currentCities,
    ]).subscribe(([countries, states, cities]) => {
      if (countries !== null && states !== null && cities !== null) {
        this.countriesResponse = countries;
        this.statesResponse = states;
        this.citiesResponse = cities;
      }
    });
  }

  private getAccounts() {
    this.spinner.show();
    this.accountService.getAccounts().subscribe(
      (accounts: AccountModel[]) => {
        this.spinner.hide();
        this.accounts = accounts;
      },
      () => {
        this.spinner.hide();
        this.toastr.error("Error! Please try again", "Error");
      }
    );
  }

  private getInternalContactsCache() {
    this.spinner.show();
    this.internalContactService.currentInternalContacts.subscribe({
      next: (contacts: InternalContactModel[]) => {
        if (contacts !== null) {
          this.internalContacts = contacts;
          this.dataSource.data = contacts;
          this.spinner.hide();
        } else {
          this.getInternalContacts();
        }
      },
    });
  }

}
