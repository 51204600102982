import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-works",
  templateUrl: "./works.component.html",
})
export class WorksComponent implements OnInit {
  showWorks = true;
  type = false;

  constructor(private readonly router: Router) {
    router.events.subscribe((val) => {
      if (val["url"] === "/home/jobs") {
        this.showWorks = true;

        if (sessionStorage.getItem("tipo") === "Interno") {
          this.type = true;
        } else {
          this.type = false;
        }
      } else {
        this.showWorks = false;
      }
    });
  }

  ngOnInit(): void {}
}
