import { Component, Input, OnInit, ViewChild } from '@angular/core';

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexResponsive,
  ApexXAxis,
  ApexLegend,
  ApexFill,
  ApexTooltip,
  ApexYAxis,
} from 'ng-apexcharts';
import { InternalWorksResponse } from 'src/app/models/works/internal.works.response.model';
import * as moment from 'moment';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  legend: ApexLegend;
  fill: ApexFill;
  tooltip: ApexTooltip;
};
@Component({
  selector: 'app-graphics',
  templateUrl: './graphics.component.html',
})
export class GraphicsComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  jobsList = [];

  @Input() set internalJobs(jobs: InternalWorksResponse[]) {
    if (jobs?.length > 0) {

      const jobsFormatedDate = jobs
      .map((job) => ({
        ...job,
        due_Date: moment(job.due_Date, 'YYYY/MM/DD')
          .format('MMM')
          .concat(' ')
          .concat(moment(job.due_Date, 'YYYY/MM/DD').format('YYYY')),
      }));

      this.months.forEach((month) => {
        const exists = jobsFormatedDate.find(
          (category) => category.due_Date.substring(0, 3) === month
        );
        if (!exists) {
          jobsFormatedDate.push({
            account_Officer: '',
            address: '',
            business_Unit: '',
            city: '',
            country: '',
            created: '',
            currency: '',
            deliverable: '',
            department: '',
            due_Date: month + ' ' + new Date().getFullYear(),
            email_Account_Officer: '',
            equivalent_USD: 0,
            external_Fees: 0,
            id: 0,
            internal_fees: 0,
            job_Comments: '',
            job_Manager: '',
            job_Number: '',
            job_Status: '',
            iD_Job_Status: 0,
            job_type: '',
            latitude: '',
            longitude: '',
            name_Job_Manager: '',
            phone_Account_Officer: '',
            project_Number: '',
            property_Name: '',
            property_Number: '',
            sector: '',
            zipCode: 0,
            account: '',
            Id_property_Contact: 0,
            Id_Inspection_Contact: 0,
            Valuation_Scenarios: "",
            Interest_Valued: "",
            Type_of_Value: "",
            Inspection_Required: "",
            Days_to_Deliver: 0
          });
        }
      });

      jobsFormatedDate.sort((a, b) => Number(moment(a.due_Date)) - Number(moment(b.due_Date)));

      this.jobsList = jobs;
      this.settingsChart(jobsFormatedDate);
    }
  }

  months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  constructor() {}

  ngOnInit(): void {}

  settingsChart(jobs: InternalWorksResponse[]) {
    this.chartOptions = {
      series: this.getSeries(jobs),
      chart: {
        type: 'bar',
        height: 470,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        type: 'category',
        categories: this.getCategories(jobs),
      },
      yaxis: {
        title: {
          text: '$ (USD)',
          style: {
            fontWeight: 'bold',
          },
        },
        labels: {
          formatter (val) {
            return '$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          },
        },
      },
      legend: {
        position: 'bottom',
        itemMargin: {
          vertical: 3,
        },
        markers: {
          fillColors: [
            '#415AA8',
            '#1A468D',
            '#254382',
            '#51737F',
            '#0080C8',
            '#03B1E5',
            '#ABC8E6',
            '#5D6770',
            '#FE0000',
            '#FD4600',
            '#FF6D3E',
            '#FAA21C',
            '#B4B5B7',
            '#FDE3C0',
          ],
        },
      },
      fill: {
        colors: [
          '#415AA8',
          '#1A468D',
          '#254382',
          '#51737F',
          '#0080C8',
          '#03B1E5',
          '#ABC8E6',
          '#5D6770',
          '#FE0000',
          '#FD4600',
          '#FF6D3E',
          '#FAA21C',
          '#B4B5B7',
          '#FDE3C0',
        ],
        opacity: 1,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter (val) {
            return (
              '$' +
              val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') +
              ' USD'
            );
          },
        },
        marker: {
          fillColors: [
            '#415AA8',
            '#1A468D',
            '#254382',
            '#51737F',
            '#0080C8',
            '#03B1E5',
            '#ABC8E6',
            '#5D6770',
            '#FE0000',
            '#FD4600',
            '#FF6D3E',
            '#FAA21C',
            '#B4B5B7',
            '#FDE3C0',
          ],
        },
      },
    };
  }

  getSeries(jobs: InternalWorksResponse[]) {
    const result = [];
    const series = [];

    const reducesJobs = jobs.reduce((res: {}, value: InternalWorksResponse) => {
      if (
        !res[value.due_Date] ||
        res[value.due_Date].job_Manager !== value.job_Manager
      ) {
        res[value.due_Date] = {
          job_Manager: value.job_Manager,
          equivalentUSD: 0,
          month: value.due_Date,
        };
        result.push(res[value.due_Date]);
      }

      res[value.due_Date].equivalentUSD += value.equivalent_USD;

      return res;
    }, {});

    if (result.length > 0) {
      result
        .map((job) => job.job_Manager)
        .filter((value: any, index: number, self: any[]) => self.indexOf(value) === index)
        .forEach((jobManager) => {
          series.push({
            name: jobManager,
            data: [],
          });
        });

      series.forEach((serie) => {
        const months = [];
        result.forEach((job) => {
          if (serie.name === job.job_Manager) {
            months.push(job.month);
            serie.data.push(job.equivalentUSD);
          } else {
            const exits = months.includes(job.month);
            const filters = result.filter(
              (value) =>
                value.month === job.month && value.job_Manager === serie.name
            );

            if (!exits && filters.length === 0) {
              months.push(job.month);
              serie.data.push(0);
            }
          }
        });
      });
      if (series.length === 1) {
        series.push({ name: 'No existen más usuarios', data: [0] });
      }
    }
    return series.filter((serie) => serie.name !== '');
  }

  getCategories(jobs: InternalWorksResponse[]) {
    return jobs
      .map((job: InternalWorksResponse) => job.due_Date)
      .filter((value: string, index: number, self: string[]) => self.indexOf(value) === index);
  }
}
