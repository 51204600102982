import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ChartComponent } from "ng-apexcharts";
import { InternalWorksResponse } from "src/app/models/works/internal.works.response.model";
import { ChartOptions } from "../../../tasks/graphics/tasks-graphics.component";
import * as moment from "moment";
import { orderArraysByName } from "../../../../../utils/utils";

@Component({
  selector: "app-project-graphic",
  templateUrl: "./project-graphic.component.html",
})
export class ProjectGraphicComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  @Input() title: string;
  @Input() horizontal: boolean;
  @Input() set jobs(jobs: InternalWorksResponse[]) {
    if (jobs?.length > 0) {
      this.jobsList = jobs;
      if (this.horizontal) {
        this.settingsChart(jobs);
      } else {
        const jobsFormatedDate = jobs.map((job) => ({
          ...job,
          due_Date: moment(job.due_Date, "YYYY/MM/DD")
            .format("MMM")
            .concat(" ")
            .concat(moment(job.due_Date, "YYYY/MM/DD").format("YYYY")),
        }));

        this.months.forEach((month) => {
          const exists = jobsFormatedDate.find(
            (category) => category.due_Date.substring(0, 3) === month
          );
          if (!exists) {
            jobsFormatedDate.push({
              account_Officer: "",
              address: "",
              business_Unit: "",
              city: "",
              country: "",
              created: null,
              currency: "",
              deliverable: "",
              department: "",
              due_Date: month + " " + new Date().getFullYear(),
              email_Account_Officer: "",
              equivalent_USD: 0,
              external_Fees: 0,
              id: 0,
              internal_fees: 0,
              job_Comments: "",
              job_Manager: "",
              job_Number: "",
              job_Status: "",
              iD_Job_Status: 0,
              job_type: "",
              latitude: "",
              longitude: "",
              name_Job_Manager: "",
              phone_Account_Officer: "",
              project_Number: "",
              property_Name: "",
              property_Number: "",
              sector: "",
              zipCode: 0,
              account: "",
              Id_property_Contact: 0,
              Id_Inspection_Contact: 0,
              Valuation_Scenarios: "",
              Interest_Valued: "",
              Type_of_Value: "",
              Inspection_Required: "",
              Days_to_Deliver: 0
            });
          }
        });

        jobsFormatedDate.sort(
          (a, b) => Number(moment(a.due_Date)) - Number(moment(b.due_Date))
        );

        this.jobsList = jobs;
        this.settingsChart(jobsFormatedDate);
      }
    }
  }
  jobsList: InternalWorksResponse[] = [];
  public chartOptions: Partial<ChartOptions>;
  reduceJobsCompanies: any;
  reduceJobsDate: any;
  productionByClient: any;
  companies: any;

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  constructor() {}

  ngOnInit(): void {}

  settingsChart(jobs: InternalWorksResponse[]) {
    this.companies = jobs.map((job: InternalWorksResponse) => job.account).filter(
        (value: string, index: number, self: string[]) => self.indexOf(value) === index).length;

    this.chartOptions = {
      series: this.getSeries(jobs),
      chart: {
        type: "bar",
        height: 470,
        stacked: true,
        toolbar: {
          show: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: this.horizontal,
          dataLabels: {
            position: "top",
          }
        },
      },
      xaxis: {
        title: {
          text: this.horizontal ? "USD Equivalent" : "",
        },
        type: "category",
        categories: this.getCategories(jobs),
        labels: {
          formatter: (val) => {
            if (this.horizontal) {
              return (
                "$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              );
            } else {
              return val;
            }
          },
        },
      },
      yaxis: {
        title: {
          text: this.horizontal ? "" : "USD Equivalent",
        },
        labels: {
          formatter: (val: any) => {
            if (this.horizontal) {
              return val;
            } else {
              return (
                "$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              );
            }
          },
        },
      },
      legend: {
        show: this.horizontal ? true : false,
        position: this.horizontal ? "right" : "bottom",
        offsetY: 0,
        markers: {
          fillColors: [
            '#415AA8',
            '#1A468D',
            '#254382',
            '#51737F',
            '#0080C8',
            '#03B1E5',
            '#ABC8E6',
            '#5D6770',
            '#FE0000',
            '#FD4600',
            '#FF6D3E',
            '#FAA21C',
            '#B4B5B7',
            '#FDE3C0',
          ],
        },
      },
      fill: {
        colors: [
          '#415AA8',
          '#1A468D',
          '#254382',
          '#51737F',
          '#0080C8',
          '#03B1E5',
          '#ABC8E6',
          '#5D6770',
          '#FE0000',
          '#FD4600',
          '#FF6D3E',
          '#FAA21C',
          '#B4B5B7',
          '#FDE3C0',
        ],
        opacity: 1,
      },
      dataLabels: {
        enabled: this.horizontal ? true : false,
        offsetX: 55,
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          let numberOfSeries = w.config.series.length - 1
          if(seriesIndex == numberOfSeries){
            let number = parseFloat(w.globals.stackedSeriesTotals[dataPointIndex].toString());
            let formattedNumber = number.toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
            return `$ ${formattedNumber}`;
          } else if (seriesIndex<numberOfSeries){
            let sum = 0
            while (seriesIndex < numberOfSeries) {
              seriesIndex++;
              sum+= w.config.series[seriesIndex].data[dataPointIndex] || 0
            }
            if (sum === 0) {
              let number = parseFloat(w.globals.stackedSeriesTotals[dataPointIndex].toString());
              let formattedNumber = number.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
              return `$ ${formattedNumber}`;
            }
          }
        },
        style: {
          fontSize: "12px",
          colors: this.horizontal ? ["#304758"] : ["#fff"],
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            let number = parseFloat(val.toString());
            let formattedNumber = number.toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
            return "$ " + formattedNumber;
          },
        },
        marker: {
          fillColors: [
            '#415AA8',
            '#1A468D',
            '#254382',
            '#51737F',
            '#0080C8',
            '#03B1E5',
            '#ABC8E6',
            '#5D6770',
            '#FE0000',
            '#FD4600',
            '#FF6D3E',
            '#FAA21C',
            '#B4B5B7',
            '#FDE3C0',
          ],
        },
      },
    };
  }

  private getSeries(jobs: InternalWorksResponse[]) {
    const result = [];
    const series = [];

    if (this.horizontal) {
        jobs.reduce((res: {}, value: InternalWorksResponse) => {
          if (
            !res[value.account] ||
            (res[value.account].sector !== value.sector)
          ) {
            res[value.account] = {
              account: value.account,
              sector: value.sector,
              equivalentUSD: 0
            };
            result.push(res[value.account]);
          }

        res[value.account].equivalentUSD += value.equivalent_USD;

        return res;
      }, {});

      this.reduceJobsCompanies = result.reduce((res: {}, value: any) => {
        if (
          !res[value.account] ||
          (res[value.account].sector !== value.sector &&
            res[value.account].account !== value.account)
        ) {
          res[value.account] = {
            account: value.account,
            sector: value.sector,
            equivalentUSD: 0,
            count: 0,
          };
        }

        res[value.account].equivalentUSD += value.equivalentUSD;
        res[value.account].count += 1;

        return res;
      });

      if (result.length > 0) {
        result
          .map((job) => job.sector)
          .filter(
            (value: any, index: number, self: any[]) =>
              self.indexOf(value) === index
          )
          .forEach((sector) => {
            series.push({
              name: sector,
              data: [],
            });
          });

        series.forEach((serie) => {
          const companies = [];
          result.forEach((job) => {
            if (serie.name === job.sector) {
              companies.push(job.account);
              serie.data.push(job.equivalentUSD);
            } else {
              const exits = companies.includes(job.account);
              const filters = result.filter(
                (value) =>
                  value.account === job.account && value.sector === serie.name
              );

              if (!exits && filters.length === 0) {
                companies.push(job.account);
                serie.data.push(0);
              }
            }
          });
        });
        if (series.length === 1) {
          series.push({ name: "No existen más usuarios", data: [0] });
        }
      }
    } else {
      jobs.reduce((res: {}, value: InternalWorksResponse) => {
        if (
          !res[value.due_Date] ||
          res[value.due_Date].sector !== value.sector
        ) {
          res[value.due_Date] = {
            job_Manager: value.job_Manager,
            equivalentUSD: 0,
            month: value.due_Date,
            sector: value.sector,
          };
          result.push(res[value.due_Date]);
        }

        res[value.due_Date].equivalentUSD += value.equivalent_USD;

        return res;
      }, {});

      this.reduceJobsDate = result;

      if (result.length > 0) {
        result
          .map((job) => job.sector)
          .filter(
            (value: any, index: number, self: any[]) =>
              self.indexOf(value) === index
          )
          .forEach((sector) => {
            series.push({
              name: sector,
              data: [],
            });
          });

        series.forEach((serie) => {
          const months = [];
          result.forEach((job) => {
            if (serie.name === job.sector) {
              months.push(job.month);
              serie.data.push(job.equivalentUSD);
            } else {
              const exits = months.includes(job.month);
              const filters = result.filter(
                (value) =>
                  value.month === job.month && value.sector === serie.name
              );

              if (!exits && filters.length === 0) {
                months.push(job.month);
                serie.data.push(0);
              }
            }
          });
        });
        if (series.length === 1) {
          series.push({ name: "No existen más usuarios", data: [0] });
        }
      }
    }

    if (this.horizontal){
      sessionStorage.setItem('productionByClient', JSON.stringify(series));
      return series.filter((serie) => serie.name !== "").sort((a, b) => a.name > b.name ? 1 : -1);
    } else {
      const productionByClient = sessionStorage.getItem('productionByClient') ? JSON.parse(sessionStorage.getItem('productionByClient')) : [];
      const [productionByClientArray, productionBySectorArray] = orderArraysByName(productionByClient, series.filter((serie) => serie.name !== ""));
      return productionBySectorArray;
    }

  }

  private getCategories(jobs: InternalWorksResponse[]) {
    if (this.horizontal) {
      return jobs
        .map((job: InternalWorksResponse) => job.account)
        .filter(
          (value: string, index: number, self: string[]) =>
            self.indexOf(value) === index
        );
    } else {
      return jobs
        .map((job: InternalWorksResponse) => job.due_Date)
        .filter(
          (value: string, index: number, self: string[]) =>
            self.indexOf(value) === index
        );
    }
  }
}
