import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-advances-details',
  templateUrl: './advances-details.component.html'
})
export class AdvancesDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
