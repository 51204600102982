import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AuthUser } from "../../models/auth/auth.model";
import { CodeRequest } from "src/app/models/auth/code.model";
import { NewPasswordRequest } from "src/app/models/auth/newpassword.model";
import { MsalService } from "@azure/msal-angular";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    public readonly http: HttpClient,
    private readonly authService: MsalService,
    private readonly router: Router
  ) {}

  loginLogan() {
    return this.authService.loginPopup();
  }

  logout() {
    sessionStorage.removeItem("email");
    if (sessionStorage.getItem("tipo") === "Interno") {
      this.router.navigate(["/auth/login-logan"]);
    } else {
      this.router.navigate(["/auth/login"]);
    }
  }

  getToken(authUser: AuthUser) {
    return this.http.post(environment.authToken, authUser);
  }

  signIn(authUser: AuthUser) {
    let headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    };

    return this.http.post(environment.auth, authUser, headers);
  }

  generateToken(email: string) {
    let headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    };

    return this.http.post(environment.generateToken, `"${email}"`, headers);
  }

  testCode(codeRequest: CodeRequest) {
    let headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    };

    return this.http.post(environment.testCode, codeRequest, headers);
  }

  changePassword(newPasswordRequest: NewPasswordRequest) {
    let headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    };

    return this.http.post(
      environment.changePassword,
      newPasswordRequest,
      headers
    );
  }

  validateEmail(email: { Email: string }) {
    return this.http.post(environment.forgotPassword.validateEmail, email);
  }

  sendEmailResetPassword(email: { Email: string }) {
    return this.http.post(environment.forgotPassword.sendEmailResetPassword, email);
  }

  createNewPassword( data: any ) {
    return this.http.post(environment.forgotPassword.changePassword, data);
  }

  validateCode( data: any ) {
    return this.http.post(environment.forgotPassword.validateCode, data);
  }
}
