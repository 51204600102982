import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProjectsService } from "../../services/admin-services/projects/projects.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { ProjectFileModel } from "../../models/projects/project.file.model";
import * as moment from 'moment';

@Component({
  selector: 'app-update-document-dialog',
  templateUrl: './update-document-dialog.component.html'
})
export class UpdateDocumentDialogComponent implements OnInit {

  public udpateRenameForm: UntypedFormGroup;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<UpdateDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      documentTypes: any[];
      action: string;
      document: ProjectFileModel;
    },
    private readonly _ProjectsService: ProjectsService,
    private readonly toastr: ToastrService,
    private readonly spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.createUpdateRenameForm();
  }

  createUpdateRenameForm(): void {
    this.udpateRenameForm = this.formBuilder.group({
      id: new UntypedFormControl(this.data.document.id),
      idItem: new UntypedFormControl(this.data.document.idItem),
      regarding: new UntypedFormControl(this.data.document.regarding),
      url: new UntypedFormControl(this.data.document.url),
      item: new UntypedFormControl(this.data.document.item),
      documentName: new UntypedFormControl(this.data.document.documentName.substring(0, this.data.document.documentName.lastIndexOf('.'))),
      documentType: new UntypedFormControl(this.data.document.documentType),
      createdOn: new UntypedFormControl(this.data.document.createdOn),
      createdBy: new UntypedFormControl(this.data.document.createdBy),
      modifiedOn: new UntypedFormControl(moment(new Date()).format('YYYY-MM-DD')),
      modifiedBy: new UntypedFormControl(sessionStorage.getItem("userID")),
      status: new UntypedFormControl(this.data.document.status),
    });
  }

  getExtension(): string {
    return this.data.document.documentName.substring(this.data.document.documentName.lastIndexOf('.') + 1);
  }

  manageDocument(): void {
    if ( this.data.action === 'Edit' ) {
      this.editDocument();
    } else if ( this.data.action === 'Rename' ) {
      this.renameDocument();
    }
  }

  editDocument(): void {
    this.spinner.show();
    this._ProjectsService.updateDocument(this.udpateRenameForm.value, 'Edit').subscribe({
      next: (response: any) => {
        this.spinner.hide();
        this.toastr.success("Document updated successfully", "Success");
        this.dialogRef.close(true);
      },
      error: () => {
        this.spinner.hide();
        this.toastr.error("Error! Please try again", "Error");
        this.dialogRef.close(true);
      }
    });
  }

  renameDocument(): void {
    this.spinner.show();
    const data = {
      ActualName: this.data.document.documentName,
      NewName: `${this.udpateRenameForm.value.documentName}.${this.data.document.documentName.substring(this.data.document.documentName.lastIndexOf('.') + 1)}`,
      DocumentId: this.data.document.id,
      UrlDocument: ""
    }
    this._ProjectsService.updateDocument(data, 'Rename').subscribe({
      next: (response: any) => {
        this.spinner.hide();
        this.toastr.success("Document renamed successfully", "Success");
        this.dialogRef.close(true);
      },
      error: () => {
        this.spinner.hide();
        this.toastr.error("Error! Please try again", "Error");
        this.dialogRef.close(true);
      }
    });
  }

}
