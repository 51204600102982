import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { PropertieResponse } from "../../../../../models/properties/properties.response.model";
import { cleanNullAttributes, findByMatchingProperty, loadSideBarConfiguration } from "../../../../../utils/utils";
import { AuthService } from "src/app/services/auth/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-internal-properties",
  templateUrl: "./internal-properties.component.html",
})
export class InternalPropertiesComponent implements OnInit {
  properties: string;
  filtersForm: UntypedFormGroup;
  propertiesResponse: PropertieResponse[] = [];
  city: string;
  type: string;
  account: string;
  citySelect = [];
  propertieTypeSelect = [];
  propertieAccountSelect = [];

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly spinner: NgxSpinnerService,
    private readonly authService: AuthService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    if (sessionStorage.getItem("propertyDirty") === "true") {
      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
        this.router.navigate([window.location.pathname]);
      });
    }
    this.buildFiltersForms();
    this.getUserProperties();
    loadSideBarConfiguration("btnMenuInternalProperties");
  }

  buildFiltersForms() {
    this.filtersForm = this.formBuilder.group({
      city: [null, Validators.required],
      property_Type: [null, Validators.required],
      property_Account: [null, Validators.required],
    });
  }

  getUserProperties() {
    this.spinner.show();
    this.propertiesResponse = JSON.parse(
      sessionStorage.getItem("internalProperties")
    );
    this.getUniqueValuesOnchangeSelect();
    this.spinner.hide();
  }

  getUniqueValuesOnchangeSelect() {
    this.citySelect = this.propertiesResponse
      .map((property) => property["city"])
      .filter((value, index, self) => self.indexOf(value) === index);

    this.propertieTypeSelect = this.propertiesResponse
      .map((property) => property["property_Type"])
      .filter((value, index, self) => self.indexOf(value) === index);

    this.propertieAccountSelect = this.propertiesResponse
      .map((property) => property["property_Account"])
      .filter((value, index, self) => self.indexOf(value) === index);
  }

  filterProperties() {
    var filters = cleanNullAttributes(this.filtersForm.value);
    var resultFilter = findByMatchingProperty(
      JSON.parse(sessionStorage.getItem("internalProperties")),
      filters
    );

    this.propertiesResponse = resultFilter;
  }

  clearFilters() {
    this.filtersForm.patchValue({
      city: undefined,
      property_Type: undefined,
      property_Account: undefined,
    });
    this.propertiesResponse = JSON.parse(
      sessionStorage.getItem("internalProperties")
    );
  }

  updateInternalFilterProperties(event) {
    this.propertiesResponse = event;
  }

  logout() {
    this.authService.logout();
  }

  get fullName() {
    return sessionStorage.getItem("nombreCompleto");
  }
}
