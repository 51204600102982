import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { combineLatest, Observable } from "rxjs";
import { CompanyNameResponse } from "src/app/models/company-names/company-name.response.model";
import { CityResponse } from "src/app/models/localizations/city.response.model";
import { CountryResponse } from "src/app/models/localizations/country.reponse.model";
import { StateResponse } from "src/app/models/localizations/state.response.model";
import { CompanyNameService } from "src/app/services/admin-services/company-name/company-name.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { LocalizationsService } from "src/app/services/localizations/localizations.service";
import { ContactsService } from "../../../services/admin-services/contacts/contacts.service";

@Component({
  selector: "app-form-contacts",
  templateUrl: "./form-contacts.component.html",
})
export class FormContactsComponent implements OnInit {
  public contactsForm: UntypedFormGroup;
  @Output() contactFormEvent = new EventEmitter<UntypedFormGroup>();
  public countriesResponse: CountryResponse[];
  public statesResponse: StateResponse[];
  public citiesResponse: CityResponse[];
  public companyNamesResponse: CompanyNameResponse[];
  public contactsResponse: any[];
  public states: StateResponse[];
  public cities: CityResponse[];
  public countryId: number;
  public stateId: number;
  public showContactForm = false;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly localizationsService: LocalizationsService,
    private readonly contactService: ContactsService,
    private readonly toastr: ToastrService,
    private readonly companyNameService: CompanyNameService,
    private readonly spinner: NgxSpinnerService,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.buildContactForm();
    this.getLocalizations();
    this.loadSideBarConfiguration();
  }

  loadSideBarConfiguration() {
    let sidebar = document.querySelector(".sidebar");
    let closeBtn = document.querySelector("#btnMenu");

    closeBtn.addEventListener("click", () => {
      sidebar.classList.toggle("open");
      menuBtnChange();
    });

    function menuBtnChange() {
      if (sidebar.classList.contains("open")) {
        closeBtn.classList.replace("bx-menu", "bx-menu-alt-right");
        document.querySelector(".margin-screen").classList.add("md:ml-14");
        document.querySelector(".margin-screen").classList.remove("md:ml-3");
      } else {
        closeBtn.classList.replace("bx-menu-alt-right", "bx-menu");
        document.querySelector(".margin-screen").classList.add("md:ml-3");
        document.querySelector(".margin-screen").classList.remove("md:ml-14");
      }
    }
  }

  private buildContactForm() {
    this.contactsForm = this.formBuilder.group({
      Email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      TelefonoTrabajo: ["", Validators.required],
      Cargo: ["", Validators.required],
      Rol: [null, Validators.required],
      Nombres: ["", Validators.required],
      Apellidos: ["", Validators.required],
      Pais: [null, Validators.required],
      Departamento: [null, Validators.required],
      Ciudad: [null, Validators.required],
      RazonSocial: [null, Validators.required],
      Estado: ["Desactivado", Validators.required],
      GuidCompany: [sessionStorage.getItem("guidCompany"), Validators.required],
    });
    this.contactFormEvent.emit(this.contactsForm);
  }

  private getLocalizations() {
    this.spinner.show();
    combineLatest([
      this.localizationsService.currentCountries,
      this.localizationsService.currentStates,
      this.localizationsService.currentCities,
      this.companyNameService.currentCompanyNames,
      this.contactService.currentContacts,
    ]).subscribe(([countries, states, cities, companyNames, contacts]) => {
      if (
        countries !== null &&
        states !== null &&
        cities !== null &&
        companyNames !== null &&
        contacts !== null
      ) {
        this.countriesResponse = countries;
        this.statesResponse = states;
        this.citiesResponse = cities;
        this.companyNamesResponse = companyNames;
        this.contactsResponse = contacts;
        this.spinner.hide();
      }
    });
  }

  public selectCountry() {
    if (this.countryId) {
      this.states = this.statesResponse.filter(
        (item) => item.country_ID == this.countryId
      );
    }
  }

  public selectState() {
    if (this.stateId) {
      this.cities = this.citiesResponse.filter(
        (item) => item.department_ID == Number(this.stateId)
      );
    }
  }

  public saveContact() {
    if (this.contactsForm.invalid) {
      return Object.values(this.contactsForm.controls).forEach((control) => {
        if (control instanceof UntypedFormGroup) {
          Object.values(control.controls).forEach((ctrl) =>
            ctrl.markAsTouched()
          );
        } else {
          control.markAllAsTouched();
        }
      });
    } else {
      this.spinner.show();
      this.contactService
        .saveContact(this.contactsForm.value)
        .subscribe((resp) => {
          this.contactsForm.reset();
          this.toastr.success(
            "Se ha registrado exitosamente el contacto!",
            "Registro contacto",
            {
              progressBar: true,
              progressAnimation: "increasing",
            }
          );
          this.showContactForm = false;
          this.spinner.hide();
        });
    }
  }

  public toggleContactForm() {
    this.showContactForm = !this.showContactForm;
  }

  logout() {
    this.authService.logout();
  }

  get fullName() {
    return sessionStorage.getItem("nombreCompleto");
  }
}
