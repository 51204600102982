import { AgmInfoWindow } from "@agm/core";
import { Component, Input, OnInit } from "@angular/core";
import { marker } from "src/app/models/googlemaps/markers.model";

@Component({
  selector: "app-googlemaps",
  templateUrl: "./googlemaps.component.html",
})
export class GooglemapsComponent implements OnInit {
  @Input() set internalProperties(properties: any) {
    this.generateMarkers(properties);
  }
  @Input() heightMap: string;
  @Input() componentName: string;

  // google maps zoom level
  zoom: number = 6;

  // initial center position for the map
  lat: number = 6.20312;
  lng: number = -75.57141;

  markers: marker[] = [];

  constructor() {}

  ngOnInit(): void {}

  generateMarkers(properties: any) {
    this.markers = [];

    if (this.componentName === "jobs") {
      this.lat = 6.20312;
      this.lng = -75.57141;
      this.zoom = 6;
      properties?.forEach((property, index) => {
        this.markers.push({
          lat: Number(property.latitude),
          lng: Number(property.longitude),
          label: (index + 1).toString(),
          draggable: false,
          description: property.property_Name,
        });
      });
    } else {
      this.zoom = 12;
      this.lat = Number(properties?.latitude);
      this.lng = Number(properties?.longitude);
      this.markers.push({
        lat: Number(properties?.latitude),
        lng: Number(properties?.longitude),
        label: (1).toString(),
        draggable: false,
        description: properties?.property_Name,
      });
    }
  }

  mouseOverMarker(infoWindow: AgmInfoWindow) {
    infoWindow.open();
  }

  mouseOutMarker(infoWindow: AgmInfoWindow) {
    infoWindow.close();
  }

  markerDragEnd(m: marker, $event: MouseEvent) {
    console.log("dragEnd", m, $event);
  }
}
