import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AdvancedSettingsService } from "src/app/services/admin-services/advanced-settings/advanced-settings.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { UserTenatModel } from "src/app/models/advanced-settings/user-info-graph.model";
import { ConfirmDialogComponent } from "src/app/components/confirm-dialog/confirm-dialog.component";
import { AccountRelationalontactModel } from "../../../../../../models/accounts/relational-contact.model";
import {
  InternalContactsService
} from "../../../../../../services/admin-services/internal-contacts/internal-contacts.service";
import { InternalContactModel } from "../../../../../../models/internal-contacts/internal-contact.model";

@Component({
  selector: "app-manage-user-dialog",
  templateUrl: "./manage-user-dialog.component.html",
})
export class ManageUserDialogComponent implements OnInit {
  userForm: UntypedFormGroup;
  isInternal = false;
  isCheckAutomaticPassword = false;
  isSendEmail = false;
  externalEmail: string;
  filteredSubtypes = [];
  users: UserTenatModel[];
  contactRecords: InternalContactModel[];

  constructor(
    private dialogRef: MatDialogRef<ManageUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly _advancedSettingsService: AdvancedSettingsService,
    private readonly internalContactService: InternalContactsService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: ToastrService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.createUserForm();
    this.getUsersTenat();
    this.manageOnChangeValues();
  }


  openConfirmUserDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        title: "Save a New User",
        description: "¿Are you sure you want to save a new user?",
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.managementUser();
      }
    });
  }

  getSubtypeName(subtypeValue: number): string {
    return this.data.subtypes.find(
      (subtype) => subtype.idSubTypeUser === subtypeValue
    )?.subTypeUserName;
  }

  getActiveDirectoryUserInfo(userId: number): void {
    let userTenant = this.users?.find((u) => u.id === userId);
    if (userTenant) {
      this.userForm.get("First_Name").setValue(userTenant.givenName);
      this.userForm.get("Last_Name").setValue(userTenant.surname);
      this.userForm.get("Full_Name").setValue(userTenant.displayName);
      this.userForm.get("Email").setValue(userTenant.mail);
      this.userForm.get("Job_Title").setValue(userTenant.jobTitle);
      this.userForm.get("Movil_Phone").setValue(userTenant.mobilePhone);
      this.userForm.get("Address").setValue(userTenant.officeLocation);
      this.userForm.get("Type_User").setValue(1);
    }
  }

  roleName(id: number): string {
    return this.data.roles.find((r) => r.id === id)?.rolName;
  }

  generatePassword(event): void {
    if (event.checked) {
      this.isCheckAutomaticPassword = true;
      const randomstring = Math.random().toString(36).slice(-8);
      this.userForm.get("Password_value").clearValidators();
      this.userForm.get("Password_value").setValue(randomstring);
    } else {
      this.isCheckAutomaticPassword = false;
      this.userForm.get("Password_value").setValue("");
      this.userForm.get("Password_value").setValidators([Validators.required]);
      this.userForm.get("Password_value").updateValueAndValidity();
    }
  }

  managementUser(): void {
    this.userForm.get("Rol_Name").setValue(this.roleName(this.userForm.value.RolesId));
    this.userForm.get("RolesId").setValue([this.userForm.value.RolesId]);
    this.userForm.removeControl("ActiveDirectory");
    this.userForm.get("Status_value").setValue(this.userForm.value.Status_value ? "Activo" : "Inactivo");
    this.spinner.show();
    if (this.data.action === "create") {
      this._advancedSettingsService
        .createContact(this.userForm.value)
        .subscribe(
          (response: any) => {
            if (response.statusCode === "428" ) {
              this.spinner.hide();
              this.toastr.error("User already exists");
            } else if ( this.isSendEmail ) {
              this.sendEmail( "created" );
            } else {
              this.spinner.hide();
              this.toastr.success( "User created successfully" );
              this.dialogRef.close( true );
            }
          },
          () => {
            this.spinner.hide();
            this.toastr.error("Error creating user");
          }
        );
    } else {
      this._advancedSettingsService
        .updateContact(this.userForm.value)
        .subscribe(
          () => {
            if (this.isSendEmail) {
              this.sendEmail("updated");
            } else {
              this.spinner.hide();
              this.toastr.success("User updated successfully");
              this.dialogRef.close(true);
            }
          },
          () => {
            this.spinner.hide();
            this.toastr.error("Error updating user");
          }
        );
    }
  }

  private getCompanyxContacts(idCompany: number) {
    this.spinner.show();
    this.internalContactService.getCompanyxContacts(idCompany).subscribe(
      (contacts: AccountRelationalontactModel[]) => {
        this.spinner.hide();
        const contactIds = contacts.map(
          (contact) => contact.idContactInformationRecord
        );
        this.getRecordsIds(contactIds);
      },
      () => {
        this.spinner.hide();
        this.toastr.error(
          `Error getting contacts for the company with id ${idCompany}`,
          "Error"
        );
      }
    );
  }

  private getRecordsIds(contacts: number[]) {
    this.spinner.show();
    this.internalContactService.getRecordsIds(contacts).subscribe(
      (contactRecords: InternalContactModel[]) => {
        this.spinner.hide();
        this.contactRecords = contactRecords;
      },
      () => {
        this.spinner.hide();
        this.toastr.error("Error! Please try again", "Error");
      }
    );
  }

  private validateTypeUser(typeValue: number): void {
    if (typeValue === 1) {
      this.isInternal = true;
      this.userForm.get("Status_value").setValue(true);
    } else if (typeValue === 2) {
      this.isInternal = false;
      this.userForm.get("Password_value").updateValueAndValidity();
      this.userForm.get("Status_value").setValue(true);
    }
  }

  private typeUserChange(): void {
    this.userForm.get("Type_User").valueChanges.subscribe((type) => {
      if (type === 2) {
        this.userForm.get("Guid_Company").setValidators(Validators.required);
        this.userForm.get("Guid_Company").updateValueAndValidity();
        this.userForm.get("Password_value").setValidators([Validators.required]);
        this.userForm.get("Password_value").updateValueAndValidity();
      } else {
        this.externalEmail = null;
        this.userForm.get("Guid_Company").setValue(null);
        this.userForm.get("Guid_Company").clearValidators();
        this.userForm.get("Guid_Company").updateValueAndValidity();
        this.userForm.get("Password_value").setValue(null);
        this.userForm.get("Password_value").clearValidators();
        this.userForm.get("Password_value").updateValueAndValidity();
      }

      this.filteredSubtypes = this.data.subtypes.filter(
        (subtype) => subtype.idTypeUser !== type
      );
      this.validateTypeUser(type);
    });
  }

  private createUserForm(): void {
    const status = this.data.user?.status_value === "Activo" ? true : false;
    if (this.data.action === "edit") {
      this.filteredSubtypes = this.data.subtypes.filter(
        (subtype) => subtype.idTypeUser !== this.data?.user?.type_User
      );
    }

    this.userForm = this.formBuilder.group({
      Id: [this.data.user ? this.data.user.id : 0],
      ActiveDirectory: [""],
      First_Name: [
        this.data.user ? this.data.user.first_Name : "",
        Validators.required,
      ],
      Last_Name: [
        this.data.user ? this.data.user.last_Name : "",
        Validators.required,
      ],
      Full_Name: [
        this.data.user ? this.data.user.full_Name : "",
        Validators.required,
      ],
      Email: [this.data.user ? this.data.user.email : "", Validators.required],
      Company_Name: [this.data.user ? this.data.user.company_Name : ""],
      Business_Phone: [
        this.data.user ? this.data.user.business_Phone : "",
        Validators.required,
      ],
      Project_Number: [this.data.user ? this.data.user.project_Number : ""],
      City: [this.data.user ? this.data.user.city : "", Validators.required],
      Department: [this.data.user ? this.data.user.department : ""],
      Country: [
        this.data.user ? this.data.user.country : "",
        Validators.required,
      ],
      Job_Title: [
        this.data.user ? this.data.user.job_Title : "",
        Validators.required,
      ],
      Status_value: [status, Validators.required],
      Rol_Name: [this.data.user ? this.roleName(this.data.user.rolesId[0]) : ""],
      Guid_value: [this.data.user ? this.data.user.guid_value : ""],
      Guid_Company: [this.data.user ? this.data.user.guid_Company : ""],
      Account_Officer: [this.data.user ? this.data.user.account_Officer : ""],
      Password_value: [this.data.user ? this.data.user.password_value : ""],
      Created: [this.data.user ? this.data.user.created : new Date()],
      Type_User: [this.data.user ? this.data.user.type_User : ""],
      Subtype: [this.data.user ? this.data.user.subtype : ""],
      Existing_User: [this.data.user ? this.data.user.existing_User : ""],
      Movil_Phone: [
        this.data.user ? this.data.user.movil_Phone : ""
      ],
      Address: [
        this.data.user ? this.data.user.address : "",
        Validators.required,
      ],
      RolesId: [this.data.user ? this.data.user.rolesId[0] : [], Validators.required],
    });

    this.typeUserChange();
    this.validateTypeUser(this.userForm.value.Type_User);
  }

  private sendEmail(action: string) {
    const data = {
      usuario: this.externalEmail,
      clave: this.userForm.value.Password_value,
    };
    this.spinner.show();
    this._advancedSettingsService.sendEmail(data).subscribe(
      () => {
        this.spinner.hide();
        this.toastr.success(`User ${action} and Email sent successfully`);
        this.dialogRef.close(true);
      },
      () => {
        this.spinner.hide();
        this.toastr.error("Error creating or sending email");
      }
    );
  }

  private getUsersTenat(): void {
    this._advancedSettingsService.getUsersTenant().subscribe(
      (users: UserTenatModel[]) => {
        this.users = users || [];
      },
      () => {
        this.toastr.error("Error getting users");
      }
    );
  }

  private manageOnChangeValues(): void {
    this.userForm.get("Guid_Company").valueChanges.subscribe((guidCompany) => {
      const idCompany = this.data.accounts.find((account) => account.guidCompany === guidCompany)?.idCompany;
      if (idCompany) {
        this.getCompanyxContacts(idCompany);
      }
    });

    this.userForm.get("Existing_User").valueChanges.subscribe((existingUser) => {
      this.userForm.patchValue({
        First_Name: existingUser.firstName,
        Last_Name: existingUser.lastName,
        Full_Name: `${existingUser.firstName} ${existingUser.lastName}`,
        Email: existingUser.email,
        Job_Title: existingUser.title,
        Movil_Phone: existingUser.phoneNumber1,
        Business_Phone: existingUser.phoneNumber2,
        Address: existingUser.address1,
        Country: this.data.countries.find((country) => country.id === existingUser.idCountry)?.countryName,
        City: this.data.cities.find((city) => city.id === existingUser.idCity)?.cityName,
      });
    });
  }

}
