import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ManageUserDialogComponent } from "./manage-user-dialog/manage-user-dialog.component";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AdvacedSettingsContactModel } from "src/app/models/advanced-settings/contact.model";
import { AdvancedSettingsService } from "src/app/services/admin-services/advanced-settings/advanced-settings.service";
import { AdvancedSettingsCityModel } from "../../../../../models/advanced-settings/city.model";
import { AdvancedSettingsCountryModel } from "../../../../../models/advanced-settings/country.model";
import { AdvancedSettingsRoleModel } from "src/app/models/advanced-settings/role.model";
import { AdvancedSettingsTypeModel } from '../../../../../models/advanced-settings/type.model';
import { AdvancedSettingsSubtypeModel } from '../../../../../models/advanced-settings/subtype.model';
import { AccountModel } from "../../../../../models/accounts/account.model";
import { AccountsService } from "../../../../../services/admin-services/accounts/accounts.service";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
})
export class UsersComponent implements OnInit {
  contacts: AdvacedSettingsContactModel[];
  countries: AdvancedSettingsCountryModel[];
  cities: AdvancedSettingsCityModel[];
  roles: AdvancedSettingsRoleModel[];
  types: AdvancedSettingsTypeModel[];
  subtypes: AdvancedSettingsSubtypeModel[];
  accounts: AccountModel[];

  constructor(
    private readonly dialog: MatDialog,
    private readonly _advancedSettingsService: AdvancedSettingsService,
    private readonly _accountService: AccountsService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getContacts();
    this.getCities();
    this.getCountries();
    this.getRoles();
    this.getSubtypes();
    this.getTypes();
    this.getAccounts();
  }

  openManageUserDialog(action?: string, user?: AdvacedSettingsContactModel) {
    const dialogRef = this.dialog.open(ManageUserDialogComponent, {
      width: "850pt",
      autoFocus: false,
      disableClose: false,
      data: {
        user,
        action,
        countries: this.countries,
        cities: this.cities,
        roles: this.roles,
        types: this.types,
        subtypes: this.subtypes,
        accounts: this.accounts,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getContacts();
      }
    });
  }

  getSubtypeName(subtypeId: number) {
    return this.subtypes?.find(x => x.idSubTypeUser == subtypeId)?.subTypeUserName;
  }

  private getContacts() {
    this.spinner.show();
    this._advancedSettingsService.getContacts().subscribe(
      (contacs: AdvacedSettingsContactModel[]) => {
        this.contacts = contacs;
        this.spinner.hide();
      },
      () => {
        this.toastr.error("Error! Please try again", "Error", {
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.spinner.hide();
      }
    );
  }

  private getCities() {
    this.spinner.show();
    this._advancedSettingsService.getCities().subscribe(
      (cities: AdvancedSettingsCityModel[]) => {
        this.cities = cities;
        this.spinner.hide();
      },
      () => {
        this.toastr.error("Error! Please try again", "Error", {
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.spinner.hide();
      }
    );
  }

  private getCountries() {
    this.spinner.show();
    this._advancedSettingsService.getCountries().subscribe(
      (countries: AdvancedSettingsCountryModel[]) => {
        this.countries = countries;
        this.spinner.hide();
      },
      () => {
        this.toastr.error("Error! Please try again", "Error", {
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.spinner.hide();
      }
    );
  }

  private getRoles() {
    this.spinner.show();
    this._advancedSettingsService.getRoles().subscribe(
      (roles: AdvancedSettingsRoleModel[]) => {
        this.roles = roles;
        this.spinner.hide();
      },
      () => {
        this.toastr.error("Error! Please try again", "Error", {
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.spinner.hide();
      }
    );
  }

  private getSubtypes() {
    this.spinner.show();
    this._advancedSettingsService.getSubtypes().subscribe(
      (subtypes: AdvancedSettingsSubtypeModel[]) => {
        this.subtypes = subtypes;
        this.spinner.hide();
      },
      () => {
        this.toastr.error("Error! Please try again", "Error", {
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.spinner.hide();
      }
    );
  }

  private getTypes() {
    this.spinner.show();
    this._advancedSettingsService.getTypes().subscribe(
      (types: AdvancedSettingsTypeModel[]) => {
        this.types = types;
        this.spinner.hide();
      },
      () => {
        this.toastr.error("Error! Please try again", "Error", {
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.spinner.hide();
      }
    );
  }

  private getAccounts() {
    this.spinner.show();
    this._accountService.getAccounts().subscribe(
      (accounts: AccountModel[]) => {
        this.accounts = accounts;
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
        this.toastr.error("Error! Please try again", "Error");
      }
    );
  }
}
