import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Contact } from 'src/app/models/contacts/contact.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  protected contacts = new BehaviorSubject<any[]>(null);
  currentContacts = this.contacts.asObservable();

  private headers = { headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
  })};

  constructor(
    public readonly http: HttpClient
  ) { }

  saveContact(contact: Contact) {
    return this.http.post(environment.saveContact, contact, this.headers);
  }

  getContacts() {
    return this.http.get(`${environment.contacts}?guidCompany=${sessionStorage.getItem("guidCompany")}`, this.headers);
  }

  getAllContacts() {
    return this.http.get(`${environment.contacts}`, this.headers);
  }

  setContacts(contacts: any[]): void {
    this.contacts.next(contacts);
  }

}
