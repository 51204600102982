import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { ProjectResponse } from 'src/app/models/projects/project.response.model';
import { PropertieResponse } from 'src/app/models/properties/properties.response.model';
import { WorksResponse } from 'src/app/models/works/woks.response.model';
import { ProjectsService } from 'src/app/services/admin-services/projects/projects.service';
import { PropertiesService } from 'src/app/services/admin-services/properties/properties.service';
import { WorksService } from 'src/app/services/admin-services/works/works.service';

@Component({
  selector: 'app-projects-details',
  templateUrl: './projects-details.component.html'
})
export class ProjectsDetailsComponent implements OnInit {

  openTab: number = 1;
  projectNumber: string;
  propertiesResponse: PropertieResponse[] = [];
  worksResponse: WorksResponse[] = [];
  projectsResponse: ProjectResponse[] = [];

  constructor(
    private readonly _Activatedroute: ActivatedRoute,
    private readonly _PropertiesService: PropertiesService,
    private readonly _WorksSevice: WorksService,
    private readonly _ProjectsService: ProjectsService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getParameteresURL();
  }

  getParameteresURL() {
    this._Activatedroute.paramMap.subscribe(params => {
      this.projectNumber = params.get('projectNumber');
    });
    this.projectDetails();
  }


  toggleTabs($tabNumber: number){
    this.openTab = $tabNumber;
  }

  public projectDetails() {
    this.spinner.show();
    combineLatest([
      this._PropertiesService.getPropertieByProjectNumber(this.projectNumber),
      this._WorksSevice.getWorksByProjectNumber(this.projectNumber),
      this._ProjectsService.currentProjects
    ]).subscribe(([properties, works, projects]) => {
      if (properties !== null && works !== null && projects) {
        this.propertiesResponse = properties;
        this.worksResponse = works;
        this.projectsResponse = projects;
        this.spinner.hide();
      }
    }, error => {
      this.toastr.error(
        "Se ha presentado un problema cargando el detalle del proyecto " + this.projectNumber,
        "Detalle proyecto",
        {
          progressBar: true,
          progressAnimation: "increasing",
        }
      );
      this.spinner.hide();
    });
  }

}
