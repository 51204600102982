import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ProjectResponse } from "src/app/models/projects/project.response.model";
import { ProjectsService } from "src/app/services/admin-services/projects/projects.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { cleanNullAttributes, findByMatchingProperty, loadSideBarConfiguration } from "../../../utils/utils";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ProjectStatusModel } from "../../../models/projects/project-status.model";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-projects-table",
  templateUrl: "./projects-table.component.html",
})
export class ProjectsTableComponent implements OnInit {

  projectsResponse: ProjectResponse[];
  filterProjectsResponse: ProjectResponse[] = [];
  projects: string;
  filtersForm: UntypedFormGroup;
  statusProject: string;
  statusProjectsSelect: ProjectStatusModel[] = [];
  client: string;
  clientSelect = [];
  accountOfficer: string;
  accountOfficerSelect = [];
  minimumFee: number;
  maximumFee: number;

  get fullName() { return sessionStorage.getItem("nombreCompleto"); }

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly projectService: ProjectsService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: ToastrService,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.getUserProjects();
    this.getStatusProjects();
    this.buildFiltersForms();
    loadSideBarConfiguration("btnMenuProjects");
  }

  clearFilters() {
    this.filtersForm.patchValue({
      id_Status_Project: undefined,
      client: undefined,
      id_Account_Officer: undefined,
      minimum_fee: undefined,
      maximum_fee: undefined,
    });
    this.projectsResponse = JSON.parse(sessionStorage.getItem("internalProjects"));
  }

  filterJobs() {
    this.filterProjectsResponse = JSON.parse(
      sessionStorage.getItem("internalProjects")
    ).map((job) => ({
      ...job,
      created: job.created.split("T")[0],
    }));

    const filterForm = {
      ...this.filtersForm.value,
      id_Status_Project: +this.filtersForm.value.id_Status_Project || undefined,
      id_Account_Officer: +this.filtersForm.value.id_Account_Officer || undefined,
    };

    let filters = cleanNullAttributes(filterForm);
    let resultFilter = findByMatchingProperty(
      this.filterProjectsResponse,
      filters
    );

    this.projectsResponse = resultFilter;

  }

  logout() {
    this.authService.logout();
  }

  updateInternalFilterProjects(event) {
    this.projectsResponse = event;
  }

  private getUserProjects() {
    this.spinner.show();
    this.projectService.getProjects().subscribe(
      (resp: ProjectResponse[]) => {
        this.projectsResponse = resp.sort((a, b) => {
          const createdA = new Date(a.created);
          const createdB = new Date(b.created);
          return createdB.getTime() - createdA.getTime();
        });
        sessionStorage.setItem("internalProjects", JSON.stringify(resp));
        this.getUniqueValuesOnchangeSelect();
        this.spinner.hide();
      },
      () => {
        this.toastr.error(
          "Se presento un problema obteniendo los proyectos",
          "Proyectos",
          {
            progressBar: true,
            progressAnimation: "increasing",
          }
        );
        this.spinner.hide();
      }
    );
  }

  private getUniqueValuesOnchangeSelect() {
    this.clientSelect = this.projectsResponse
      .map((project) => project.client)
      .filter((value, index, self) => self.indexOf(value) === index);

    this.accountOfficerSelect = this.projectsResponse
      .map((project) => {
        return { idAccountOficcer: project.id_Account_Officer, accountOfficer: project.account_officer  }
      })
      .filter((value, index, self) =>
        self.findIndex((t) => t.idAccountOficcer === value.idAccountOficcer) === index);
  }

  private buildFiltersForms() {
    this.filtersForm = this.formBuilder.group({
      id_Status_Project: [null, Validators.required],
      client: [null, Validators.required],
      id_Account_Officer: [null, Validators.required],
      minimum_fee: [null, Validators.required],
      maximum_fee: [null, Validators.required],
    });
  }

  private getStatusProjects() {
    this.projectService.getStatusProjects()
      .subscribe((statusProject: ProjectStatusModel[]) => (this.statusProjectsSelect = statusProject));
  }

}
